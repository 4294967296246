import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { mapPropsType } from "../types/mapTypes";
import { CctvIndexContext } from "../context/clickCctvContext";

const NaverMap: FunctionComponent<mapPropsType> = function ({
  latitude,
  longtitude,
  nearCctvData,
}) {
  const cctvIndex = useContext(CctvIndexContext);
  const mapRef = useRef(null);
  const trafficLayerRef = useRef<naver.maps.TrafficLayer | null>(null);
  const [isTrafficLayerVisible, setIsTrafficLayerVisible] = useState(false);
  const [isAutoRefreshChecked, setIsAutoRefreshChecked] = useState(true);

  useEffect(() => {
    if (!mapRef.current || !naver) return;
    const mapOptions = {
      center: new naver.maps.LatLng(Number(latitude), Number(longtitude)),
      mapTypeControl: true,
    };

    const map = new naver.maps.Map(mapRef.current, mapOptions);

    const trafficLayerOptions = {
      interval: 300000, // 5 minutes refresh interval
    };

    const trafficLayer = new naver.maps.TrafficLayer(trafficLayerOptions);
    trafficLayerRef.current = trafficLayer;

    naver.maps.Event.addListener(map, "trafficLayer_changed", () => {
      const isLayerVisible = !!trafficLayer.getMap();
      setIsTrafficLayerVisible(isLayerVisible);

      if (isLayerVisible) {
        setIsAutoRefreshChecked(true);
      }
    });

    const markers: naver.maps.Marker[] = [];
    const infowindows: naver.maps.InfoWindow[] = [];
    const currentMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(Number(latitude), Number(longtitude)),
      map,
      icon: {
        url: "/img/GreenMarker.png",
        size: new naver.maps.Size(50, 52),
        origin: new naver.maps.Point(0, 0),
        anchor: new naver.maps.Point(25, 26),
      },
    });
    const infoAccientwindow = new naver.maps.InfoWindow({
      content: `<div class="naver-container"><p class="ptag">사고 발생지점</p></div>`,
      borderWidth: 1,
      anchorSize: new naver.maps.Size(10, 10),
      pixelOffset: new naver.maps.Point(10, -10),
    });
    infoAccientwindow.open(map, currentMarker);

    naver.maps.Event.addListener(currentMarker, "click", () => {
      if (infoAccientwindow.getMap()) {
        infoAccientwindow.close();
      } else {
        infoAccientwindow.open(map, currentMarker);
      }
    });

    for (let i = 0; i < nearCctvData.length; i++) {
      const contentTags = `<div class="naver-container"><p class="ptag">${nearCctvData[i].CCTVNAME}</p></div>`;
      const otherMarkers = new naver.maps.Marker({
        position: new naver.maps.LatLng(
          Number(nearCctvData[i].YCOORD),
          Number(nearCctvData[i].XCOORD)
        ),
        map,
        icon: {
          url: "/img/MapMarker.png",
          size: new naver.maps.Size(50, 52),
          origin: new naver.maps.Point(0, 0),
          anchor: new naver.maps.Point(25, 26),
        },
      });
      const infowindow = new naver.maps.InfoWindow({
        content: contentTags,
        borderWidth: 1,
        anchorSize: new naver.maps.Size(10, 10),
        pixelOffset: new naver.maps.Point(10, -10),
      });
      markers.push(otherMarkers);
      infowindows.push(infowindow);
    }

    naver.maps.Event.once(map, "init", () => {
      trafficLayer.setMap(map);
    });

    const getClickHandler = (seq: number) => {
      return () => {
        const marker = markers[seq];
        const infoWindow = infowindows[seq];
        console.log(seq);
        cctvIndex.updateCctvIndexData(seq);
        if (infoWindow.getMap()) {
          infoWindow.close();
        } else {
          infoWindow.open(map, marker);
        }
      };
    };

    for (let i = 0; i < markers.length; i += 1) {
      naver.maps.Event.addListener(markers[i], "click", getClickHandler(i));
    }

    return () => {
      if (trafficLayerRef.current) {
        trafficLayerRef.current.setMap(null);
      }
    };
  }, [nearCctvData]);

  return (
    <div id="map" style={{ width: "100%", height: "100%" }} ref={mapRef} />
  );
};

export default NaverMap;
