import { useSpring, animated } from "react-spring";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { off, onValue, ref } from "@firebase/database";
import { primaryColorTheme } from "../Layout/Theme";
import { TypographyStyle } from "../StyleComponent/FontStyle";
import { database } from "../../FirebaseSetting";
import { emeraldScaleTheme, grayScaleTheme } from "../Layout/RootColorStyle";
import axios from "axios";
const TextDataKr = {
  frontText: "현재 사고났슈가 총",
  backText: "건의 사고들을 감지했습니다.",
};
const TextDataEn = {
  frontText: "Currently, Myren has detected a total of",
  backText: "accidents.",
};
export const CountUserLabel: FunctionComponent = function () {
  const lang = window.localStorage.getItem("language");
  let TextData = TextDataKr;
  lang === "false" ? (TextData = TextDataKr) : (TextData = TextDataEn);
  const targetRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(0);
  const [accidentRecordCount, setRecordCount] = useState(0);
  const dbRef = ref(database, "AccidentRecord/");
  useEffect(() => {
    axios
      .get("/api/accident-record/all")
      .then((Response) => {
        setRecordCount(Response.data.data.length);
      })
      .catch((Error) => {
        console.log(Error);
      });
    return () => {
      off(dbRef, "value");
    };
  }, []);

  //spring 애니메이션 설정
  const springProps = useSpring({
    to: { count },
    config: { duration: 1000 },
  });

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2, //20%보이면 애니메이션 실행됨
    };

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current); //타겟 요소 관찰 시작
    }
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isVisible) {
      intervalId = setInterval(() => {
        setCount((prevCount) => {
          const newCount = prevCount + 1;
          if (newCount >= accidentRecordCount) {
            clearInterval(intervalId);
          }
          return newCount;
        });
      }, 10);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isVisible]);
  return (
    <div
      ref={targetRef}
      style={
        window.innerWidth > 1024
          ? {
              ...TypographyStyle.label.semiBold39,
              padding: "81px 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: grayScaleTheme.grayScaleColor50,
            }
          : window.innerWidth > 768
          ? {
              ...TypographyStyle.label.semiBold31,
              padding: "73px 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: grayScaleTheme.grayScaleColor50,
            }
          : {
              ...TypographyStyle.paragraphMedium.paragraphMedium12,
              padding: "41px 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: grayScaleTheme.grayScaleColor50,
            }
      }
    >
      <div>
        {TextData.frontText} &nbsp;
        {isVisible && (
          <animated.span
            style={{
              color: emeraldScaleTheme.emeraldScaleColor500,
            }}
          >
            {/* 애니메이션 값에 따라 숫자를 보간하여 출력 */}
            {springProps.count.interpolate((value: number) =>
              Math.floor(value)
            )}
          </animated.span>
        )}
        &nbsp;
        {TextData.backText}
      </div>
    </div>
  );
};
