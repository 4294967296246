import { ResponsiveLine } from "@nivo/line";
import React, { FunctionComponent } from "react";
import { TypographyStyle } from "../StyleComponent/FontStyle";
import { GridColumn5, GridItem2 } from "./Layout.Style";

export const GraphTable: FunctionComponent<{
  data: number[];
  title: string;
  color: string;
}> = function (props) {
  return (
    <div
      style={{
        height: "300px",
        padding: "0 20px",
      }}
    >
      <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <ResponsiveLine
          colors={{ scheme: "set1" }}
          data={[
            {
              id: props.title,
              color: "orange",
              data: [
                {
                  x: "0",
                  y: props.data[29],
                },
                {
                  x: "0.5",
                  y: props.data[28],
                },
                {
                  x: "1.0",
                  y: props.data[27],
                },
                {
                  x: "1.5",
                  y: props.data[26],
                },
                {
                  x: "2.0",
                  y: props.data[25],
                },
                {
                  x: "2.5",
                  y: props.data[24],
                },
                {
                  x: "3.0",
                  y: props.data[23],
                },
                {
                  x: "3.5",
                  y: props.data[22],
                },
                {
                  x: "4.0",
                  y: props.data[21],
                },
                {
                  x: "4.5",
                  y: props.data[20],
                },
                {
                  x: "5.0",
                  y: props.data[19],
                },
                {
                  x: "5.5",
                  y: props.data[18],
                },
                {
                  x: "6.0",
                  y: props.data[17],
                },
                {
                  x: "6.5",
                  y: props.data[16],
                },
                {
                  x: "7.0",
                  y: props.data[15],
                },
                {
                  x: "7.5",
                  y: props.data[14],
                },
                {
                  x: "8.0",
                  y: props.data[13],
                },
                {
                  x: "8.5",
                  y: props.data[12],
                },
                {
                  x: "9.0",
                  y: props.data[11],
                },
                {
                  x: "10.0",
                  y: props.data[10],
                },
                {
                  x: "10.5",
                  y: props.data[9],
                },
                {
                  x: "11.0",
                  y: props.data[8],
                },
                {
                  x: "11.5",
                  y: props.data[7],
                },
                {
                  x: "12.0",
                  y: props.data[6],
                },
                {
                  x: "12.5",
                  y: props.data[5],
                },
                {
                  x: "13.0",
                  y: props.data[4],
                },
                {
                  x: "13.5",
                  y: props.data[3],
                },
                {
                  x: "14.0",
                  y: props.data[2],
                },
                {
                  x: "14.5",
                  y: props.data[1],
                },
                {
                  x: "15.0",
                  y: props.data[0],
                },
              ],
            },
          ]}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
      <div style={{ width: "100%" }}>
        <GridColumn5>
          <GridItem2
            style={{
              ...TypographyStyle.label.semiBold16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            5초전
          </GridItem2>
          <GridItem2 style={{ ...TypographyStyle.label.semiBold16 }}>
            4.5초전
          </GridItem2>
          <GridItem2 style={{ ...TypographyStyle.label.semiBold16 }}>
            4초전
          </GridItem2>
          <GridItem2 style={{ ...TypographyStyle.label.semiBold16 }}>
            3.5초전
          </GridItem2>
          <GridItem2 style={{ ...TypographyStyle.label.semiBold16 }}>
            3초전
          </GridItem2>
          {/* <div style={{ display: "flex" }}> */}
          <GridItem2>{props.data[0]}</GridItem2>
          <GridItem2>{props.data[1]}</GridItem2>
          <GridItem2>{props.data[2]}</GridItem2>
          <GridItem2>{props.data[3]}</GridItem2>
          <GridItem2>{props.data[4]}</GridItem2>
          <GridItem2 style={{ ...TypographyStyle.label.semiBold16 }}>
            2.5초전
          </GridItem2>
          <GridItem2 style={{ ...TypographyStyle.label.semiBold16 }}>
            2초전
          </GridItem2>
          <GridItem2 style={{ ...TypographyStyle.label.semiBold16 }}>
            1.5초전
          </GridItem2>
          <GridItem2 style={{ ...TypographyStyle.label.semiBold16 }}>
            1초전
          </GridItem2>
          <GridItem2 style={{ ...TypographyStyle.label.semiBold16 }}>
            0.5초전
          </GridItem2>
          <GridItem2>{props.data[5]}</GridItem2>
          <GridItem2>{props.data[6]}</GridItem2>
          <GridItem2>{props.data[7]}</GridItem2>
          <GridItem2>{props.data[8]}</GridItem2>
          <GridItem2>{props.data[9]}</GridItem2>
        </GridColumn5>
      </div>
    </div>
  );
};
export const Graph: FunctionComponent<{
  data: number[];
  title: string;
  color: string;
}> = function (props) {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <ResponsiveLine
          colors={{ scheme: "set1" }}
          data={[
            {
              id: props.title,
              color: "orange",
              data: [
                {
                  x: "0",
                  y: props.data[29],
                },
                {
                  x: "0.5",
                  y: props.data[28],
                },
                {
                  x: "1.0",
                  y: props.data[27],
                },
                {
                  x: "1.5",
                  y: props.data[26],
                },
                {
                  x: "2.0",
                  y: props.data[25],
                },
                {
                  x: "2.5",
                  y: props.data[24],
                },
                {
                  x: "3.0",
                  y: props.data[23],
                },
                {
                  x: "3.5",
                  y: props.data[22],
                },
                {
                  x: "4.0",
                  y: props.data[21],
                },
                {
                  x: "4.5",
                  y: props.data[20],
                },
                {
                  x: "5.0",
                  y: props.data[19],
                },
                {
                  x: "5.5",
                  y: props.data[18],
                },
                {
                  x: "6.0",
                  y: props.data[17],
                },
                {
                  x: "6.5",
                  y: props.data[16],
                },
                {
                  x: "7.0",
                  y: props.data[15],
                },
                {
                  x: "7.5",
                  y: props.data[14],
                },
                {
                  x: "8.0",
                  y: props.data[13],
                },
                {
                  x: "8.5",
                  y: props.data[12],
                },
                {
                  x: "9.0",
                  y: props.data[11],
                },
                {
                  x: "10.0",
                  y: props.data[10],
                },
                {
                  x: "10.5",
                  y: props.data[9],
                },
                {
                  x: "11.0",
                  y: props.data[8],
                },
                {
                  x: "11.5",
                  y: props.data[7],
                },
                {
                  x: "12.0",
                  y: props.data[6],
                },
                {
                  x: "12.5",
                  y: props.data[5],
                },
                {
                  x: "13.0",
                  y: props.data[4],
                },
                {
                  x: "13.5",
                  y: props.data[3],
                },
                {
                  x: "14.0",
                  y: props.data[2],
                },
                {
                  x: "14.5",
                  y: props.data[1],
                },
                {
                  x: "15.0",
                  y: props.data[0],
                },
              ],
            },
          ]}
          margin={{ top: 10, right: 20, bottom: 25, left: 35 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
        />
      </div>
    </div>
  );
};
export const Table: FunctionComponent<{
  data: number[];
}> = function (props) {
  return (
    <div
      style={{
        display: "flex",
        // height: "300px",
        justifyContent: "space-around",
        padding: "0 20px",
      }}
    >
      <div style={{}}>
        <GridColumn5>
          <GridItem2
            style={{
              ...TypographyStyle.label.semiBold16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "12px",
            }}
          >
            5초전
          </GridItem2>
          <GridItem2
            style={{ ...TypographyStyle.label.semiBold16, fontSize: "13px" }}
          >
            4.5초전
          </GridItem2>
          <GridItem2
            style={{ ...TypographyStyle.label.semiBold16, fontSize: "13px" }}
          >
            4초전
          </GridItem2>
          <GridItem2
            style={{ ...TypographyStyle.label.semiBold16, fontSize: "13px" }}
          >
            3.5초전
          </GridItem2>
          <GridItem2
            style={{ ...TypographyStyle.label.semiBold16, fontSize: "13px" }}
          >
            3초전
          </GridItem2>
          {/* <div style={{ display: "flex" }}> */}
          <GridItem2>{props.data[0]}</GridItem2>
          <GridItem2>{props.data[1]}</GridItem2>
          <GridItem2>{props.data[2]}</GridItem2>
          <GridItem2>{props.data[3]}</GridItem2>
          <GridItem2>{props.data[4]}</GridItem2>
          <GridItem2
            style={{ ...TypographyStyle.label.semiBold16, fontSize: "13px" }}
          >
            2.5초전
          </GridItem2>
          <GridItem2
            style={{ ...TypographyStyle.label.semiBold16, fontSize: "13px" }}
          >
            2초전
          </GridItem2>
          <GridItem2
            style={{ ...TypographyStyle.label.semiBold16, fontSize: "13px" }}
          >
            1.5초전
          </GridItem2>
          <GridItem2
            style={{ ...TypographyStyle.label.semiBold16, fontSize: "13px" }}
          >
            1초전
          </GridItem2>
          <GridItem2
            style={{ ...TypographyStyle.label.semiBold16, fontSize: "13px" }}
          >
            0.5초전
          </GridItem2>
          <GridItem2>{props.data[5]}</GridItem2>
          <GridItem2>{props.data[6]}</GridItem2>
          <GridItem2>{props.data[7]}</GridItem2>
          <GridItem2>{props.data[8]}</GridItem2>
          <GridItem2>{props.data[9]}</GridItem2>
        </GridColumn5>
      </div>
    </div>
  );
};
