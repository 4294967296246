import axios from "axios";
import { cctvInfoByIdType } from "../types/cctvTypes";
export const apiUrl = `/guide/cctvOpenData.do?key=${process.env.REACT_APP_CCTV_KEY}`;
const getCctvPopup = () => {};
const minY = 36.25;
const maxY = 36.45;
const minX = 127.25;
const maxX = 128.25;

export function getCctvUrl(resultDatas: cctvInfoByIdType[]) {
  let urls: string[] = [];
  for (let i = 0; i < resultDatas.length; i++) {
    const cctvUrl = `http://www.utic.go.kr/view/map/openDataCctvStream.jsp?key=${
      process.env.REACT_APP_CCTV_KEY
    }&cctvid=${resultDatas[i].CCTVID}&cctvName=${encodeURI(
      encodeURIComponent(resultDatas[i].CCTVNAME)
    )}&kind=${resultDatas[i].KIND}&cctvip=${resultDatas[i].CCTVIP}&cctvch=${
      resultDatas[i].CH
    }&id=${resultDatas[i].ID}&cctvpasswd=${resultDatas[i].PASSWD}&cctvport=${
      resultDatas[i].PORT
    }`;
    urls.push(cctvUrl);
  }
  return urls;
}

export async function getNearCctvDatas(cctvData: any, indexData: number[]) {
  try {
    let nearCctvData: cctvInfoByIdType[] = [];
    for (let i = 0; i < 4; i++) {
      const getData = await getCctvIDAndPW(
        cctvData[indexData[i]].properties.CCTVID
      );
      nearCctvData.push(getData);
    }
    return nearCctvData;
  } catch (error) {
    return null;
  }
}

//대전시 안에 있는 좌표값인지 검증하는 함수
export function getDaeJeonInRangeCoordinate(lat: number, lng: number) {
  try {
    if (lat >= minY && lat <= maxY && lng >= minX && lng <= maxX) {
      return true;
    }
    return false;
  } catch (error) {
    throw console.log(error);
  }
}
//가장 짧은 거리에 있는 CCTV 4개 정보 반환하는 함수
export function getCCTVCoordinate(lat: number, lng: number, coordList: any) {
  let resultCctvObjectIndex: number[] = new Array(0);

  //지정 좌표와 거리 계산
  const distances = coordList.map((coord: any) =>
    Math.sqrt(
      (coord.geometry.coordinates[1] - lat) ** 2 +
        (coord.geometry.coordinates[0] - lng) ** 2
    )
  );
  //가장 짧은 거리의 값 4개 찾기
  const minDistances = [...distances]
    .sort((a: number, b: number) => a - b)
    .slice(0, 4);

  //가장 작은 값의 index찾기
  for (let i = 0; i < 4; i++) {
    resultCctvObjectIndex.push(distances.indexOf(minDistances[i]));
  }
  return resultCctvObjectIndex;
}

//CCTV ID, PW 찾는 함수
export async function getCctvIDAndPW(cctvID: string) {
  try {
    const response = await axios.get(
      `/map/getCctvInfoById.do?cctvId=${cctvID}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
export default getCctvPopup;
