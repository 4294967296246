import { FunctionComponent, useEffect } from "react";
import {
  CardSectionFlex,
  FlexChildStretchContainer,
  SolutionContainer,
  SolutionDetailP,
  SolutionMainH3,
  SolutionTextContainer,
  Spacer2em,
} from "./ContentsCard.style";
import { styled } from "styled-components";
import { Spacer } from "../StyleComponent/Spacer";

//side => right : true
//        left : false
type CardProps = {
  side: boolean | any;
  title: string | null;
  imgURL: string;
  children: React.ReactNode;
};

const ContentsCard: FunctionComponent<CardProps> = function (props: CardProps) {
  useEffect(() => {
    const resizeListener = () => {};
    window.addEventListener("resize", resizeListener);
  });

  return (
    <CardSectionFlex style={props.side ? {} : { backgroundColor: "#FAFAFA" }}>
      <FlexChildStretchContainer>
        <SolutionContainer className={props.side ? "reverse" : ""}>
          {/* left */}
          {props.side ? (
            props.imgURL ? (
              <ImgWrapper>
                <img
                  style={{ objectFit: "cover", width: "300px" }}
                  src={props.imgURL}
                  alt=""
                />
              </ImgWrapper>
            ) : null
          ) : null}
          {props.side ? <Spacer space={"50px"} /> : null}
          <SolutionTextContainer>
            <SolutionMainH3>{props.title}</SolutionMainH3>
            <Spacer2em />
            <SolutionDetailP>{props.children}</SolutionDetailP>
          </SolutionTextContainer>
          {props.side ? null : <Spacer space={"50px"} />}
          {/* right */}
          <div>
            {
              props.side ? null : props.imgURL ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ objectFit: "cover", width: "300px" }}
                    src={props.imgURL}
                    alt=""
                  />
                </div>
              ) : null
              // <IntroAniComponent />
            }
          </div>
        </SolutionContainer>
      </FlexChildStretchContainer>
    </CardSectionFlex>
  );
};

const ImgWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    // margin-left: 48px;
  }
`;

export default ContentsCard;
