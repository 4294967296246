import { signInWithEmailAndPassword } from "firebase/auth";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { auth } from "../../FirebaseSetting";
import { emeraldScaleTheme, grayScaleTheme } from "../Layout/RootColorStyle";
import { defaultColorTheme } from "../Layout/Theme";
import { Spacer } from "../StyleComponent/Spacer";
import {
  MediumBodyCaption,
  SemiBoldSubHead01,
} from "../StyleComponent/FontStyle";
import { BorderInput } from "../StyleComponent/BorderInput";
import { BorderButton } from "../StyleComponent/BorderButton";

export const CarLogin: FunctionComponent = function () {
  const navigation = useNavigate();
  const userInfo = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handlePwd = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPwd(e.target.value);
  };

  const handleSubit = () => {
    signInWithEmailAndPassword(auth, email, pwd)
      .then(() => {
        // alert("로그인 성공");
        navigation("/en");
      })
      .catch((e) => {
        alert("회원 정보를 확인해주세요.");
      });
  };

  useEffect(() => {
    if (userInfo) {
      navigation("/en");
    }
  }, [navigation, userInfo]);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: grayScaleTheme.grayScaleColor400,
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          padding: "40px 36px",
          width: "480px",
          height: "444px",
          backgroundColor: defaultColorTheme.White,
          borderRadius: "16px",
          boxShadow: "0 2px 16px 0 gray",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: grayScaleTheme.grayScaleColor300,
            paddingBottom: "16px",
            marginBottom: "27px",
          }}
        >
          <img src="/img/LogoCar.png" alt="" style={{ width: "113px" }} />
          <Spacer space="16px" />
          <SemiBoldSubHead01>Cartalog Data Server Login</SemiBoldSubHead01>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <MediumBodyCaption
            style={{ color: grayScaleTheme.grayScaleColor500 }}
          >
            ID
          </MediumBodyCaption>
          <BorderInput
            type="email"
            placeholder="Please Insert e-mail"
            onChange={handleEmail}
            value={email}
            style={{
              backgroundColor: grayScaleTheme.grayScaleColor50,
              padding: "12px 16px",
              borderRadius: "8px",
              margin: "4px 0",
            }}
          />

          <Spacer horizontal={true} space="24px" />

          <MediumBodyCaption
            style={{ color: grayScaleTheme.grayScaleColor500 }}
          >
            Password
          </MediumBodyCaption>
          <BorderInput
            type="password"
            placeholder="Please Insert Password"
            onChange={handlePwd}
            value={pwd}
            style={{
              backgroundColor: grayScaleTheme.grayScaleColor50,
              padding: "12px 16px",
              borderRadius: "8px",
              margin: "4px 0",
            }}
          />

          <Spacer horizontal={true} space="60px" />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <BorderButton
              onClick={handleSubit}
              borderRadius={"8px"}
              backgroundColor={grayScaleTheme.grayScaleColor100}
              style={{
                color: defaultColorTheme.White,
                fontWeight: 500,
                borderRadius: "8px",
                width: "73px",
                fontSize: "16px",
                height: "40px",
                backgroundColor: emeraldScaleTheme.emeraldScaleColor500,
                // border: "2px solid",
                // borderColor: emeraldScaleTheme.emeraldScaleColor500,
                textAlign: "center",
              }}
              textColor={""}
            >
              LOGIN
            </BorderButton>
          </div>
        </div>
      </div>
    </div>
  );
};
