import { Icon } from "@iconify/react";
import { FunctionComponent, useEffect, useState } from "react";
import { mainTheme } from "./Theme";
import { Spacer } from "../StyleComponent/Spacer";
import { TypographyComponent } from "../StyleComponent/FontStyle";
import { FlexSpaceBtwtoCenter } from "./Layout.Style";

type ButtonContentProps = {
  iconSrc: string;
  title: string;
  noticeFlag: boolean;
};

const ButtonContent: FunctionComponent<ButtonContentProps> = function ({
  iconSrc,
  title,
  noticeFlag,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // 창 크기가 변할 때마다 실행되는 이벤트 리스너 추가
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <FlexSpaceBtwtoCenter>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon icon={iconSrc} fontSize={"25px"} />
          {windowWidth <= 1200 ? null : (
            <>
              <Spacer space={"23.33px"} />
              <TypographyComponent.label.semiBold16>
                {title}
              </TypographyComponent.label.semiBold16>
            </>
          )}
        </div>
        {noticeFlag ? (
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "20px",
              color: "white",
              backgroundColor: mainTheme.mainColor500,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <TypographyComponent.label.semiBold20>
              2
            </TypographyComponent.label.semiBold20>
          </div>
        ) : null}
      </FlexSpaceBtwtoCenter>
    </>
  );
};

export default ButtonContent;
