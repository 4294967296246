import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { MenuHorizontalWrapper, MenuLogoImg } from "../Menu.style";
import Accordion from "../Accordion";
import { ButtonStyle, defaultColorTheme, orangeScaleTheme } from "../Theme";
import { Icon } from "@iconify/react";
import ButtonContent from "../ButtonContents";
import Modal from "../Modal";
import Profile from "../Profile";
import { useNavigate } from "react-router-dom";
import SockJS from "sockjs-client";
import Stomp from "stompjs"; // <------변경
import { DividerLine } from "../../StyleComponent/DividerLine";
import { grayScaleTheme } from "../RootColorStyle";
import { Spacer } from "../../StyleComponent/Spacer";
import {
  ShadowTheme,
  TypographyComponent,
  TypographyStyle,
} from "../../StyleComponent/FontStyle";
import { BorderButton } from "../../StyleComponent/BorderButton";
import { dataObjectType } from "../../page/ControllServer";
import { ReportItemContext } from "../../../context/reportItemContext";
import { AccidentIndexContext } from "../../../context/accidentIndexContext";
import axios from "axios";
export type sortType = {
  car: boolean;
  bicycle: boolean;
  wheelchair: boolean;
  pedestrian: boolean;
};
export type Item = {
  dataKey: string;
  dataObject: dataObjectType;
};

export type MenuHorizontalProps = {
  originItems: Item[];
};
// const dateObject = new Date();
// const Year = dateObject.getFullYear().toString();
// const Month = (dateObject.getMonth() + 1).toString();
// const Day = dateObject.getDate().toString();

var oldIdx = 0;
const MenuHorizontal: FunctionComponent<MenuHorizontalProps> = function ({
  // modalSorting,
  originItems,
}) {
  const [items, setItems] = useState<Item[]>([]);
  const [selectedButton, setSelectedButton] = useState<number>(-1);
  const [hoverButton, setHoverButton] = useState<number | null>(null);
  const returnData = useContext(ReportItemContext);
  const indexData = useContext(AccidentIndexContext);
  const navigate = useNavigate();
  const [isOpenModal, setOpenModal] = useState(false);
  // const [totalModalSorting, setTotalModalSorting] = useState(true);
  // const [filteredItems, setFiltereditems] = useState<Item[]>([]);
  // const [CSV, setCSV] = useState<dataObjectType[]>([]);
  // const csvHeader = [
  //   "가속도,주소,날짜,회원 토큰,위치정보,Roll,사고유형,Yaw, ,온도,주행 타입, Roll",
  // ];
  // const CSVFileName =
  //   "MyRen accident data report : " + Year + "_" + Month + "_" + Day;
  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);

  // const checkTotalSorting = (props: sortType) => {
  //   const isAllTrue = Object.values(props).every((value) => value === true);
  //   return isAllTrue;
  // };
  // const [modalSorting, setModalSorting] = useState<sortType>({
  //   car: true,
  //   bicycle: true,
  //   wheelchair: true,
  //   pedestrian: true,
  // });

  /* modal sorting 하면 추출될 파일 filtering */
  // useEffect(() => {
  //   if (Object.values(modalSorting).every((value) => value === true)) {
  //     setTotalModalSorting(true);
  //   } else {
  //     setTotalModalSorting(false);
  //   }

  //   if (isOpenModal) {
  //     const newCSV: dataObjectType[] = [];
  //     filteredItems.forEach((data) => {
  //       newCSV.push(data.dataObject);
  //     });
  //     setCSV(newCSV);
  //   }
  // }, [modalSorting, originItems, isOpenModal, filteredItems]); //
  // const [oldIdx, setOldIdx] = useState(0);
  useEffect(() => {
    axios
      .get("/api/accident-record/all")
      .then((Response) => {
        const dataKey = Object.keys(Response.data.data);
        const dataValue = Object.values(Response.data.data);
        const Items: Item[] = dataKey.map((key, index) => ({
          dataKey: key,
          dataObject: dataValue[index] as dataObjectType,
        }));
        Items.reverse();
        oldIdx = Items[0].dataObject.idx;
      })
      .catch((Error) => {
        console.log(Error);
      });
    return () => {};
  }, []);
  // let oldIdx = 0;
  useEffect(() => {
    const socket = new SockJS("https://alwayssafeu.com/wss");
    // const stompClient = over(socket);// <------기존
    const stompClient = Stomp.over(socket); // <------변경

    stompClient.connect({}, (frame) => {
      stompClient.subscribe("/topic/accidentRecords", (message) => {
        if (message.body) {
          const Item = {
            dataKey: items.length.toString(),
            dataObject: JSON.parse(message.body),
          };
          if (oldIdx < Item.dataObject.idx) {
            oldIdx = Item.dataObject.idx;
            setItems((items) => [Item, ...items]);
          }
          setSelectedButton(0);
        }
      });
    });

    // cleanup on unmount
    return () => {
      if (stompClient.connected) {
        stompClient.disconnect(() => {}, {});
      }
    };
  }, []);
  return (
    <>
      {isOpenModal && (
        <Modal onClickToggleModal={onClickToggleModal}>
          <div
            style={{
              padding: "28px 32px",
              borderRadius: "16px",
              zIndex: 10000,
              backgroundColor: defaultColorTheme.White,
              maxWidth: "90%",
              maxHeight: "90%",
              minWidth: "404px",
              minHeight: "294px",
              boxShadow: ShadowTheme._16,
            }}
          >
            <div
              style={{
                display: "flex",
                width: "50vw",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginBottom: "16px",
                  marginRight: "16px",
                }}
              >
                <Icon
                  icon={"priority_high"}
                  fontSize={"25px"}
                  style={{ marginRight: "16px" }}
                />
                <TypographyComponent.label.semiBold20>
                  사고기록
                </TypographyComponent.label.semiBold20>
                <div
                  style={{
                    overflowY: "scroll",
                    height: "75vh",
                    padding: "0 5px",
                  }}
                >
                  {originItems.map((item, index) => {
                    return (
                      <div key={index}>
                        <BorderButton
                          key={index}
                          onMouseEnter={() => setHoverButton(index)}
                          onMouseLeave={() => setHoverButton(null)}
                          onClick={() => {
                            setSelectedButton(index);
                            returnData.updateData(item.dataObject);
                          }}
                          style={{
                            borderRadius: "8px",
                            backgroundColor:
                              selectedButton === index
                                ? grayScaleTheme.grayScaleColor500
                                : hoverButton === index
                                ? grayScaleTheme.grayScaleColor50
                                : grayScaleTheme.grayScaleColor100,
                            padding: "12px",
                            marginBottom: "12px",
                            boxShadow:
                              hoverButton === index
                                ? ShadowTheme._12
                                : ShadowTheme._2,
                            width: "100%",
                          }}
                          borderRadius={"8px"}
                          textColor={""}
                          backgroundColor={defaultColorTheme.White}
                          paddingHorizontal="12px"
                          paddingVertical="12px"
                          marginBottom="20px"
                        >
                          <div
                            style={{
                              display: "flex",
                              paddingLeft: "12px",
                              width: "100%",
                              justifyContent: "space-between",
                              textAlign: "left",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  width: "fit-content",
                                  display: "flex",
                                  alignItems: "center",
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                <Icon
                                  icon={
                                    "healthicons:accident-and-emergency-outline"
                                  }
                                  style={{
                                    color:
                                      selectedButton === index
                                        ? defaultColorTheme.White
                                        : grayScaleTheme.grayScaleColor800,
                                  }}
                                />
                                <Spacer horizontal={false} space={"8px"} />
                                {item.dataObject.speed === "-"
                                  ? "직접신고"
                                  : ""}
                              </div>
                              <div
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                {item.dataObject.address}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "28%",
                              }}
                            >
                              <div
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                {item.dataObject.date.slice(0, 10)}
                              </div>
                              {/* <Spacer space={"20px"} /> */}
                              <div
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                {
                                  item.dataObject.date
                                    .split(".")[0]
                                    .split("T")[1]
                                }
                              </div>
                              <Icon
                                icon={"ri:arrow-right-s-line"}
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              />
                            </div>
                          </div>
                        </BorderButton>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BorderButton
                  onClick={() => {
                    navigate("/accidentreport");
                  }}
                  style={{
                    ...ButtonStyle.Enabled,
                    padding: "8px 16px",
                    width: "150px",
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  보고서 미리보기
                </BorderButton>
                <Spacer horizontal={true} space={"16px"} />
                <div
                  style={{
                    ...TypographyStyle.paragraphRegular.paragraphRegular12,
                    color: grayScaleTheme.grayScaleColor500,
                  }}
                >
                  직접 신고는 가속도 센서값, 속도, <br />
                  자이로센서(Roll/Yaw/Pitch)를 <br />
                  제공하지 않습니다.
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <MenuHorizontalWrapper
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "20%",
          height: "100vh",
        }}
      >
        <div
          style={{
            padding: " 44px 32px",
          }}
        >
          <MenuLogoImg src="/img/Logo.png" />
          <DividerLine />
          <Accordion
            HiddenChildren={<Profile />}
            AccordionTitle={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon icon={"ion:person-circle"} fontSize={"25px"} />
                <Spacer space={"16px"} />
                <div>
                  <TypographyComponent.label.semiBold20>
                    Profile
                  </TypographyComponent.label.semiBold20>
                </div>
              </div>
            }
          />
          <DividerLine />
          <div style={{ display: "flex", flexDirection: "column" }}>
            목록
            <BorderButton
              style={{ backgroundColor: "white", margin: "0", padding: "0" }}
              onClick={() => {}}
            >
              <ButtonContent
                iconSrc="mdi:exclamation"
                title={"사고 목록"}
                noticeFlag={false}
              />
            </BorderButton>
          </div>
          <DividerLine />
          <div style={{ display: "flex", flexDirection: "column" }}>
            기능
            <BorderButton
              style={{ backgroundColor: "white", margin: "0", padding: "0" }}
              onClick={() => {
                setOpenModal(!isOpenModal);
              }}
            >
              <ButtonContent
                iconSrc="gg:export"
                title={"사고 보고서"}
                noticeFlag={false}
              />
            </BorderButton>
          </div>
          <DividerLine />
          <div style={{ height: "40%" }}>
            알림
            <div
              style={{
                height: "100%",
                backgroundColor: grayScaleTheme.grayScaleColor50,
                borderRadius: "8px",
                overflowY: "scroll",
                maxHeight: "250px",
              }}
            >
              {items.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      padding: "8px",
                      backgroundColor: orangeScaleTheme.orangeScaleColor200,
                      borderRadius: "8px",
                      marginBottom: "8px",
                    }}
                    onClick={() => {
                      indexData.updateIndexData(item.dataObject.idx);
                      if (items.length === 1) {
                        setItems([]);
                      } else {
                        items.splice(index, 1);
                      }
                    }}
                    key={index}
                  >
                    <div>
                      <img
                        src="./img/notification_orange.png"
                        alt=""
                        style={{ marginRight: "8px" }}
                      />
                    </div>
                    <div>
                      <div style={{ ...TypographyStyle.label.semiBold16 }}>
                        {item.dataObject.type}
                      </div>
                      <div
                        style={{
                          ...TypographyStyle.paragraphRegular
                            .paragraphRegular12,
                          color: grayScaleTheme.grayScaleColor600,
                        }}
                      >
                        {item.dataObject.address}
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* normal */}
              {/* <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: grayScaleTheme.grayScaleColor700,
            height: "72px",
            padding: "12px 16px",
            display: "flex",
            // justifyContent: "space-between",s
            alignItems: "center",
            ...TypographyStyle.paragraphMedium.paragraphMedium16,
            color: grayScaleTheme.grayScaleColor500,
          }}
        >
          <MenuLogoImg src="/img/LogoWhite.png" style={{ width: "30%" }} />
          <Spacer space={"12px"} />
          MYREN Data Server
        </div>
      </MenuHorizontalWrapper>
    </>
  );
};

export default MenuHorizontal;
