import { styled } from "styled-components";
import { grayScaleTheme } from "./RootColorStyle";
import { defaultColorTheme } from "./Theme";
import { ShadowTheme } from "../StyleComponent/FontStyle";

export const FlexBackground = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${grayScaleTheme.grayScaleColor300};
`;

export const FlexCenter = styled.div`
  display: flex;
  alignitems: center;
`;

export const GridColumn4to2 = styled.div`
  //   overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const GridColumn2 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  // gap: 16px;
`;

export const GridColumn5 = styled.div`
  //   overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
`;

export const GridItem2 = styled.div`
  margin: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid;
  border-color: ${grayScaleTheme.grayScaleColor300};
  align-items: center;
  justify-content: center;
  font-size: 13px;
`;

export const GridColumn2to1 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 96px;
  column-gap: 24px;
  padding: 48px 60px;
  justify-items: center;
  background-color: ${grayScaleTheme.grayScaleColor200};
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 26px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    row-gap: 66px;
  }
  //   @media (max-width: 1199px) {
  //     grid-template-columns: repeat(1, 1fr);
  //   }
`;
export const FlexSpaceBtwtoCenter = styled.div`
  display: flex;
  align-items: center;
  //   align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1199px) {
    justify-content: center;
  }
`;

export const GridItem = styled.div`
  //   min-width: 132px;
  min-height: 100px;
  padding: 12px;
  background-color: ${defaultColorTheme.White};
  // background-color: ${grayScaleTheme.grayScaleColor200};
  border-radius: 8px;
  // box-shadow: ${ShadowTheme._8};
  display: flex;
  flex-direction: column;

  &:first-child {
    grid-column-end: span 2;
  }
  &:last-child {
    grid-column-end: span 2;
  }
  // &:nth-last-child(2) {
  //   grid-column-end: span 2;
  // }
`;
