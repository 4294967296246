import { User } from "@firebase/auth";
import { FunctionComponent, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import { auth } from "../FirebaseSetting";

const AuthProvider: FunctionComponent<{ children: React.ReactNode }> = (
  props
) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const subscribe = auth.onAuthStateChanged((fbUser) => {
      setUser(fbUser);
    });
    return subscribe;
  }, []);

  return (
    <AuthContext.Provider value={user}>{props.children}</AuthContext.Provider>
  );
};

export default AuthProvider;
