import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Hambuger,
  MainIcon,
  MenuItemDropDownContainer,
  MenuWrapper,
  TopContents,
} from "./Menu.style";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import MenuContents from "./MenuContents";

const Menu: FunctionComponent = function () {
  const [open, setOpen] = useState(false);
  const [openAnimation, setOpenAnimation] = useState(false);
  const [repeat, setRepeat] = useState(undefined);

  useEffect(() => {
    if (open) {
      clearTimeout(repeat);
      setRepeat(undefined);
      setOpenAnimation(true);
    } else {
      setTimeout(() => {
        setOpenAnimation(false);
        setRepeat(undefined);
      }, 400);
    }
  }, [open, repeat]);

  return (
    <MenuWrapper>
      <TopContents>
        <Link to={"/"}>
          <MainIcon src="./img/Logo.png" />
        </Link>
        <div style={{ display: "flex", justifyContent: "center" }}></div>
        <MenuContents className={"max-width-page"} />
        {/* <ContactBtn className="max-width-page">Contact</ContactBtn> */}
        {/* <div style={{width:"2em"}}/> */}
        <Hambuger onClick={() => setOpen(!open)}>
          {window.innerWidth >= 320 && window.innerWidth <= 768 ? (
            <Icon icon="material-symbols:menu" width={24} />
          ) : (
            <Icon icon="material-symbols:menu" width={52} />
          )}
        </Hambuger>
      </TopContents>

      <MenuItemDropDownContainer
        className={open ? "slide-fade-in-dropdown" : "slide-fade-out-dropdown"}
      >
        {openAnimation && (
          <>
            <MenuContents className={undefined} />
            {/* <ContactBtn>Contact</ContactBtn> */}
          </>
        )}
      </MenuItemDropDownContainer>
    </MenuWrapper>
  );
};

export default Menu;
