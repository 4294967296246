import { FunctionComponent, ReactElement } from "react";
import { defaultColorTheme } from "./Theme";

const Modal: FunctionComponent<{
  Footer?: boolean;
  onClickToggleModal: () => void;
  children: ReactElement | ReactElement[] | string;
}> = function ({ onClickToggleModal, children, Footer }) {
  return (
    <div
      style={
        Footer
          ? {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }
          : {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              width: "100%",
              height: "100%",
            }
      }
    >
      {children}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 9999,
          backgroundColor: defaultColorTheme.Black,
          opacity: 0.4,

          // width: "100vw",
          // height: "100vh",
          // position: "fixed",
          // top: 0,
          // zIndex: 9999,
          // backgroundColor: grayScaleTheme.grayScaleColor600,
          // opacity: 0.3,
        }}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          if (onClickToggleModal) {
            onClickToggleModal();
          }
        }}
      ></div>
    </div>
  );
};

export default Modal;
