import React, { FunctionComponent } from "react";
import Menu from "../Layout/Menu";
import Footer from "../Layout/Footer";
import { GridColumn2to1 } from "../Layout/Layout.Style";

const AppIntroducePage: FunctionComponent = function () {
  return (
    <>
      <Menu />
      <GridColumn2to1>
        <img src="./img/1-1.png" alt="" />
        <img src="./img/2-1.png" alt="" />
        <img src="./img/3-1.png" alt="" />
        <img src="./img/4-1.png" alt="" />
        <img src="./img/5-1.png" alt="" />
        <img src="./img/6-1.png" alt="" />
        <img src="./img/7-1.png" alt="" />
        <img src="./img/8-1.png" alt="" />
        <img src="./img/9-1.png" alt="" />
        <img src="./img/10-1.png" alt="" />
        <img src="./img/11-1.png" alt="" />
        <img src="./img/12-1.png" alt="" />
        <img src="./img/13-1.png" alt="" />
        <img src="./img/14-1.png" alt="" />
        <img src="./img/15-1.png" alt="" />
        <img src="./img/16-1.png" alt="" />
        <img src="./img/17-1.png" alt="" />
        <img src="./img/18-1.png" alt="" />
        <img src="./img/19-1.png" alt="" />
        <img src="./img/20-1.png" alt="" />
        <img src="./img/21-1.png" alt="" />
        <img src="./img/22-1.png" alt="" />
        <img src="./img/23-1.png" alt="" />
        <img src="./img/25.png" alt="" />
      </GridColumn2to1>
      <Footer />
    </>
  );
};

export default AppIntroducePage;
