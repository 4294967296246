import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Layout/Footer";
import Menu from "../Layout/Menu";
import ThirdContents from "../Main/ThirdContents";
import SecondContents from "../SecondContents/SecondContents";
import { CountUserLabel } from "../Main/CountUserLabel";

const Main: FunctionComponent = function () {
  const location = useLocation();
  if (location.pathname === "/en") {
    console.log("en");
    window.localStorage.setItem("language", "true");
  } else if (location.pathname === "/") {
    window.localStorage.setItem("language", "false");
  }
  return (
    <>
      <Menu />
      <SecondContents />
      <ThirdContents />
      <CountUserLabel />
      <Footer />
    </>
  );
};

export default Main;
