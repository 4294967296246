import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { ReportItemContext } from "../../../context/reportItemContext";
import { AccidentIndexContext } from "../../../context/accidentIndexContext";
import SockJS from "sockjs-client";
import Stomp from "stompjs"; // <------변경
import Modal from "../Modal";
import { ButtonStyle, defaultColorTheme, orangeScaleTheme } from "../Theme";
import {
  ShadowTheme,
  TypographyComponent,
  TypographyStyle,
} from "../../StyleComponent/FontStyle";
import { BorderButton } from "../../StyleComponent/BorderButton";
import { grayScaleTheme } from "../RootColorStyle";
import { Icon } from "@iconify/react";
import { Spacer } from "../../StyleComponent/Spacer";
import { MenuHorizontalWrapper, MenuLogoImg } from "../Menu.style";
import { DividerLine } from "../../StyleComponent/DividerLine";
import Accordion from "../Accordion";
import Profile from "../Profile";
import ButtonContent from "../ButtonContents";
export type sortType = {
  car: boolean;
  bicycle: boolean;
  wheelchair: boolean;
  pedestrian: boolean;
};
export type Item = {
  dataKey: string;
  dataObject: dataObjectType;
};
export type dataObjectType = {
  accel: string;
  address: string;
  createdAt: string;
  date: string;
  idx: number;
  insurance: string;
  location: string;
  number: string;
  ownName: string;
  ownType: string;
  parentPhoneNumber: string;
  pitch: string;
  pty: string;
  roll: string;
  sky: string;
  speed: string;
  t1h: string;
  type: string;
  updatedAt: string;
  userId: string;
  yaw: string;
};
// export type dataObjectType = {
//   idx: number;
//   date: string;
//   address: string;
//   location: string;
//   speed: string;
//   rpm: string;
// };
export type MenuHorizontalProps = {
  originItems: Item[];
  selectItemIdx: number;
};
var oldIdx = 0;
const CarMenuHorizontal: FunctionComponent<MenuHorizontalProps> = function ({
  originItems,
  selectItemIdx,
}) {
  const [items, setItems] = useState<Item[]>([]);
  const [selectedButton, setSelectedButton] = useState<number>(-1);
  const [isOpenModal, setOpenModal] = useState(false);
  const [hoverButton, setHoverButton] = useState<number | null>(null);
  const navigate = useNavigate();
  const returnData = useContext(ReportItemContext);
  const indexData = useContext(AccidentIndexContext);
  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);
  useEffect(() => {
    const socket = new SockJS("https://alwayssafeu.com/wss");
    // const stompClient = over(socket);// <------기존
    const stompClient = Stomp.over(socket); // <------변경

    stompClient.connect({}, (frame) => {
      stompClient.subscribe("/topic/accidentRecords", (message) => {
        if (message.body) {
          const Item = {
            dataKey: items.length.toString(),
            dataObject: JSON.parse(message.body),
          };
          if (oldIdx < Item.dataObject.idx) {
            oldIdx = Item.dataObject.idx;
            setItems((items) => [Item, ...items]);
          }
          setSelectedButton(0);
        }
      });
    });

    // cleanup on unmount
    return () => {
      if (stompClient.connected) {
        stompClient.disconnect(() => {}, {});
      }
    };
  }, []);
  return (
    <>
      {isOpenModal && (
        <Modal onClickToggleModal={onClickToggleModal}>
          <div
            style={{
              padding: "28px 32px",
              borderRadius: "16px",
              zIndex: 10000,
              backgroundColor: defaultColorTheme.White,
              maxWidth: "90%",
              maxHeight: "90%",
              minWidth: "404px",
              minHeight: "294px",
              boxShadow: ShadowTheme._16,
            }}
          >
            <div
              style={{ display: "flex", width: "50vw", alignItems: "center" }}
            >
              <div
                style={{
                  marginBottom: "16px",
                }}
              >
                <TypographyComponent.label.semiBold20>
                  Accident List
                </TypographyComponent.label.semiBold20>
                <div
                  style={{
                    overflowY: "scroll",
                    height: "75vh",
                    padding: "0 5px",
                  }}
                >
                  {originItems.map((item, index) => {
                    return (
                      <div>
                        <BorderButton
                          key={index}
                          onMouseEnter={() => setHoverButton(index)}
                          onMouseLeave={() => setHoverButton(null)}
                          onClick={() => {
                            setSelectedButton(index);
                            returnData.updateData(item.dataObject);
                          }}
                          style={{
                            borderRadius: "8px",
                            backgroundColor:
                              selectedButton === index
                                ? grayScaleTheme.grayScaleColor500
                                : hoverButton === index
                                ? grayScaleTheme.grayScaleColor50
                                : grayScaleTheme.grayScaleColor100,
                            padding: "12px",
                            marginBottom: "12px",
                            boxShadow:
                              hoverButton === index
                                ? ShadowTheme._12
                                : ShadowTheme._2,
                            width: "100%",
                          }}
                          borderRadius={"8px"}
                          textColor={""}
                          backgroundColor={defaultColorTheme.White}
                          paddingHorizontal="12px"
                          paddingVertical="12px"
                          marginBottom="20px"
                        >
                          <div
                            style={{
                              display: "flex",
                              paddingLeft: "12px",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  width: "fit-content",
                                  display: "flex",
                                  alignItems: "center",
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                <Icon
                                  icon={
                                    "healthicons:accident-and-emergency-outline"
                                  }
                                  style={{
                                    color:
                                      selectedButton === index
                                        ? defaultColorTheme.White
                                        : grayScaleTheme.grayScaleColor800,
                                  }}
                                />
                                <Spacer horizontal={false} space={"8px"} />
                              </div>
                              <div
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                {item.dataObject.address}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "28%",
                              }}
                            >
                              <div
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                {item.dataObject.date.slice(0, 10)}
                              </div>
                              {/* <Spacer space={"20px"} /> */}
                              <div
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                {
                                  item.dataObject.date
                                    .split(".")[0]
                                    .split("T")[1]
                                }
                              </div>
                              {/* <Spacer space={"20px"} /> */}
                              <Icon
                                icon={"ri:arrow-right-s-line"}
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              />
                            </div>
                          </div>
                        </BorderButton>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div style={{ marginLeft: "8px" }}>
                <BorderButton
                  onClick={() => {
                    // window.open("/accidentreport", "_blank");
                    navigate("/caraccidentreport");
                  }}
                  style={{ ...ButtonStyle.Enabled, padding: "8px 16px" }}
                >
                  Report Preview
                </BorderButton>
                <div
                  style={{
                    ...TypographyStyle.paragraphRegular.paragraphRegular12,
                    color: grayScaleTheme.grayScaleColor500,
                  }}
                >
                  Direct reporting does not provide acceleration sensor values,
                  speed, or gyro sensors <br />
                  (Roll/Yaw/Pitch).
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <MenuHorizontalWrapper
        style={{ width: "15%", height: "100vh", padding: "44px 32px" }}
      >
        <MenuLogoImg src="/img/LogoCar.png" />
        <DividerLine />
        <Accordion
          HiddenChildren={<Profile />}
          AccordionTitle={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon icon={"ion:person-circle"} fontSize={"25px"} />
              <Spacer space={"16px"} />
              <div>
                <TypographyComponent.label.semiBold20>
                  Profile
                </TypographyComponent.label.semiBold20>
              </div>
            </div>
          }
        />
        <DividerLine />
        <div style={{ display: "flex", flexDirection: "column" }}>
          List
          <BorderButton
            style={{ backgroundColor: "white", margin: "0", padding: "0" }}
            onClick={() => {}}
          >
            <ButtonContent
              iconSrc="mdi:exclamation"
              title={"Accident"}
              noticeFlag={false}
            />
          </BorderButton>
        </div>
        <DividerLine />
        <div style={{ display: "flex", flexDirection: "column" }}>
          Function
          <BorderButton
            style={{ backgroundColor: "white", margin: "0", padding: "0" }}
            onClick={() => {
              setOpenModal(!isOpenModal);
            }}
          >
            <ButtonContent
              iconSrc="gg:export"
              title={"Report Export"}
              noticeFlag={false}
            />
          </BorderButton>
        </div>
        <DividerLine />

        <div style={{ height: "40%" }}>
          Notice
          <div
            style={{
              height: "100%",
              backgroundColor: grayScaleTheme.grayScaleColor50,
              borderRadius: "8px",
              overflowY: "scroll",
              maxHeight: "250px",
            }}
          >
            {items.map((item, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    padding: "8px",
                    backgroundColor: orangeScaleTheme.orangeScaleColor200,
                    borderRadius: "8px",
                    marginBottom: "8px",
                  }}
                  onClick={() => {
                    indexData.updateIndexData(item.dataObject.idx);
                    if (items.length === 1) {
                      setItems([]);
                    } else {
                      items.splice(index, 1);
                    }
                  }}
                  key={index}
                >
                  <div>
                    <img
                      src="./img/notification_orange.png"
                      alt=""
                      style={{ marginRight: "8px" }}
                    />
                  </div>
                  <div>
                    <div style={{ ...TypographyStyle.label.semiBold16 }}>
                      Car Accident
                    </div>
                    <div
                      style={{
                        ...TypographyStyle.paragraphRegular.paragraphRegular12,
                        color: grayScaleTheme.grayScaleColor600,
                      }}
                    >
                      {item.dataObject.address}
                    </div>
                  </div>
                </div>
              );
            })}
            {/* normal */}
            {/* <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div> */}
            {/* <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  borderRadius: "8px",
                  marginBottom: "8px",
                }}
              >
                <div>
                  <img
                    src="./img/notification_gray.png"
                    alt=""
                    style={{ marginRight: "8px" }}
                  />
                </div>
                <div>
                  <div style={{ ...TypographyStyle.label.semiBold16 }}>
                    Car Crash
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor600,
                    }}
                  >
                    address
                  </div>
                </div>
              </div>*/}
          </div>
        </div>
      </MenuHorizontalWrapper>
    </>
  );
};

export default CarMenuHorizontal;
