import React from "react";

type accidentIndexContextType = {
  accidentIndex: number;
  notiItemLength: number;
  updateIndexData: (newData: number) => void;
  updateNotiIndexData: (newData: number) => void;
};

export const AccidentIndexContext =
  React.createContext<accidentIndexContextType>({
    accidentIndex: 0,
    notiItemLength: 0,
    updateIndexData: function (newData: number): void {},
    updateNotiIndexData: function (newData: number): void {},
  });
