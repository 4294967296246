import { FunctionComponent, useContext } from "react";
import { cctvInfoByIdType, cctvPropsType } from "../../types/cctvTypes";
import { CctvIndexContext } from "../../context/clickCctvContext";
// src/OpenDataCCTV.json
//lattitude = 위도Y
//longtitude = 경도X

//CCTV URL 지정하는 함수

const CctvPage: FunctionComponent<cctvPropsType> = function ({ nearData }) {
  //   console.log(cctvData[0]);
  const clickedCctv = useContext(CctvIndexContext);
  return (
    <>
      {nearData.map((item, key) => {
        return (
          <iframe
            style={
              clickedCctv.cctvIndex === key
                ? { width: "100%", height: "100%", border: "5px solid red" }
                : { width: "100%", height: "100%" }
            }
            src={item}
            key={key}
          />
        );
      })}
    </>
  );
};

export default CctvPage;
