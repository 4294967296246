import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from "react";
import { LogoWrapper, MenuHorizontalWrapper, MenuLogoImg } from "../Menu.style";

import Accordion from "../Accordion";
import ButtonContent from "../ButtonContents";
import Modal from "../Modal";
import { ButtonStyle, defaultColorTheme } from "../Theme";
import { MenuHorizontalProps } from "./MenuHorizontal";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { ReportItemContext } from "../../../context/reportItemContext";
import {
  ShadowTheme,
  TypographyComponent,
  TypographyStyle,
} from "../../StyleComponent/FontStyle";
import { BorderButton } from "../../StyleComponent/BorderButton";
import { grayScaleTheme } from "../RootColorStyle";
import { Spacer } from "../../StyleComponent/Spacer";
import { DividerLine } from "../../StyleComponent/DividerLine";

const MenuIconHorizontal: FunctionComponent<MenuHorizontalProps> = function ({
  originItems,
}) {
  const [selectedButton, setSelectedButton] = useState<number>(-1);
  const [isOpenModal, setOpenModal] = useState(false);
  const [hoverButton, setHoverButton] = useState<number | null>(null);
  const navigate = useNavigate();
  const returnData = useContext(ReportItemContext);
  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);
  return (
    <>
      {isOpenModal && (
        <Modal onClickToggleModal={onClickToggleModal}>
          <div
            style={{
              padding: "28px 32px",
              borderRadius: "16px",
              zIndex: 10000,
              backgroundColor: defaultColorTheme.White,
              maxWidth: "90%",
              maxHeight: "90%",
              minWidth: "404px",
              minHeight: "294px",
              boxShadow: ShadowTheme._16,
            }}
          >
            <div
              style={{
                // display: "flex",
                width: "50vw",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginBottom: "16px",
                  marginRight: "16px",
                }}
              >
                <Icon
                  icon={"priority_high"}
                  fontSize={"25px"}
                  style={{ marginRight: "16px" }}
                />
                <TypographyComponent.label.semiBold20>
                  사고기록
                </TypographyComponent.label.semiBold20>
                <div
                  style={{
                    overflowY: "scroll",
                    height: "75vh",
                    padding: "0 5px",
                  }}
                >
                  {originItems.map((item, index) => {
                    return (
                      <div key={index}>
                        <BorderButton
                          key={index}
                          onMouseEnter={() => setHoverButton(index)}
                          onMouseLeave={() => setHoverButton(null)}
                          onClick={() => {
                            setSelectedButton(index);
                            returnData.updateData(item.dataObject);
                          }}
                          style={{
                            borderRadius: "8px",
                            backgroundColor:
                              selectedButton === index
                                ? grayScaleTheme.grayScaleColor500
                                : hoverButton === index
                                ? grayScaleTheme.grayScaleColor50
                                : grayScaleTheme.grayScaleColor100,
                            padding: "12px",
                            marginBottom: "12px",
                            boxShadow:
                              hoverButton === index
                                ? ShadowTheme._12
                                : ShadowTheme._2,
                            width: "100%",
                          }}
                          borderRadius={"8px"}
                          textColor={""}
                          backgroundColor={defaultColorTheme.White}
                          paddingHorizontal="12px"
                          paddingVertical="12px"
                          marginBottom="20px"
                        >
                          <div
                            style={{
                              display: "flex",
                              paddingLeft: "12px",
                              width: "100%",
                              justifyContent: "space-between",
                              textAlign: "left",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  width: "fit-content",
                                  display: "flex",
                                  alignItems: "center",
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                <Icon
                                  icon={
                                    "healthicons:accident-and-emergency-outline"
                                  }
                                  style={{
                                    color:
                                      selectedButton === index
                                        ? defaultColorTheme.White
                                        : grayScaleTheme.grayScaleColor800,
                                  }}
                                />
                                <Spacer horizontal={false} space={"8px"} />
                                {item.dataObject.speed === "-"
                                  ? "직접신고"
                                  : ""}
                              </div>
                              <div
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                {item.dataObject.address}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "28%",
                              }}
                            >
                              <div
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                {item.dataObject.date.slice(0, 10)}
                              </div>
                              {/* <Spacer space={"20px"} /> */}
                              <div
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              >
                                {
                                  item.dataObject.date
                                    .split(".")[0]
                                    .split("T")[1]
                                }
                              </div>
                              <Icon
                                icon={"ri:arrow-right-s-line"}
                                style={{
                                  color:
                                    selectedButton === index
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              />
                            </div>
                          </div>
                        </BorderButton>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // flexDirection: "column",
                }}
              >
                <BorderButton
                  onClick={() => {
                    navigate("/accidentreport");
                  }}
                  style={{
                    ...ButtonStyle.Enabled,
                    padding: "8px 16px",
                    width: "150px",
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  보고서 미리보기
                </BorderButton>
                <Spacer horizontal={true} space={"16px"} />
                <div
                  style={{
                    ...TypographyStyle.paragraphRegular.paragraphRegular12,
                    color: grayScaleTheme.grayScaleColor500,
                  }}
                >
                  직접 신고는 가속도 센서값, 속도, 자이로센서(Roll/Yaw/Pitch)를
                  <br />
                  제공하지 않습니다.
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <MenuHorizontalWrapper>
        <LogoWrapper>
          <MenuLogoImg src="/img/MobileLogo.png" alt="" />
        </LogoWrapper>
        <DividerLine />
        <Accordion />
        <DividerLine />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <BorderButton backgroundColor="white" onClick={() => {}}>
            <ButtonContent
              iconSrc="mdi:exclamation"
              title={""}
              noticeFlag={false}
            />
          </BorderButton>
        </div>
        <DividerLine />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <BorderButton
            backgroundColor="white"
            onClick={() => {
              setOpenModal(!isOpenModal);
            }}
          >
            <ButtonContent iconSrc="gg:export" title={""} noticeFlag={false} />
          </BorderButton>
        </div>
        <DividerLine />
      </MenuHorizontalWrapper>
    </>
  );
};

export default MenuIconHorizontal;
