import { FunctionComponent } from "react";

export const Spacer: FunctionComponent<{
  horizontal?: boolean;
  space: string;
}> = function (props) {
  if (props.horizontal) {
    return <div style={{ height: props.space }} />;
  }
  return <div style={{ width: props.space }} />;
};
