import React, { CSSProperties, FunctionComponent, ReactElement } from "react";

import { TypographyStyle } from "./FontStyle";
import { Spacer } from "./Spacer";
import { grayScaleTheme } from "../Layout/RootColorStyle";
import { FlexCenter, GridItem } from "../Layout/Layout.Style";

const VerticalNameTagRadiusBox: FunctionComponent<{
  children: ReactElement | ReactElement[] | string;
  wordBreak?: boolean;
  LabelText: string | null;
  style?: CSSProperties | undefined;
}> = function (props) {
  return (
    <GridItem>
      {props.LabelText == null ? null : (
        <>
          <div
            style={{
              width: "fit-content",
              borderRadius: "8px",
              padding: "4px 8px",
              backgroundColor: grayScaleTheme.grayScaleColor200,
              ...TypographyStyle.paragraphMedium.paragraphMedium16,
            }}
          >
            {props.LabelText}
          </div>
          <Spacer horizontal={true} space={"8px"} />
        </>
      )}

      <FlexCenter
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          height: "100%",
          wordBreak: props.wordBreak ? "keep-all" : "break-all",
          ...TypographyStyle.paragraphMedium.paragraphMedium16,
        }}
      >
        {props.children}
      </FlexCenter>
    </GridItem>
  );
};

export default VerticalNameTagRadiusBox;
