import React, { FunctionComponent } from "react";
import { MenuItemsWrapper, MenuItemWrapper } from "./Menu.style";
import { Link } from "react-router-dom";
import { SidePadding20px } from "../StyleComponent/StyleComponents";
import { BoldHeadLine02, SemiBoldSubHead01 } from "../StyleComponent/FontStyle";

type MenuProps = {
  className: string | undefined;
  children?: React.ReactNode;
};
const TextDataKr = {
  one: "사고났슈 소개",
  two: "사용 안내",
};
const TextDataEn = {
  one: "Introduce",
  two: "How to Use",
};

const MenuContents: FunctionComponent<MenuProps> = function ({ className }) {
  const lang = window.localStorage.getItem("language");
  let TextData = TextDataKr;
  lang === "false" ? (TextData = TextDataKr) : (TextData = TextDataEn);
  // const TextData
  return (
    <MenuItemsWrapper className={className}>
      <Link to={"/"} style={{ display: "flex", textAlign: "center" }}>
        <MenuItemWrapper>
          <SidePadding20px>
            {window.innerWidth <= 1024 ? (
              <SemiBoldSubHead01>{TextData.one}</SemiBoldSubHead01>
            ) : (
              <BoldHeadLine02>{TextData.one}</BoldHeadLine02>
            )}
          </SidePadding20px>
        </MenuItemWrapper>
      </Link>

      <Link to={"/platform"} style={{ display: "flex", textAlign: "center" }}>
        <MenuItemWrapper>
          <SidePadding20px>
            {window.innerWidth <= 1024 ? (
              <SemiBoldSubHead01>{TextData.two}</SemiBoldSubHead01>
            ) : (
              <BoldHeadLine02>{TextData.two}</BoldHeadLine02>
            )}
          </SidePadding20px>
        </MenuItemWrapper>
      </Link>
    </MenuItemsWrapper>
  );
};

export default MenuContents;
