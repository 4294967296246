import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { GoogleMap, MarkerF, OverlayView } from "@react-google-maps/api";
import axios from "axios";
import GSAReport, {
  GSAReport2,
  GSAReport3,
  GSAReport4,
  GSAReport5,
} from "./GSAReport";
import { dataObjectType } from "../Layout/Cartalog_Menu/CarMenuHorizontal";
import { ReportItemContext } from "../../context/reportItemContext";
import { grayScaleTheme } from "../Layout/RootColorStyle";
import {
  ShadowTheme,
  TypographyComponent,
  TypographyStyle,
} from "../StyleComponent/FontStyle";
import { DataLabel } from "../Layout/DataLable";
import { ButtonStyle, defaultColorTheme } from "../Layout/Theme";
import { getAverage, getMax, getMin } from "../../util/data";
import { SensorLabel } from "../Layout/SensorLabel";
type AccidentItemProps = {
  data: dataObjectType;
};
const containerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
};
export type userInfoType = {
  createdAt: string;
  updatedAt: string;
  idx: string;
  userId: string;
  name: string;
  parentNumber: string;
  profileImageUrl: string;
  loginType: string;
};
const CarAccidentItemReport: FunctionComponent = function () {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [dataSpeedAverage, setDataSpeedAverage] = useState<number>(0);
  const [dataSpeedMax, setDataSpeedMax] = useState<number>(0);
  const [dataSpeedDeltaOneSec, setDataSpeedDeltaOneSec] = useState<number>(0);
  const [dataRPMAverage, setDataRPMAverage] = useState<number>(0);
  const [dataRPMMax, setDataRPMMax] = useState<number>(0);
  const [dataRPMDeltaOneSec, setDataRPMDeltaOneSec] = useState<number>(0);
  const [importance, setImportance] = useState<string>("");
  const reportData = useContext(ReportItemContext);
  const speedArray: number[] = [];
  reportData.accidentData.speed
    .split(",")
    .forEach((item) => speedArray.push(Number(item)));

  const API_KEY = "JAXEBGHR";
  const [what3wordsAddress, setWhat3wordsAddress] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const handleConvertToWhat3words = () => {
    // What3words API 키
    const what3wordsApiKey = API_KEY;

    // 좌표를 What3words 주소로 변환하는 API 엔드포인트
    const apiUrl = `https://api.what3words.com/v3/convert-to-3wa?coordinates=${latitude},${longitude}&key=${what3wordsApiKey}`;

    // Axios를 사용하여 API 호출
    axios
      .get(apiUrl)
      .then((response) => {
        // API 응답에서 What3words 주소 추출
        const address = response.data.words;
        setWhat3wordsAddress(address);
      })
      .catch((error) => {});
  };
  const averageSpeed = getAverage(speedArray);
  const maxSpeed = getMax(speedArray);
  const minSpeed = getMin(speedArray);
  const [userData, setUserData] = useState<userInfoType>({
    createdAt: "",
    updatedAt: "",
    idx: "",
    userId: "",
    name: "",
    parentNumber: "",
    profileImageUrl: "",
    loginType: "",
  });
  const checkAccidentType = function (props: dataObjectType) {
    if (props.type !== "자동차") {
      axios
        .post("/api/mobile/user/login", {
          user_id: props.userId,
        })
        .then(function (response) {
          setUserData(response.data.data.user);
          const Item: dataObjectType = {
            ...reportData.accidentData,
            ownName: response.data.data.user.name,
          };
          reportData.updateData(Item);
          console.log(response.data.data.user);
        });
    }
  };

  const generatePDF = async () => {
    // if (contentRef.current) {
    //   const { current: content } = contentRef;
    //   const canvas = await html2canvas(content); // HTML을 캔버스로 렌더링
    //   const pdf = new jsPDF("p", "mm", "a4"); // PDF 인스턴스 생성

    //   const imgData = canvas.toDataURL("image/png"); // 캔버스 이미지 데이터 가져오기

    //   // PDF에 이미지 추가
    //   pdf.addImage(imgData, "PNG", 0, 0, 210, 297); // 여기서 210x297은 A4 페이지 크기입니다.

    //   // PDF 파일 다운로드
    //   pdf.save(
    //     "MyRen Accident Report(" +
    //       reportData.accidentData.date.split("T")[0] +
    //       ").pdf"
    //   );
    // }
    if (contentRef.current) {
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const pages = contentRef.current.children as HTMLCollection;

      for (let i = 0; i < pages.length; i++) {
        const page = pages[i] as HTMLElement;
        // const canvas = await html2canvas(page);
        const canvas = await html2canvas(page, {
          allowTaint: true,
          useCORS: true,
        }); // HTML을 캔버스로 렌더링
        const imgData = canvas.toDataURL("image/png");
        console.log(imgData);

        if (i > 0) {
          pdf.addPage();
        }

        pdf.addImage(imgData, "PNG", 0, 0, 210, pdfHeight);
      }

      pdf.save(
        "MyRen Accident Report(" +
          reportData.accidentData.date.split("T")[0] +
          ").pdf"
      );
    }
  };
  const changeStringArrToNumberArr = function (getData: string[]) {
    const numberArray = getData.map((stringValue) => parseInt(stringValue, 10));
    return numberArray;
  };
  const setAverage = function (getData: string[]) {
    const numberArray = getData.map((stringValue) => parseInt(stringValue, 10));
    const sum =
      numberArray.reduce((total, currentValue) => total + currentValue, 0) /
      numberArray.length;
    return sum;
  };
  const setMax = function (getData: string[]) {
    const numberArray = getData.map((stringValue) => parseInt(stringValue, 10));
    return Math.max(...numberArray);
  };
  const beforeOneSec = function (getData: string[]) {
    const numberArray = getData.map((stringValue) => parseInt(stringValue, 10));
    //index = 7이 사고 발생 1초전 데이터
    if (numberArray[9] - numberArray[7] >= 0) {
      return numberArray[9] - numberArray[7];
    } else {
      return numberArray[7] - numberArray[9];
    }
  };
  const dateObject = new Date();
  const Year = dateObject.getFullYear().toString();
  const Month = (dateObject.getMonth() + 1).toString();
  const Day = dateObject.getDate().toString();
  useEffect(() => {
    const speedData = reportData.accidentData.speed
      .split(",")
      .map((stringValue) => parseInt(stringValue, 10));

    checkAccidentType(reportData.accidentData);
    setLatitude(Number(reportData.accidentData.location.split("/")[0]));
    setLongitude(Number(reportData.accidentData.location.split("/")[1]));
    handleConvertToWhat3words();
    setDataSpeedAverage(setAverage(reportData.accidentData.speed.split(",")));
    setDataSpeedMax(setMax(reportData.accidentData.speed.split(",")));
    setDataSpeedDeltaOneSec(
      beforeOneSec(reportData.accidentData.speed.split(","))
    );
  }, []);

  return (
    <>
      <div
        style={{
          width: "21cm",
          padding: "10px 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ ...TypographyStyle.heading.bold31 }}>Report Preview</div>
        <div>
          <button
            onClick={generatePDF}
            style={{ ...ButtonStyle.EnabledChecked }}
          >
            PDF EXPORT
          </button>
        </div>
      </div>
      <div ref={contentRef} style={{ width: "21cm", height: "29.7cm" }}>
        <div>
          {/* PDF로 변환하려는 내용을 이 div 안에 넣으세요 */}
          <div
            // ref={contentRef}
            style={{
              width: "21cm",
              height: "29.7cm",
              padding: "24px",
              borderWidth: "1px",
              borderColor: defaultColorTheme.Black,
            }}
          >
            {/* WATERMARK */}
            <div
              style={{
                position: "absolute",
                top: "13cm",
                left: "5cm",
                opacity: "10%",
              }}
            >
              <img src="./img/LogoCar.png" alt="" style={{ height: "50px" }} />
            </div>
            {/* HEADERS */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottomWidth: "1px",
                borderColor: grayScaleTheme.grayScaleColor300,
                paddingBottom: "8px",
                marginBottom: "16px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <img
                    src="/img/LogoCar.png"
                    alt="logo"
                    style={{ height: "15px", paddingRight: "16px" }}
                  />
                </div>
                <div
                  style={{
                    ...TypographyStyle.paragraphMedium.paragraphMedium25,
                  }}
                >
                  OBD Accident Report
                </div>
              </div>
              <div
                style={{
                  color: grayScaleTheme.grayScaleColor400,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {Year}.{Month}.{Day}
              </div>
            </div>
            {/* CONTENTS */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "90%",
              }}
            >
              <div
                style={{
                  backgroundColor: grayScaleTheme.grayScaleColor50,
                  padding: "16px",
                }}
              >
                <div
                  style={{
                    ...TypographyStyle.paragraphMedium.paragraphMedium20,
                    paddingBottom: "10px",
                  }}
                >
                  Accident information
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                  }}
                >
                  <DataLabel
                    label={"User"}
                    data={reportData.accidentData.ownName}
                  />
                  <DataLabel
                    label={"Accident Type"}
                    data={reportData.accidentData.type}
                  />
                  <DataLabel
                    label={"Date"}
                    data={reportData.accidentData.date}
                  />

                  <DataLabel
                    label={"Insurance"}
                    data={reportData.accidentData.insurance}
                  />
                  <DataLabel
                    label={"Roll"}
                    data={
                      reportData.accidentData.roll === "-"
                        ? "-"
                        : reportData.accidentData.roll.split(",")[29]
                    }
                  />
                  <DataLabel
                    label={"Yaw"}
                    data={
                      reportData.accidentData.yaw === "-"
                        ? "-"
                        : reportData.accidentData.yaw.split(",")[29]
                    }
                  />
                  <div
                    style={{
                      border: "1px solid",
                      borderRadius: "6px",
                      borderColor: grayScaleTheme.grayScaleColor200,
                      backgroundColor: defaultColorTheme.White,
                      padding: "4px 6px",
                      width: "100%",
                      height: "fit-content",
                      // display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      ...TypographyStyle.paragraphMedium.paragraphMedium16,
                      color: grayScaleTheme.grayScaleColor700,
                      marginBottom: "10px",
                      gridColumnEnd: "span 3",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          height: "fit-content",
                          padding: "2.5px 12px",
                          margin: "4px auto",
                          borderRadius: "6px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          marginRight: "2px",
                        }}
                      >
                        Average Speed Rate(km/h)
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          height: "fit-content",
                          padding: "2.5px 12px",
                          margin: "4px auto",
                          borderRadius: "6px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          marginRight: "2px",
                        }}
                      >
                        Max Speed Rate(km/h)
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          height: "fit-content",
                          padding: "2.5px 12px",
                          margin: "4px auto",
                          borderRadius: "6px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        Min Speed Rate(km/h)
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          textAlign: "center",
                          color: grayScaleTheme.grayScaleColor800,
                          width: "100%",
                        }}
                      >
                        {averageSpeed}km/h
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          color: grayScaleTheme.grayScaleColor800,
                          width: "100%",
                        }}
                      >
                        {maxSpeed}km/h
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          color: grayScaleTheme.grayScaleColor800,
                          width: "100%",
                        }}
                      >
                        {minSpeed}km/h
                      </div>
                    </div>
                  </div>
                  {/* 사고위치 */}
                  <div
                    style={{
                      border: "1px solid",
                      borderRadius: "6px",
                      borderColor: grayScaleTheme.grayScaleColor200,
                      backgroundColor: defaultColorTheme.White,
                      padding: "4px 6px",
                      width: "100%",
                      height: "fit-content",
                      // display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      ...TypographyStyle.paragraphMedium.paragraphMedium16,
                      color: grayScaleTheme.grayScaleColor700,
                      marginBottom: "10px",
                      gridColumnEnd: "span 3",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: "fit-content",
                        padding: "2.5px 12px",
                        margin: "4px auto",
                        borderRadius: "6px",
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        marginRight: "2px",
                      }}
                    >
                      Accident Address
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        color: grayScaleTheme.grayScaleColor800,
                        width: "100%",
                      }}
                    >
                      {reportData.accidentData.address}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    ...TypographyStyle.paragraphRegular.paragraphRegular12,
                    color: grayScaleTheme.grayScaleColor500,
                  }}
                >
                  * The km/h indicated in this report is the speed corresponding
                  to the time of the collision. Speed on page 3 to view records
                  in the 15 seconds prior to collision Check the graph.
                </div>
              </div>
              {/* 세로구분선  */}
              <div
                style={{
                  backgroundColor: grayScaleTheme.grayScaleColor300,
                  width: "100%",
                  height: "1px",
                }}
              />
              {/* 사고 정보 */}
              <div
                style={{
                  width: "100%",
                  backgroundColor: grayScaleTheme.grayScaleColor50,
                  padding: "16px",
                  height: "100%",
                }}
              >
                <SensorLabel
                  accel={Number(
                    reportData.accidentData.accel
                      .split(",")
                      [
                        reportData.accidentData.accel.split(",").length - 1
                      ].split(".")[0]
                  )}
                  lang={true}
                />
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor300,
                    width: "100%",
                    height: "1px",
                    marginBottom: "10px",
                  }}
                />
                {/* 사고 분석 */}
                <div
                  style={{
                    ...TypographyStyle.paragraphMedium.paragraphMedium20,
                    paddingBottom: "10px",
                  }}
                >
                  Accident Location (Map)
                </div>
                <div
                  style={{
                    ...TypographyStyle.paragraphMedium.paragraphMedium16,
                    color: grayScaleTheme.grayScaleColor400,
                    height: "60%",
                    padding: "16px",
                    backgroundColor: defaultColorTheme.White,
                    borderRadius: "8px",
                    boxShadow: ShadowTheme._16,
                  }}
                >
                  <div style={{ position: "absolute", width: "660px" }}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "54px",
                        marginTop: "16px",
                        zIndex: 9999,
                        borderRadius: "8px",
                        boxShadow: "0 2px 16px 0 gray",
                        backgroundColor: defaultColorTheme.White,
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        left: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          // padding: "16px",
                          margin: "8px",
                          alignItems: "center",
                          color: defaultColorTheme.Black,
                        }}
                      >
                        <img
                          src="/img/w3w_Symbol_RGB_Red.png"
                          alt=""
                          style={{ width: "50px" }}
                        />
                        {/* <Spacer space={"26px"} /> */}
                        <TypographyComponent.label.semiBold16>
                          {what3wordsAddress}
                        </TypographyComponent.label.semiBold16>
                      </div>
                    </div>
                  </div>
                  <GoogleMap
                    zoom={16}
                    mapContainerStyle={containerStyle}
                    center={{
                      lat: Number(
                        reportData.accidentData.location.split("/")[0]
                      ),
                      lng: Number(
                        reportData.accidentData.location.split("/")[1]
                      ),
                    }}
                    mapContainerClassName="map-container"
                    options={{ disableDefaultUI: true }}
                  >
                    <MarkerF
                      position={{
                        lat: Number(
                          reportData.accidentData.location.split("/")[0]
                        ),
                        lng: Number(
                          reportData.accidentData.location.split("/")[1]
                        ),
                      }}
                      icon={{ url: "/img/MapMarker.png", scale: 5 }}
                    />
                    <OverlayView
                      position={{
                        lat: Number(
                          reportData.accidentData.location.split("/")[0]
                        ),
                        lng: Number(
                          reportData.accidentData.location.split("/")[1]
                        ),
                      }}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          background: "rgba(255,255,255,0.7)",
                          border: "1px solid #ccc",
                        }}
                      >
                        {/* 여기에 what3words grid의 내용을 렌더링하거나 스타일을 추가할 수 있습니다. */}
                      </div>
                    </OverlayView>
                  </GoogleMap>
                  {/* <MapContainer
                    location={reportData.accidentData.location}
                    address={""}
                  /> */}
                  {/* <MapW3W
                    location={reportData.accidentData.location}
                    address={""}
                  /> */}
                </div>
              </div>
            </div>
            {/* BOTTOM */}
            <div
              style={{
                marginTop: "16px",
                borderTopWidth: "1px",
                borderTopColor: grayScaleTheme.grayScaleColor300,
                paddingTop: "8px",
                ...TypographyStyle.paragraphMedium.paragraphMedium12,
                color: grayScaleTheme.grayScaleColor400,
              }}
            >
              *This accident report has no legal effect and is for reference
              only.
            </div>
          </div>
        </div>
        {reportData.accidentData.accel === "-" ? null : (
          <>
            <GSAReport dataObjectType={reportData.accidentData} />
            <GSAReport2 />
            <GSAReport3 />
            <GSAReport4 />
            <GSAReport5 />
          </>
        )}
      </div>
    </>
  );
};

export default CarAccidentItemReport;
