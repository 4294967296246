import React, { FunctionComponent } from "react";
import { styled } from "styled-components";
import { Spacer } from "../StyleComponent/Spacer";
import { TypographyStyle } from "../StyleComponent/FontStyle";
import { grayScaleTheme } from "../Layout/RootColorStyle";
const TextDataKr = {
  one: "간편하고 빠른 사고 신고",
  two: "마이렌",
  introText1: `사고났슈는 손쉽고 빠른 사고 신고 서비스를 제공합니다.`,
  introText2: `운전 중 교통사고로 보행자 낙상, 자전거와 휠체어 탑승 중 등`,
  introText3: `7가지 상황의 안전 사고에 대한 대처를 도와드립니다.`,
};
const TextDataEn = {
  one: "My Own Siren",
  two: "MYREN",
  introText1: `MYREN is a combination of "My" and "Siren."`,
  introText2: `We help you cope with various unexpected situations and traffic accidents while driving.`,
  introText3: `7가지 상황의 안전 사고에 대한 대처를 도와드립니다.`,
};

const FixCopyContain: FunctionComponent = function () {
  const lang = window.localStorage.getItem("language");
  let TextData = TextDataKr;
  lang === "false" ? (TextData = TextDataKr) : (TextData = TextDataEn);
  return (
    <FixCopyContainWrapper>
      <TitleTxt>
        {TextData.one}
        <Spacer space={"16px"} horizontal={true} />
        <LogoImg src="./img/Logo.png" alt="" />
      </TitleTxt>
      <Spacer space={"24px"} horizontal={true} />
      <ExplainTxt>
        {TextData.introText1}
        <br />
        {TextData.introText2}
        <br />
        {TextData.introText3}
      </ExplainTxt>
      <DownloadContainer>
        <a href="https://play.google.com/store/apps/details?id=com.myren.safeyou">
          <img src="img/googlePlayStore.png" alt="" />
        </a>
        <Spacer space={"20px"} />
        <a href="https://apps.apple.com/kr/app/%EC%82%AC%EA%B3%A0%EB%82%AC%EC%8A%88/id6470176363?l=en-GB">
          <img src="img/appleAppStore.png" alt="" />
        </a>
      </DownloadContainer>
      <Spacer space={"56px"} horizontal={true} />
    </FixCopyContainWrapper>
  );
};
const FixCopyContainWrapper = styled.div`
  width: 30%;
  @media screen and (max-width: 1024px) and (min-width: 320px) {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  width: 80%;
  max-width: 500px;
  padding-top: 24px;
  @media screen and (max-width: 1024px) and (min-width: 720px) {
    width: 50%;
  }
  @media screen and (max-width: 720px) and (min-width: 320px) {
    width: 70%;
  }
`;

const TitleTxt = styled.p`
  font-weight: 700;
  font-size: 31px;
  color: ${grayScaleTheme.grayScaleColor800};
  @media screen and (max-width: 1024px) and (min-width: 320px) {
    align-items: center;
    display: flex;
    font-size: 25px;
    flex-direction: column;
  }
`;

const ExplainTxt = styled.p`
  color: ${grayScaleTheme.grayScaleColor500};
  wordbreak: "keep-all";
  font-weight: 500;
  font-size: 20px;
  @media screen and (max-width: 1024px) and (min-width: 320px) {
    font-size: 12px;
  }
`;

const LogoImg = styled.img`
  width: 257.1px;
  height: 64px;
  @media screen and (max-width: 1024px) and (min-width: 320px) {
    width: 208.89px;
    height: 52px;
  }
`;
export default FixCopyContain;
