import { Icon } from "@iconify/react";
import React, { FunctionComponent, useContext } from "react";
import { signOut } from "@firebase/auth";
import { useNavigate } from "react-router-dom";
import { grayScaleTheme } from "./RootColorStyle";
import { AuthContext } from "../../context/authContext";
import { defaultColorTheme } from "./Theme";
import { TypographyStyle } from "../StyleComponent/FontStyle";
import { DividerLine } from "../StyleComponent/DividerLine";
import { auth } from "../../FirebaseSetting";
import { BorderButton } from "../StyleComponent/BorderButton";

const Profile: FunctionComponent = function () {
  const userInfo = useContext(AuthContext);
  const navigation = useNavigate();
  return (
    <>
      <div
        style={{
          backgroundColor: grayScaleTheme.grayScaleColor100,
          padding: "11px 12px",
          borderRadius: "12px",
        }}
      >
        <div style={{ display: "flex " }}>
          <div
            style={{
              padding: "5px",
              backgroundColor: grayScaleTheme.grayScaleColor500,
              display: "flex",
              alignItems: "center",
              borderRadius: "12px",
            }}
          >
            <Icon
              icon={"ion:person-circle"}
              fontSize={33}
              color={defaultColorTheme.White}
            />
          </div>
          <div style={{ paddingLeft: "6px" }}>
            <div
              style={{
                color: grayScaleTheme.grayScaleColor600,
                ...TypographyStyle.label.semiBold16,
              }}
            >
              {userInfo?.email?.split("@")[0]}
            </div>
            <div
              style={{
                color: grayScaleTheme.grayScaleColor400,
                ...TypographyStyle.paragraphRegular.paragraphRegular16,
              }}
            >
              MYREN Data Server Admin
            </div>
          </div>
        </div>
        <DividerLine />
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <BorderButton
            onClick={() => {
              signOut(auth);
              navigation("/serverlogin");
            }}
            children={"Logout"}
            style={{
              backgroundColor: grayScaleTheme.grayScaleColor300,
              color: grayScaleTheme.grayScaleColor500,
              borderRadius: "12px",
              padding: "6px 12px",
              ...TypographyStyle.paragraphMedium.paragraphMedium16,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default Profile;
