import React from "react";

type cctvIndexContextType = {
  cctvIndex: number;
  updateCctvIndexData: (newData: number) => void;
};

export const CctvIndexContext = React.createContext<cctvIndexContextType>({
  cctvIndex: -1,
  updateCctvIndexData: function (newData: number): void {},
});
