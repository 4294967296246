import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FunctionComponent, useContext, useRef } from "react";
import styled from "styled-components";
import { grayScaleTheme } from "../Layout/RootColorStyle";
import { ReportItemContext } from "../../context/reportItemContext";
import { defaultColorTheme } from "../Layout/Theme";

const GSAReport: FunctionComponent<{ dataObjectType: any }> = function (props) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const reportData = useContext(ReportItemContext);
  console.log(props);
  const generatePDF = async () => {
    if (contentRef.current) {
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const pages = contentRef.current.children as HTMLCollection;

      for (let i = 0; i < pages.length; i++) {
        const page = pages[i] as HTMLElement;
        // const canvas = await html2canvas(page);
        const canvas = await html2canvas(page, {
          allowTaint: true,
          useCORS: true,
        }); // HTML을 캔버스로 렌더링
        const imgData = canvas.toDataURL("image/png");

        if (i > 0) {
          pdf.addPage();
        }

        pdf.addImage(imgData, "PNG", 0, 0, 210, pdfHeight);
      }

      pdf.save(
        "MyRen Accident Report(" +
          reportData.accidentData.createdAt.split("T")[0] +
          ").pdf"
      );
    }
  };
  return (
    <>
      <div ref={contentRef} style={{ width: "21cm", height: "29.7cm" }}>
        <div>
          <div
            style={{
              width: "21cm",
              height: "29.7cm",
              padding: "8px 36px",
              borderWidth: "1px",
              borderColor: defaultColorTheme.Black,
            }}
          >
            {/* header */}
            <div
              style={{
                display: "flex",
                width: "100%",
                fontSize: "14px",
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
              }}
            >
              <strong
                style={{
                  width: "20%",
                  textAlign: "center",
                  borderRight: "2px solid black",
                }}
              >
                <p>MOTOR VEHICLE</p>
                <p>ACCIDENT (CRASH)</p>
                <p>REPORT</p>
              </strong>
              <div
                style={{
                  width: "15%",
                  textAlign: "center",
                  borderRight: "2px solid black",
                  fontStyle: "oblique",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  lineHeight: 1.1,
                }}
              >
                Please read the
                <br />
                Privacy Act
                <br />
                Statement on Page 4
              </div>
              <div
                style={{
                  width: "60%",
                  fontSize: "11px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 600,
                  lineHeight: 1.1,
                }}
              >
                INSTRUCTIONS: Sections I through IX are filled out by the
                vehicle operator. Section X, items73 thru 83c are filled out by
                the operator's supervisor. Section XI thru XII are filled out by
                a crash investigator for bodily injury, fatality, and/or damage
                exceeding $500.
              </div>
            </div>
            {/* section 1  */}
            <div>
              <div
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                  borderBottom: "2px solid black",
                }}
              >
                SECTION I - FEDERAL VEHICLE DATA
              </div>
              {/* driver's name / driver's license number/state/limitaions / date of crash */}
              <div
                style={{ display: "flex", fontSize: "10px", height: "40px" }}
              >
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    1. DRIVER'S NAME
                    <span style={{ fontStyle: "oblique" }}>
                      (Last, First, Middle)
                    </span>
                  </div>
                  {/* <br /> */}
                  {reportData.accidentData.ownName}
                </div>
                <div
                  style={{
                    width: "30%",
                    fontSize: "12px",
                    height: "100%",
                    borderBottom: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    2. DRIVER'S LICENSE NUMBER/STATE/LIMITATIONS
                  </div>
                  AB123456/NY/10312029
                </div>
                <div
                  style={{
                    width: "20%",
                    borderBottom: "2px solid black",
                    borderLeft: "2px solid black",
                    fontSize: "12px",
                    borderRight: "2px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    3. DATE OF CRASH
                  </div>
                  {
                    reportData.accidentData.createdAt
                      .split("-")[2]
                      .split("T")[0]
                  }
                  .{reportData.accidentData.createdAt.split("-")[1]}.
                  {reportData.accidentData.createdAt.split("-")[0]}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  height: "38px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "55%",
                    height: "100%",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    4a. DEPARTMENT/FEDERAL AGENCY PERMANENT OFFICE ADDRESS
                  </div>
                </div>
                <div
                  style={{
                    width: "20%",
                    height: "100%",
                    fontSize: "12px",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    4b. TELEPHONE NUMBER
                  </div>
                  (000)000-1234
                </div>
                <div
                  style={{
                    width: "25%",
                    height: "100%",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      fontSize: "10px",
                    }}
                  >
                    4c. E-MAIL ADDRESS
                  </div>
                  test@test.com
                </div>
              </div>
              <div
                style={{ display: "flex", fontSize: "10px", height: "40px" }}
              >
                <div
                  style={{
                    width: "27%",
                    height: "100%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      fontSize: "10px",
                    }}
                  >
                    5. TAG OR IDENTIFICATION NUMBER
                  </div>
                  ABC123
                </div>
                <div
                  style={{
                    width: "23%",
                    height: "100%",
                    fontSize: "12px",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    6. ESTIMATED REPAIR COST
                  </div>
                  $500
                </div>
                <div
                  style={{
                    width: "15%",
                    height: "100%",
                    fontSize: "12px",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    7. YEAR OF VEHICLE
                  </div>
                  2023
                </div>
                <div
                  style={{
                    width: "10%",
                    height: "100%",
                    fontSize: "12px",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    8. MAKE
                  </div>
                  HYUNDAI
                </div>
                <div
                  style={{
                    width: "10%",
                    height: "100%",
                    fontSize: "12px",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    9. MODEL
                  </div>
                  EV6
                </div>
                <div
                  style={{
                    width: "15%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    10. SEAT BELTS USED?
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: " 0 4px",
                      }}
                    >
                      <CheckBoxDiv>X</CheckBoxDiv>
                      YES
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckBoxDiv />
                      NO
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontSize: "12px",
                  height: "40px",
                  borderTop: "1px solid black",
                  borderBottom: "2px solid black",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    height: "fit-content",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  11. DESCRIBE VEHICLE DAMAGE
                </div>
                Forward collision
              </div>
            </div>
            {/* seciont 2 */}
            <div>
              <div
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                  borderBottom: "1px solid black",
                }}
              >
                SECTION II - OTHER VEHICLE DATA
                <span style={{ fontStyle: "oblique" }}>
                  (Use Section VIII if additional space is needed)
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  height: "45px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "45%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      fontSize: "10px",
                    }}
                  >
                    12. DRIVER'S NAME
                    <span style={{ fontStyle: "oblique" }}>
                      (Last, First, Middle)
                    </span>
                  </div>
                  Jane Smith
                </div>
                <div
                  style={{
                    width: "20%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    13. SOCIAL SECURITY NUMBER/ TAX IDENTIFICATION NUMBER
                  </div>
                  123-45-6789
                </div>
                <div style={{ width: "35%", fontSize: "12px" }}>
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    14. DRIVER'S LICENSE NUMBER/STATE/LIMITATIONS
                  </div>
                  XYZ789/NY/09052029
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  height: "38px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "75%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    15a. DRIVER'S WORK ADDRESS
                  </div>
                  5422 Beach BI. Buena Park. CA
                </div>
                <div style={{ width: "25%", fontSize: "12px" }}>
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    15b. TELEPHONE NUMBER
                  </div>
                  (111)-222-3333
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  height: "38px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "75%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    16a. DRIVER'S HOME ADDRESS
                  </div>
                  5422 Beach BI. Buena Park. CA
                </div>
                <div style={{ width: "25%", fontSize: "12px" }}>
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    16b. HOME TELEPHONE NUMBER
                  </div>
                  (000)-111-2222
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  height: "40px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "75%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    17. DESCRIPTION OF VEHICLE DAMAGE
                  </div>
                  Headlight Breakage
                </div>
                <div style={{ width: "25%", fontSize: "12px" }}>
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      fontSize: "10px",
                    }}
                  >
                    18. ESTIMATED REPAIR COST
                  </div>
                  $500
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  height: "37px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "15%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    19. YEAR OF VEHICLE
                  </div>
                  2023
                </div>
                <div
                  style={{
                    width: "40%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    20. MAKE OF VEHICLE
                  </div>
                  2023
                </div>
                <div
                  style={{
                    width: "20%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      fontSize: "10px",
                    }}
                  >
                    21. MODEL OF VEHICLE
                  </div>
                  EV6
                </div>
                <div style={{ width: "25%", fontSize: "12px" }}>
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    22. TAG NUMBER AND STATEM
                  </div>
                  XYZ123
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  // height: "45px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "75%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      fontSize: "10px",
                    }}
                  >
                    23a. DRIVER'S INSURANCE COMPANY NAME AND ADDRESS
                  </div>
                  GEICO / GEICO PLZ, WASHINGTON, DC 20076-0005, USA
                </div>
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      height: "50%",
                      borderBottom: "1px solid black",
                      fontSize: "12px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        fontSize: "10px",
                      }}
                    >
                      23b. POLICY NUMBER
                    </div>
                    123-45-6789
                  </div>
                  <div style={{ height: "50%", fontSize: "12px" }}>
                    <div
                      style={{
                        fontSize: "10px",
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                      }}
                    >
                      23c. TELEPHONE NUMBER
                    </div>
                    1-800-861-8380
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  height: "50px",
                  borderBottom: "1px solid black",
                }}
              >
                <div style={{ width: "30%", borderRight: "1px solid black" }}>
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    24. VEHICLE IS
                  </div>
                  <div style={{ marginLeft: "25px", display: "flex" }}>
                    <div style={{ marginRight: "4px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CheckBoxDiv />
                        CO-OWNED
                      </div>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CheckBoxDiv />
                        RENTAL
                      </div>
                    </div>
                    <div style={{}}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CheckBoxDiv />
                        LEASED
                      </div>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CheckBoxDiv>X</CheckBoxDiv>
                        PRIVATELY OWNED
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "45%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10spx",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    25a. OWNER'S NAME(S)
                    <span style={{ fontStyle: "oblique" }}>
                      (Last, First, Middle)
                    </span>
                  </div>
                  Jane Smith
                </div>
                <div style={{ width: "25%", fontSize: "12px" }}>
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      fontSize: "10px",
                    }}
                  >
                    25b. TELEPHONE NUMBER
                  </div>
                  (111)-222-3333
                </div>
              </div>
              <div
                style={{
                  fontSize: "12px",
                  height: "37px",
                  borderBottom: "2px solid black",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    height: "fit-content",
                    width: "100%",
                    fontSize: "10px",
                  }}
                >
                  26. OWNER'S ADDRESS(ES)
                </div>
                5422 Beach BI. Buena Park. CA
              </div>
            </div>
            {/* section 3 */}
            <div>
              <div
                style={{
                  fontWeight: 600,
                  textAlign: "center",
                  borderBottom: "1px solid black",
                }}
              >
                SECTION III - FATALITY OR INJURED
                <span style={{ fontStyle: "oblique" }}>
                  (Use Section VIII if additional space is needed)
                </span>
              </div>
              {/* A */}
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "3%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                  }}
                >
                  A
                </div>
                <div style={{ width: "97%" }}>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "10px",
                      height: "40px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "75%",
                        borderRight: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          fontSize: "10px",
                        }}
                      >
                        27. NAME
                        <span style={{ fontStyle: "oblique" }}>
                          (Last, First, Middle)
                        </span>
                      </div>
                      Emily Johnson
                    </div>
                    <div
                      style={{
                        width: "8%",
                        borderRight: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          fontSize: "10px",
                        }}
                      >
                        28. SEX
                      </div>
                      Female
                    </div>
                    <div style={{ width: "17%", fontSize: "12px" }}>
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          fontSize: "10px",
                        }}
                      >
                        29. DATE OF BIRTH
                      </div>
                      23.04.1995
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      height: "40px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        height: "fit-content",
                        width: "100%",
                        fontSize: "10px",
                      }}
                    >
                      30. ADDRESS
                    </div>
                    789 Pine Street, Anytown, USA
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: "50px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        borderRight: "1px solid black",
                        height: "50px",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          fontSize: "10px",
                        }}
                      >
                        31. MARK "X" IN TWO APPROPRIATE BOXES
                      </div>
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "4px",
                          fontSize: "10px",
                        }}
                      >
                        <div
                          style={{
                            borderRight: "1px solid black",
                            width: "30%",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckBoxDiv />
                            FATALITY
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckBoxDiv>X</CheckBoxDiv>
                            INJURED
                          </div>
                        </div>
                        <div
                          style={{
                            paddingLeft: "4px",
                            display: "flex",
                            height: "35px",
                          }}
                        >
                          <div style={{ paddingRight: "4px" }}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CheckBoxDiv />
                              DRIVER
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CheckBoxDiv />
                              HELPER
                            </div>
                          </div>
                          <div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CheckBoxDiv />
                              PASSENGER
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CheckBoxDiv>X</CheckBoxDiv>
                              PEDESTRIAN
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "15%",
                        borderRight: "1px solid black",
                        height: "50px",
                        fontSize: "10px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          fontSize: "10px",
                        }}
                      >
                        32. IN WHICH VEHICLE
                      </div>
                      <div
                        style={{
                          paddingLeft: "4px",
                          display: "flex",
                        }}
                      >
                        <div style={{ paddingRight: "4px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <CheckBoxDiv />
                            FED
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckBoxDiv>X</CheckBoxDiv>
                            OTHER (2)
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "20%",
                        borderRight: "1px solid black",
                        height: "50px",
                        fontSize: "10px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        33. LOCATION IN VEHICLE
                      </div>
                    </div>
                    <div
                      style={{
                        height: "50px",
                        width: "35%",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          fontSize: "10px",
                        }}
                      >
                        34. FIRST AID GIVEN BY
                      </div>
                      injury cure
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "10px",
                      height: "40px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "25%",
                        borderRight: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "10px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        35. TRANSPORTED BY
                      </div>
                      None
                    </div>
                    <div style={{ width: "75%", fontSize: "12px" }}>
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          width: "100%",
                          fontSize: "10px",
                          height: "fit-content",
                        }}
                      >
                        36. TRANSPORTED TO
                      </div>
                      Go home
                    </div>
                  </div>
                </div>
              </div>
              {/* B */}
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "3%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid black",
                    borderRight: "1px solid black",
                  }}
                >
                  B
                </div>
                <div style={{ width: "97%" }}>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "10px",
                      height: "37px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "75%",
                        borderRight: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "10px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        37. NAME
                        <span style={{ fontStyle: "oblique" }}>
                          (Last, First, Middle)
                        </span>
                      </div>
                      None
                    </div>
                    <div
                      style={{
                        width: "8%",
                        borderRight: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "10px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        38. SEX
                      </div>
                      None
                    </div>
                    <div style={{ width: "17%", fontSize: "12px" }}>
                      <div
                        style={{
                          fontSize: "10px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        39. DATE OF BIRTH
                      </div>
                      None
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      height: "37px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        height: "fit-content",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      40. ADDRESS
                    </div>
                    None
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "10px",
                      height: "50px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        borderRight: "1px solid black",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        41. MARK "X" IN TWO APPROPRIATE BOXES
                      </div>
                      <div style={{ display: "flex", paddingLeft: "4px" }}>
                        <div
                          style={{
                            borderRight: "1px solid black",
                            width: "30%",
                            height: "35px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckBoxDiv />
                            FATALITY
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckBoxDiv />
                            INJURED
                          </div>
                        </div>
                        <div style={{ paddingLeft: "4px", display: "flex" }}>
                          <div style={{ paddingRight: "4px" }}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CheckBoxDiv />
                              DRIVER
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CheckBoxDiv />
                              HELPER
                            </div>
                          </div>
                          <div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CheckBoxDiv />
                              PASSENGER
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CheckBoxDiv />
                              PEDESTRIAN
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ width: "15%", borderRight: "1px solid black" }}
                    >
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        42. IN WHICH VEHICLE
                      </div>
                      <div style={{ paddingLeft: "4px", display: "flex" }}>
                        <div style={{ paddingRight: "4px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckBoxDiv />
                            FED
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CheckBoxDiv />
                            OTHER (2)
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "20%",
                        borderRight: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "10px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        43. LOCATION IN VEHICLE
                      </div>
                      None
                    </div>
                    <div style={{ width: "35%", fontSize: "12px" }}>
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          fontSize: "10px",
                          height: "fit-content",
                          width: "100%",
                        }}
                      >
                        44. FIRST AID GIVEN BY
                      </div>
                      None
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "10px",
                      height: "40px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        width: "25%",
                        borderRight: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "10px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        45. TRANSPORTED BY
                      </div>
                      None
                    </div>
                    <div style={{ width: "75%", fontSize: "12px" }}>
                      <div
                        style={{
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          height: "fit-content",
                          fontSize: "10px",
                          width: "100%",
                        }}
                      >
                        46. TRANSPORTED TO
                      </div>
                      None
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  borderBottom: "2px solid black",
                  fontSize: "10px",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    borderRight: "1px solid black",
                    fontSize: "12px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "11%",
                  }}
                >
                  47.
                  <br />
                  PEDESTRIAN
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "1px solid black",
                      height: "35px",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderRight: "1px solid black",
                        fontSize: "12px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "10px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                        }}
                      >
                        a. NAME OF STREET OR HIGHWAY
                      </div>
                      None
                    </div>
                    <div style={{ width: "50%" }}>
                      <div
                        style={{
                          fontSize: "9px",
                          borderBottom: "1px solid black",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: grayScaleTheme.grayScaleColor200,
                          }}
                        >
                          b. DIRECTION OF PEDESTRIAN
                          <span style={{ fontStyle: "oblique" }}>
                            (Southwest (SW) corner to Northwest (NW) corner,
                            etc.)
                          </span>
                        </div>
                      </div>
                      <div style={{ display: "flex", height: "65s%" }}>
                        <div
                          style={{
                            width: "50%",
                            borderRight: "1px solid black",
                            height: "100%",
                          }}
                        >
                          FROM
                        </div>
                        <div>TO</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: "50px", fontSize: "12px" }}>
                    <div
                      style={{
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        fontSize: "10px",
                      }}
                    >
                      c. DESCRIBE WHAT PEDESTRIAN WAS DOING AT TIME OF CRASH
                      <span style={{ fontStyle: "oblique" }}>
                        (crossing intersection with signal, against signal,
                        diagonally; in roadway playing, 3. DATE OF CRASH
                        walking, hitchhiking, etc.)
                      </span>
                    </div>
                    None
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px",
                  // position: "absolute",
                }}
              >
                <div style={{ fontStyle: "oblique" }}>
                  Previous editions are NOT usable
                </div>
                <div
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "5s%",
                    textAlign: "center",
                    lineHeight: 1,
                  }}
                >
                  National Stock Number
                  <br />
                  7540-00-634-4041
                </div>
                <div style={{ textAlign: "right" }}>
                  <strong style={{ fontSize: "15px" }}>
                    STANDARD FORM 91 (REV.9/2020)
                  </strong>
                  <br />
                  Prescribed by GSA - FMR (41 CFR) 102-34.290
                </div>
              </div>
            </div>
          </div>

          <GSAReport2 />
          <GSAReport3 />
          <GSAReport4 />
          <GSAReport5 />
        </div>
      </div>
    </>
  );
};
export const GSAReport2: FunctionComponent = function () {
  const reportData = useContext(ReportItemContext);
  return (
    <>
      <div
        style={{
          width: "21cm",
          height: "29.7cm",
          padding: "36px",
          borderWidth: "1px",
          borderColor: defaultColorTheme.Black,
        }}
      >
        <div
          style={{
            fontWeight: 600,
            textAlign: "center",
            borderTop: "2px solid black",
            height: "30px",
            borderBottom: "1px solid black",
          }}
        >
          SECTION IV - CRASH TIME AND LOCATION (Use Section VII if additional
          space is needed)
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "11px",
            borderBottom: "1px solid black",
          }}
        >
          <div style={{ width: "20%", borderRight: "1px solid black" }}>
            <div
              style={{
                height: "36px",
                borderBottom: "1px solid black",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                  fontSize: "10px",
                }}
              >
                48. DATE OF CRASH
              </div>
              {reportData.accidentData.createdAt.split("-")[2].split(" ")[0]}.
              {reportData.accidentData.createdAt.split("-")[1]}.
              {reportData.accidentData.createdAt.split("-")[0]}
            </div>
            <div>
              50. TIME OF CRASH
              <div style={{ marginLeft: "auto", marginRight: "4px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckBoxDiv>
                    {Number(
                      reportData.accidentData.createdAt
                        .split("T")[1]
                        .split(":")[0]
                    ) < 12
                      ? "X"
                      : null}
                  </CheckBoxDiv>
                  AM
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckBoxDiv>
                    {Number(
                      reportData.accidentData.createdAt
                        .split("T")[1]
                        .split(":")[0]
                    ) >= 12
                      ? "X"
                      : null}
                  </CheckBoxDiv>
                  PM
                </div>
              </div>
            </div>
          </div>
          <div style={{ fontSize: "12px" }}>
            <div
              style={{
                fontSize: "10px",
                backgroundColor: grayScaleTheme.grayScaleColor200,
              }}
            >
              49. PLACE OF CRASH (Street address, city, state, ZIP Code; Nearest
              landmark; Distance nearest intersection; Kind of locality
              (industrial, business, residential, open country, etc.); Road
              description).
            </div>
            123 Main Street, Anytown, USA
          </div>
        </div>
        <div style={{ fontSize: "13px", borderBottom: "1px solid black" }}>
          51. INDICATE ON THE DIAGRAMS BELOW WHAT HAPPENED
        </div>
        <div>
          <img src="./img/GSA_51.png" alt="" />
        </div>
        <div style={{ fontWeight: 500 }}>
          <div>1. Number the vehicles involved as follows:</div>
          <div style={{ marginTop: "10px" }}>
            Government Vehicle (GOV) #1 - Private Vehicle (POV) #2 - Additional
            Vehicles GOV or POV as #3, etc. and show direction of travel by
            arrow. <br />
            (Example: ----&gt; <strong>1 2</strong> &lt;----)
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              2. Use solid line to show path before crash
              <div
                style={{
                  margin: "0 10px",
                  width: "150px",
                  height: "3px",
                  borderTop: "3px solid black",
                }}
              />
              2
            </div>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Broken line after crash - - - - - -
              - - - - - - 2
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
          >
            3. Show pedestrian by ------------------------&gt;
            <img src="./img/GSA_star.png" alt="" style={{ height: "25px" }} />
          </div>
          <div style={{ marginTop: "15px" }}>
            4. Show railroad by -|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|-|
          </div>
          <div style={{ marginTop: "15px" }}>
            5. Give names or numbers of streets or highways
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "4px" }}
          >
            6. Indicate north by arrow in this compass
            <img
              src="./img/GSA_compass.png"
              alt=""
              style={{ height: "50px", marginLeft: "20px" }}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "4px",
            borderTop: "3px solid black",
            textAlign: "right",
            fontSize: "15px",
          }}
        >
          <strong> STANDARD FORM 91 </strong>(REV. 9/2020){" "}
          <strong>PAGE 2</strong>
        </div>
      </div>
    </>
  );
};
export const GSAReport3: FunctionComponent = function () {
  const reportData = useContext(ReportItemContext);
  return (
    <>
      <div
        style={{
          width: "21cm",
          height: "29.7cm",
          padding: "36px",
          borderWidth: "1px",
          borderColor: defaultColorTheme.Black,
        }}
      >
        <p style={{ fontWeight: 500, fontSize: "14px" }}>
          52. POINT OF IMPACT (Check one for each vehicle)
        </p>
        {/* row1 */}
        <div
          style={{
            display: "flex",
            width: "100%",
            fontSize: "13px",
            fontWeight: 500,
          }}
        >
          <div style={{ display: "flex", width: "16.67%", height: "30px" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            >
              FED
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            >
              2
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
              }}
            >
              AREA
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            >
              FED
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            >
              2
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
              }}
            >
              AREA
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            >
              FED
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            >
              2
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
              }}
            >
              AREA
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            >
              FED
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            >
              2
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
              }}
            >
              AREA
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            >
              FED
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            >
              2
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
              }}
            >
              AREA
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            >
              FED
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            >
              2
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid whites",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
              }}
            >
              AREA
            </div>
          </div>
        </div>
        {/* row2 */}
        <div
          style={{
            display: "flex",
            width: "100%",
            fontSize: "11px",
            height: "30px",
          }}
        >
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
              }}
            >
              a. Front
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
                fontSize: "11px",
              }}
            >
              b. Right Front
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
                fontSize: "11px",
              }}
            >
              c. Left Front
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
              }}
            >
              d. Rear
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "11px",
                width: "60%",
              }}
            >
              e. Right Rear
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
                fontSize: "11px",
              }}
            >
              f. Left Rear
            </div>
          </div>
        </div>
        {/* row3 */}
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "30px",
            borderBottom: "1px solid black",
          }}
        >
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
                fontSize: "11px",
              }}
            >
              g. Right Side
            </div>
          </div>
          <div style={{ display: "flex", width: "16.67%" }}>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                width: "25%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            ></div>
            <div
              style={{
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "60%",
                fontSize: "11px",
              }}
            >
              h. Left Side
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "66.66%",
              borderTop: "1px solid black",
            }}
          ></div>
        </div>
        <div
          style={{ fontSize: "11px", fontWeight: 400, fontStyle: "oblique" }}
        >
          53. DESCRIBE WHAT HAPPENED
          <span style={{ fontStyle: "oblique" }}>
            (Refer to vehicles as "Fed", "2", "3", etc. Please include
            information on posted speed limit, approximate speed of vehicles,
            road conditions, weather conditions, driver visibility, condition of
            crash vehicles, traffic controls (warning light, stop signal, etc.),
            condition of light (daylight, dusk, night, dawn, artificial light,
            etc.), and driver actions (making a U-turn, passing, stopped in
            traffic, etc.).
          </span>
        </div>
        <div
          style={{
            borderTop: "2px solid black",
            marginTop: "350px",
          }}
        >
          <div
            style={{
              fontSize: "13px",
              fontWeight: 600,
              textAlign: "center",
              height: "25px",
            }}
          >
            SECTION V - WITNESS/PASSENGER (Witness must fill out Standard Form
            94 - Statement of Witness) (Continue in Section VIII.)
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "5%",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              A
            </div>
            <div style={{ width: "95%", borderTop: "1px solid black" }}>
              <div
                style={{
                  display: "flex",
                  fontSize: "11px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "45%",
                    height: "40px",
                    fontSize: "12px",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    54. DRIVER'S NAME
                    <span style={{ fontStyle: "oblique" }}>
                      (Last, First, Middle)
                    </span>
                  </div>
                  Doe, John M.
                </div>
                <div
                  style={{
                    width: "27.5%",
                    fontSize: "12px",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    55. TELEPHONE NUMBER
                  </div>
                  555-1234
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    width: "27.5%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    56. HOME TELEPHONE NUMBER
                  </div>
                  555-5678
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "11px",
                  width: "100%",
                  borderTop: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                    height: "40px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    57. WORK ADDRESS
                  </div>
                  789 Oak Street, Anytown, USA
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    58. HOME ADDRESS
                  </div>
                  123 Maple Avenue, Anytown, USA
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "5%",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              B
            </div>
            <div style={{ width: "95%", borderTop: "1px solid black" }}>
              <div
                style={{
                  display: "flex",
                  fontSize: "11px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "45%",
                    height: "40px",
                    fontSize: "12px",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    59. DRIVER'S NAME
                    <span style={{ fontStyle: "oblique" }}>
                      (Last, First, Middle)
                    </span>
                  </div>
                  Smith, Sarah A.
                </div>
                <div
                  style={{
                    width: "27.5%",
                    fontSize: "12px",
                    borderRight: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    60. TELEPHONE NUMBER
                  </div>
                  555-4321
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    width: "27.5%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    61. HOME TELEPHONE NUMBER
                  </div>
                  555-8765
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontSize: "11px",
                  width: "100%",
                  borderTop: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                    height: "40px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    62. WORK ADDRESS
                  </div>
                  456 Pine Street, Anytown, USA
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    63. HOME ADDRESS
                  </div>
                  789 Cedar Avenue, Anytown, USA
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ borderTop: "2px solid black" }}>
          <div
            style={{
              fontSize: "13px",
              fontWeight: 600,
              textAlign: "center",
              borderBottom: "1px solid black",
              height: "25px",
            }}
          >
            SECTION VI - PROPERTY DAMAGE (Use Section VIII if additional space
            is needed.)
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "11px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "45%",
                height: "40px",
                fontSize: "12px",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                }}
              >
                64a. NAME OF OWNER
                <span style={{ fontStyle: "oblique" }}>
                  (Last, First, Middle)
                </span>
              </div>

              {reportData.accidentData.ownName}
            </div>
            <div
              style={{
                width: "27.5%",
                fontSize: "12px",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                }}
              >
                64b. TELEPHONE NUMBER
              </div>
              555-1234
            </div>
            <div
              style={{
                fontSize: "12px",
                width: "27.5%",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                }}
              >
                64c. HOME TELEPHONE NUMBER
              </div>
              555-5678
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "11px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
                fontSize: "12px",
                height: "50px",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                }}
              >
                64d. WORK ADDRESS
              </div>
              789 Oak Street, Anytown, USA
            </div>
            <div
              style={{
                width: "50%",
                borderTop: "1px solid black",
                fontSize: "12px",
                height: "40px",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                }}
              >
                64e. HOME ADDRESS
              </div>
              123 Maple Avenue, Anytown, USA
            </div>
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "11px",
              width: "100%",
              borderTop: "1px solid black",
            }}
          >
            <div
              style={{
                width: "45%",
                height: "40px",
                fontSize: "12px",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                }}
              >
                65a. NAME OF INSURANCE COMPANY
              </div>
              ABC Insurance Company
            </div>
            <div
              style={{
                width: "27.5%",
                fontSize: "12px",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                }}
              >
                65b. TELEPHONE NUMBER
              </div>
              800-555-6789
            </div>
            <div
              style={{
                fontSize: "12px",
                width: "27.5%",
              }}
            >
              <div
                style={{
                  fontSize: "10px",
                  backgroundColor: grayScaleTheme.grayScaleColor200,
                }}
              >
                65c. POLICY NUMBER
              </div>
              XYZ123456
            </div>
          </div>
          <div>
            <div style={{ display: "flex", fontSize: "11px" }}>
              <div
                style={{
                  width: "25%",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  fontSize: "12px",
                  height: "40px",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  66. ITEM DAMAGED
                </div>
                Vehicle (Car)
              </div>
              <div
                style={{
                  width: "47.5%",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  fontSize: "12px",
                  height: "40px",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  67. LOCATION OF DAMAGED ITEM
                </div>
                Front bumper and left headlight
              </div>
              <div
                style={{
                  width: "27.5%",
                  borderTop: "1px solid black",
                  fontSize: "12px",
                  height: "40px",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  68. ESTIMATED COST
                </div>
                $2,000
              </div>
            </div>
          </div>
          <div style={{ borderTop: "2px solid black" }}>
            <div
              style={{
                fontSize: "13px",
                fontWeight: 600,
                textAlign: "center",
                borderBottom: "1px solid black",
                height: "25px",
              }}
            >
              SECTION VII - POLICE INFORMATION
            </div>
            <div style={{ display: "flex", fontSize: "11px" }}>
              <div
                style={{
                  width: "27.5%",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  fontSize: "12px",
                  height: "40px",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  69a. NAME OF POLICE OFFICER
                </div>
                Officer Smith
              </div>
              <div
                style={{
                  width: "45%",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  fontSize: "12px",
                  height: "40px",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  69b. BADGE NUMBER
                </div>
                12345
              </div>
              <div
                style={{
                  width: "27.5%",
                  borderTop: "1px solid black",
                  fontSize: "12px",
                  height: "40px",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  69c. TELEPHONE NUMBER
                </div>
                555-6789
              </div>
            </div>
            <div style={{ display: "flex", fontSize: "11px" }}>
              <div
                style={{
                  width: "27.5%",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  fontSize: "12px",
                  height: "50px",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  70. PRECINCT OR HEADQUARTERS
                </div>
                Anytown Police Department
              </div>
              <div
                style={{
                  width: "45%",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  height: "50px",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  71a. PERSON CHARGED WITH CRASH
                </div>
              </div>
              <div
                style={{
                  width: "27.5%",
                  borderTop: "1px solid black",
                  height: "40px",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  71b. VIOLATION(S)
                </div>
                - Jaywalking and Disregarding Traffic Signal
                <br />- Failure to Exercise Due Care
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <div
          style={{
            borderTop: "3px solid black",
            textAlign: "right",
            fontSize: "15px",
          }}
        >
          <strong> STANDARD FORM 91 </strong>(REV. 9/2020)
          <strong>PAGE 3</strong>
        </div>
      </div>
    </>
  );
};
export const GSAReport4: FunctionComponent = function () {
  return (
    <>
      <div
        style={{
          width: "21cm",
          height: "29.7cm",
          padding: "36px",
          borderWidth: "1px",
          borderColor: defaultColorTheme.Black,
        }}
      >
        <div style={{ borderTop: "2px solid black" }}>
          <div
            style={{
              fontSize: "13px",
              fontWeight: 600,
              textAlign: "center",
              height: "25px",
              borderBottom: "1px solid black",
            }}
          >
            SECTION VIII - EXTRA DETAILS
          </div>
          <div style={{ fontSize: "11px", paddingBottom: "230px" }}>
            SPACE FOR DETAILED ANSWERS. INDICATE SECTION AND ITEM NUMBER FOR
            EACH ANSWER. IF MORE SPACE IS NEEDED, CONTINUE ON ADDITIONAL SHEETS
            OF PAPER.
          </div>
          <div>
            <div
              style={{
                fontWeight: 600,
                fontSize: "13px",
                borderTop: "1px solid black",
                textAlign: "center",
              }}
            >
              PRIVACY ACT STATEMENT
            </div>
            <div
              style={{
                fontSize: "12px",
                borderBottom: "2px solid black",
                fontWeight: 500,

                height: "230px",
              }}
            >
              The information on this form is subject to the Privacy Act of 1974
              - United States Code set forth at 5 U.S.C. § 552a. Authority to
              collect the information is set forth at 40 U.S.C. § 491 and 31
              U.S.C. § 7701. The information is required by Federal Government
              agencies to administer motor vehicle programs, including
              maintaining records on crashes involving privately owned and
              Federal fleet vehicles, and collecting crash claims resulting from
              crashes. Federal employees, and employees under contract, will use
              the information only in the performance of their official duties.
              Routine uses of the collected information may include disclosures
              to: appropriate Federal, State, or local agencies or contractors
              when relevant to civil, criminal, or regulatory investigations or
              prosecutions; the Office of Personnel Management and the
              Government Accountability Office for program evaluation purposes;
              a Member of Congress or staff in response to a request for
              assistance by the individual of record; another Federal agency,
              including the Department of the Treasury and the Department of
              Justice, or a court under judicial proceedings; agency Inspectors
              General in conducting audits; private insurance and the collection
              agencies (including agencies under contract to Treasury to collect
              debt), and to other agency finance offices for Federal management
              and debt collection. Furnishing the requested information is
              mandatory, including the Social Security Number or Taxpayer's
              Identification Number (TIN) for use as a unique identifier to
              ensure accurate identification for individuals or firms in the
              system.
            </div>
          </div>
          <div>
            <div
              style={{
                fontSize: "13px",
                fontWeight: 600,
                textAlign: "center",
                height: "25px",
              }}
            >
              SECTION IX - FEDERAL DRIVER CERTIFICATION
            </div>
            <div
              style={{
                fontSize: "11px",
                borderTop: "1px solid black",
                height: "20px",
              }}
            >
              I certify that the information on this form (Sections I thru VII)
              is correct to the best of my knowledge and belief.
            </div>
            <div
              style={{
                display: "flex",
                fontSize: "11px",
                borderTop: "1px solid black",
                height: "50px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  borderRight: "1px solid black",
                  fontSize: "12px",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  72a. NAME AND TITLE OF DRIVER
                </div>
                John Doe, Driver
              </div>
              <div
                style={{
                  width: "35%",
                  borderRight: "1px solid black",
                  fontSize: "12px",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    fontSize: "10px",
                  }}
                >
                  72b. DRIVER'S SIGNATURE
                </div>
              </div>
              <div style={{ width: "15%", fontSize: "12px" }}>
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    fontSize: "10px",
                  }}
                >
                  72c. DATE
                </div>
              </div>
            </div>
          </div>
          <div style={{ borderTop: "2px solid black" }}>
            <div
              style={{
                fontSize: "13px",
                fontWeight: 600,
                textAlign: "center",
                borderBottom: "1px solid black",
                height: "25px",
              }}
            >
              SECTION X - DETAILS OF TRIP DURING WHICH CRASH OCCURRED
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  fontSize: "11px",
                  height: "50px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    fontSize: "12px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    73. ORIGIN
                  </div>
                  Anytown, USA
                </div>
                <div style={{ width: "50%", fontSize: "12px" }}>
                  <div
                    style={{
                      fontSize: "10px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                    }}
                  >
                    74. DESTINATION
                  </div>
                  Downtown City, USA
                </div>
              </div>
              <div
                style={{
                  fontSize: "12px",
                  height: "50px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  75. EXACT PURPOSE OF TRIP
                </div>
                Commuting to Work
              </div>
              <div
                style={{
                  display: "flex",
                  height: "50px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "30%",
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      borderRight: "1px solid black",
                    }}
                  >
                    76. TRIP BEGAN
                  </div>
                  <div
                    style={{
                      width: "35%",
                      fontSize: "12px",
                      borderRight: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "10px",
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                      }}
                    >
                      DATE
                    </div>
                    2023-04-15
                  </div>
                  <div
                    style={{
                      width: "35%",
                      fontSize: "12px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "10px",
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                      }}
                    >
                      TIME
                      <span
                        style={{
                          fontStyle: "oblique",
                        }}
                      >
                        (Include AM or PM)
                      </span>
                    </div>
                    8:00 AM
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "30%",
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      borderRight: "1px solid black",
                    }}
                  >
                    77. CRASH
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OCCURRED
                  </div>
                  <div
                    style={{
                      width: "35%",
                      fontSize: "12px",
                      borderRight: "1px solid black",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "10px",
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                      }}
                    >
                      DATE
                    </div>
                    2023-04-15
                  </div>
                  <div
                    style={{
                      width: "35%",
                      fontSize: "12px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "10px",
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                      }}
                    >
                      TIME
                      <span
                        style={{
                          fontStyle: "oblique",
                        }}
                      >
                        (Include AM or PM)
                      </span>
                    </div>
                    3:30 PM
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  height: "50px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    borderRight: "1px solid black",
                    width: "50%",
                    fontSize: "11px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      fontSize: "10px",
                      width: "100%",
                    }}
                  >
                    78. AUTHORITY FOR THE TRIP WAS GIVEN TO THE OPERATOR
                  </div>
                  <div style={{ display: "flex", marginLeft: "30px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: " 0 4px",
                      }}
                    >
                      <CheckBoxDiv>X</CheckBoxDiv>
                      ORALLY
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckBoxDiv />
                      IN WRITING &nbsp;
                      <span
                        style={{
                          fontStyle: "oblique",
                        }}
                      >
                        (Explain)
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    fontSize: "11px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      fontSize: "10px",
                      width: "100%",
                    }}
                  >
                    79. WAS THERE ANY DEVIATION FROM DIRECT ROUTE?
                  </div>
                  <div style={{ display: "flex", marginLeft: "40px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: " 0 4px",
                      }}
                    >
                      <CheckBoxDiv>X</CheckBoxDiv>
                      NO
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckBoxDiv />
                      YES &nbsp;
                      <span
                        style={{
                          fontStyle: "oblique",
                        }}
                      >
                        (Explain)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  height: "50px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    borderRight: "1px solid black",
                    width: "50%",
                    fontSize: "11px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      width: "100%",
                    }}
                  >
                    80. WAS THE TRIP MADE WITHIN ESTABLISHED WORKING HOURS?
                  </div>
                  <div style={{ display: "flex", marginLeft: "30px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: " 0 4px",
                      }}
                    >
                      <CheckBoxDiv>X</CheckBoxDiv>
                      YES
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckBoxDiv />
                      NOs &nbsp;
                      <span
                        style={{
                          fontStyle: "oblique",
                        }}
                      >
                        (Explain)
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                    fontSize: "11px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      width: "100%",
                    }}
                  >
                    79. WAS THERE ANY DEVIATION FROM DIRECT ROUTE?
                  </div>
                  <div style={{ display: "flex", marginLeft: "40px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: " 0 4px",
                      }}
                    >
                      <CheckBoxDiv>X</CheckBoxDiv>
                      NO
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CheckBoxDiv />
                      YES &nbsp;
                      <span
                        style={{
                          fontStyle: "oblique",
                        }}
                      >
                        (Explain)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid black",
              }}
            >
              <div
                style={{
                  width: "15%",
                  borderRight: "1px solid black",
                  fontSize: "13px",
                  lineHeight: 1.1,
                  fontWeight: 500,
                  paddingTop: "19px",
                }}
              >
                82. COMPLETED <br />
                BY DRIVER'S
                <br />
                SUPERVISOR
              </div>
              <div style={{ width: "85%" }}>
                <div
                  style={{
                    fontSize: "11.5px",
                    fontWeight: 500,
                    borderBottom: "1px solid black",
                    paddingLeft: "2px",
                    height: "20px",
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                  }}
                >
                  a. DID THIS CRASH OCCUR WITHIN THE EMPLOYEE'S SCOPE OF DUTY?
                </div>
                <div
                  style={{ display: "flex", fontSize: "11px", height: "50px" }}
                >
                  <div style={{ width: "10%", borderRight: "1px solid black" }}>
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "4px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CheckBoxDiv />
                          YES
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CheckBoxDiv />
                          NO
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "90%", fontSize: "12ㄴpx" }}>
                    <div
                      style={{
                        paddingLeft: "2px",
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      b. COMMENTS
                    </div>
                    The crash occurred while the employee was performing
                    work-related duties.
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", fontSize: "11px", height: "50px" }}>
              <div
                style={{
                  borderRight: "1px solid black",
                  width: "40%",
                  fontSize: "12px",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  83a. NAME AND TITLE OF SUPERVISOR
                </div>
                Jane Supervisor, Operations Manager
              </div>
              <div style={{ borderRight: "1px solid black", width: "30%" }}>
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  83b. SUPERVISOR'S SIGNATURE
                </div>
              </div>
              <div style={{ borderRight: "1px solid black", width: "10%" }}>
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  83c. DATE
                </div>
              </div>
              <div style={{ width: "20%", fontSize: "12px" }}>
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  83d. TELEPHONE NUMBER
                </div>
                555-9876
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <div
          style={{
            borderTop: "3px solid black",
            textAlign: "right",
            fontSize: "15px",
          }}
        >
          <strong> STANDARD FORM 91 </strong>(REV. 9/2020)
          <strong>PAGE 4</strong>
        </div>
      </div>
    </>
  );
};
export const GSAReport5: FunctionComponent = function () {
  return (
    <>
      <div
        style={{
          width: "21cm",
          height: "29.7cm",
          padding: "36px",
          borderWidth: "1px",
          borderColor: defaultColorTheme.Black,
        }}
      >
        <div style={{ borderTop: "2px solid black" }}>
          <div
            style={{
              fontSize: "13px",
              fontWeight: 600,
              textAlign: "center",
              height: "25px",
              borderBottom: "1px solid black",
            }}
          >
            SECTION XI - CRASH INVESTIGATION DATA
          </div>
          <div
            style={{ fontSize: "11px", display: "flex", marginBottom: "150px" }}
          >
            84. DID THE INVESTIGATION DISCLOSE CONFLICTING INFORMATION?
            <div style={{ display: "flex", marginLeft: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: " 0 4px",
                }}
              >
                <CheckBoxDiv>X</CheckBoxDiv>
                NO
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CheckBoxDiv />
                YES &nbsp;
                <span
                  style={{
                    fontStyle: "oblique",
                  }}
                >
                  (If checked, explain below.)
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              fontSize: "11px",
              borderBottom: "1px solid black",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                fontWeight: 500,
                borderBottom: "1px solid black",
                height: "25px",
              }}
            >
              85. PERSONS INTERVIEWED
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%", borderRight: "1px solid black" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    borderBottom: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      borderRight: "1px solid black",
                      fontSize: "11.5px",
                      textAlign: "center",
                      height: "25px",
                      lineHeight: 1.1,
                    }}
                  >
                    NAME
                  </div>
                  <div
                    style={{
                      width: "20%",
                      fontSize: "11.5px",
                      textAlign: "center",
                      lineHeight: 1.1,
                    }}
                  >
                    DATE
                  </div>
                </div>
                <div style={{ width: "80%" }}>
                  <div
                    style={{
                      height: "60px",
                      padding: "4px 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      borderRight: "1px solid black",
                    }}
                  >
                    <div>a.</div>
                    <div>b.</div>
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    borderBottom: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      borderRight: "1px solid black",
                      fontSize: "11.5px",
                      textAlign: "center",
                      height: "25px",
                      lineHeight: 1.1,
                    }}
                  >
                    NAME
                  </div>
                  <div
                    style={{
                      width: "20%",
                      fontSize: "11.5px",
                      textAlign: "center",
                      lineHeight: 1.1,
                    }}
                  >
                    DATE
                  </div>
                </div>
                <div style={{ width: "80%" }}>
                  <div
                    style={{
                      height: "60px",
                      padding: "4px 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "2px",
                      borderRight: "1px solid black",
                    }}
                  >
                    <div>c.</div>
                    <div>d.</div>
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ fontSize: "11px", paddingBottom: "150px" }}>
            86. ADDITIONAL COMMENTS (Indicate section and item number of each
            comment)
          </div>
        </div>
        <div style={{ borderTop: "2px solid black" }}>
          <div
            style={{
              fontSize: "13px",
              fontWeight: 600,
              textAlign: "center",
              borderBottom: "1px solid black",
              height: "25px",
            }}
          >
            SECTION XII - ATTACHMENTS
          </div>
          <div style={{ fontSize: "11px", paddingBottom: "100px" }}>
            87. LIST ALL ATTACHMENTS TO THIS REPORT
          </div>
        </div>
        <div style={{ borderTop: "2px solid black" }}>
          <div
            style={{
              fontSize: "13px",
              fontWeight: 600,
              textAlign: "center",
              borderBottom: "1px solid black",
              height: "25px",
            }}
          >
            SECTION XIII - COMMENTS/APPROVALS
          </div>
          <div
            style={{
              fontSize: "11px",
              paddingBottom: "100px",
              borderBottom: "1px solid black",
            }}
          >
            88. REVIEWING OFFICIAL'S COMMENTS
          </div>
          <div style={{ display: "flex", fontSize: "11px" }}>
            <div
              style={{
                width: "50%",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  height: "25px",
                }}
              >
                89. CRASH INVESTIGATOR
              </div>
              <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                <div
                  style={{
                    width: "75%",
                    borderRight: "1px solid black",
                    height: "45px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      width: "100%",
                    }}
                  >
                    a. SIGNATURE
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      paddingLeft: "2px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      width: "100%",
                    }}
                  >
                    b. DATE
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "36px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    width: "100%",
                  }}
                >
                  c. NAME (First, Middle, Last)
                </div>
                Investigator Smith Johnson
              </div>
              <div
                style={{
                  height: "36px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    width: "100%",
                  }}
                >
                  d. TITLE
                </div>
                Crash Investigator
              </div>
              <div
                style={{
                  height: "45px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    width: "100%",
                  }}
                >
                  e. OFFICE
                </div>
                Anytown Police Department
              </div>
              <div>
                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <div
                    style={{
                      width: "75%",
                      borderRight: "1px solid black",
                      height: "50px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        width: "100%",
                      }}
                    >
                      f. TELEPHONE NUMBER
                    </div>
                    555-5555ㄴ
                  </div>
                  <div style={{ width: "25%" }}>
                    <div
                      style={{
                        paddingLeft: "2px",
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        width: "100%",
                      }}
                    >
                      EXTENSION
                    </div>
                    123
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "36px",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    width: "100%",
                  }}
                >
                  g. E-MAIL ADDRESS
                </div>
                smith.johnson@example.com
              </div>
            </div>
            <div
              style={{
                width: "50%",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  height: "25px",
                }}
              >
                90. CRASH REVIEWING OFFICIAL
              </div>
              <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                <div
                  style={{
                    width: "75%",
                    borderRight: "1px solid black",
                    height: "45px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      width: "100%",
                    }}
                  >
                    a. SIGNATURE
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      paddingLeft: "2px",
                      backgroundColor: grayScaleTheme.grayScaleColor200,
                      width: "100%",
                    }}
                  >
                    b. DATE
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "36px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    width: "100%",
                  }}
                >
                  c. NAME (First, Middle, Last)
                </div>
                Review Official Mary Davis
              </div>
              <div
                style={{
                  height: "36px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    width: "100%",
                  }}
                >
                  d. TITLE
                </div>
                Reviewing Official
              </div>
              <div
                style={{
                  height: "45px",
                  borderBottom: "1px solid black",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    width: "100%",
                  }}
                >
                  e. OFFICE
                </div>
                Department of Transportation
              </div>
              <div>
                <div
                  style={{ display: "flex", borderBottom: "1px solid black" }}
                >
                  <div
                    style={{
                      width: "75%",
                      borderRight: "1px solid black",
                      height: "50px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        width: "100%",
                      }}
                    >
                      f. TELEPHONE NUMBER
                    </div>
                    555-6789
                  </div>
                  <div style={{ width: "25%" }}>
                    <div
                      style={{
                        paddingLeft: "2px",
                        backgroundColor: grayScaleTheme.grayScaleColor200,
                        width: "100%",
                      }}
                    >
                      EXTENSION
                    </div>
                    456
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "36px",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor200,
                    width: "100%",
                  }}
                >
                  g. E-MAIL ADDRESS
                </div>
                mary.davis@example.com
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div
          style={{
            borderTop: "3px solid black",
            textAlign: "right",
            fontSize: "15px",
          }}
        >
          <strong> STANDARD FORM 91 </strong>(REV. 9/2020)
          <strong>PAGE 5</strong>
        </div>
      </div>
    </>
  );
};
export default GSAReport;
const CheckBoxDiv = styled.div`
  width: 13px;
  height: 13px;
  font-size: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  margin-right: 4px;
`;
