import "./App.css";
import GlobalStyle from "./components/Layout/Global.style";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./components/page/MainPage";
import AppIntroducePage from "./components/page/AppIntroducePage";
import DashBoard from "./components/page/Dashboard";
import { Login } from "./components/page/Login";
import AccidentItemReport from "./components/page/AccidentItemReport";
import Policy from "./components/page/Policy";
import Terms from "./components/page/Terms";
import ControllTowerInfoPage from "./components/page/ControllTowerInfoPage";
import Cartalog from "./components/page/Cartalog";
import CarAccidentItemReport from "./components/page/CarAccidentItemReport";
import { CarLogin } from "./components/page/CarLogin";

function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/serverlogin" element={<Login />} />
          <Route path="/carserverlogin" element={<CarLogin />} />
          <Route path="/platform" element={<AppIntroducePage />} />
          <Route path="/accidentreport" element={<AccidentItemReport />} />
          <Route path="/controllserver" element={<DashBoard />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/test" element={<ControllTowerInfoPage />} />
          <Route path="/en" element={<Cartalog />} />
          <Route
            path="/caraccidentreport"
            element={<CarAccidentItemReport />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
