import { FunctionComponent, useContext, useEffect, useState } from "react";
import { ShadowTheme, TypographyStyle } from "../StyleComponent/FontStyle";
import { grayScaleTheme } from "../Layout/RootColorStyle";
import NaverMap from "../NaverMap";
import { defaultColorTheme } from "../Layout/Theme";
import CctvPage from "./CCTV";
import { GridColumn2 } from "../Layout/Layout.Style";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  getCCTVCoordinate,
  getCctvUrl,
  getDaeJeonInRangeCoordinate,
  getNearCctvDatas,
} from "../../util/cctv";
import axios from "axios";
import { cctvInfoByIdType } from "../../types/cctvTypes";
import { CctvIndexContext } from "../../context/clickCctvContext";
const CCTV_JSON_FILE = "/OpenDataCCTV.json";
const LATITUDE_INDEX = 0;
const LONGTITUDE_INDEX = 1;
const ControllTowerInfoPage: FunctionComponent = function () {
  const cctvIndex = useContext(CctvIndexContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const lat = searchParams.get("lat");
  const lng = searchParams.get("lng");

  const [cctvData, setCCTVData] = useState<any>([]);
  const [nearCctvData, setNearCctvData] = useState<cctvInfoByIdType[]>([]);
  const [nearCctv, setNearCctv] = useState<string[]>([]);
  const [nearCctvDataIdx, setNearCctvDataIdx] = useState<number[]>([
    0, 0, 0, 0,
  ]);
  useEffect(() => {
    axios
      .get(CCTV_JSON_FILE)
      .then((response) => {
        const getData = response.data.features.filter((item: any) => {
          if (
            getDaeJeonInRangeCoordinate(
              item.geometry.coordinates[LONGTITUDE_INDEX],
              item.geometry.coordinates[LATITUDE_INDEX]
            )
          ) {
            return item;
          }
          return null;
        });
        setCCTVData(getData);
        const data = getCCTVCoordinate(Number(lat), Number(lng), getData);
        setNearCctvDataIdx(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    async function updateNearCctvDatas() {
      const getData = await getNearCctvDatas(cctvData, nearCctvDataIdx);
      if (getData) {
        setNearCctvData(getData);
        console.log(getData);
      }
    }
    updateNearCctvDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearCctvDataIdx]);
  useEffect(() => {
    setNearCctv(getCctvUrl(nearCctvData));
  }, [nearCctvData]);

  return (
    <div style={{ height: "100vh" }}>
      <div style={{ width: "100%", boxShadow: ShadowTheme._6, height: "8vh" }}>
        <div
          style={{
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img src="./img/Logo.png" alt="" style={{ height: "30px" }} />
          <div
            style={{ ...TypographyStyle.heading.bold31, paddingLeft: "24px" }}
          >
            사고 지점 주변 CCTV / Traffic
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: grayScaleTheme.grayScaleColor500,
          height: "92vh",
          display: "flex",
        }}
      >
        <div style={{ width: "50%", margin: "24px" }}>
          <div
            style={{
              backgroundColor: defaultColorTheme.White,
              padding: "24px 32px",
              ...TypographyStyle.label.semiBold25,
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
              height: "80px",
            }}
          >
            CCTV
          </div>
          <GridColumn2 style={{ width: "100%", height: "90%" }}>
            <CctvPage nearData={nearCctv} />
          </GridColumn2>
        </div>
        <div
          style={{
            width: "50%",
            margin: "24px",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              backgroundColor: defaultColorTheme.White,
              padding: "24px 32px",
              ...TypographyStyle.label.semiBold25,
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
              height: "80px",
            }}
          >
            <div>사고 위치 / 교통 정보</div>
          </div>
          <div style={{ height: "90%" }}>
            {nearCctvData.length > 0 ? (
              <NaverMap
                latitude={lat}
                longtitude={lng}
                nearCctvData={nearCctvData}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControllTowerInfoPage;
