import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from "react";
import { MenuHorizontalProps } from "./CarMenuHorizontal";
import { useNavigate } from "react-router-dom";
import { ReportItemContext } from "../../../context/reportItemContext";
import { LogoWrapper, MenuHorizontalWrapper, MenuLogoImg } from "../Menu.style";
import { DividerLine } from "../../StyleComponent/DividerLine";
import Accordion from "../Accordion";
import { BorderButton } from "../../StyleComponent/BorderButton";
import ButtonContent from "../ButtonContents";
import Modal from "../Modal";
import {
  ShadowTheme,
  TypographyComponent,
  TypographyStyle,
} from "../../StyleComponent/FontStyle";
import { ButtonStyle, defaultColorTheme } from "../Theme";
import { Icon } from "@iconify/react";
import { grayScaleTheme } from "../RootColorStyle";
import { Spacer } from "../../StyleComponent/Spacer";

const dateObject = new Date();
const Year = dateObject.getFullYear().toString();
const Month = (dateObject.getMonth() + 1).toString();
const Day = dateObject.getDate().toString();
export type dataObjectType = {
  accel: string;
  address: string;
  createdAt: string;
  date: string;
  idx: number;
  insurance: string;
  location: string;
  number: string;
  ownName: string;
  ownType: string;
  parentPhoneNumber: string;
  pitch: string;
  pty: string;
  roll: string;
  sky: string;
  speed: string;
  t1h: string;
  type: string;
  updatedAt: string;
  userId: string;
  yaw: string;
};
const CarMenuIconHorizontal: FunctionComponent<MenuHorizontalProps> =
  function ({ originItems }) {
    const [selectedButton, setSelectedButton] = useState<number>(-1);
    const [isOpenModal, setOpenModal] = useState(false);
    const [hoverButton, setHoverButton] = useState<number | null>(null);
    const navigate = useNavigate();
    const returnData = useContext(ReportItemContext);
    const onClickToggleModal = useCallback(() => {
      setOpenModal(!isOpenModal);
    }, [isOpenModal]);
    return (
      <>
        {isOpenModal && (
          // <Modal onClickToggleModal={onClickToggleModal}>
          //   <div
          //     style={{
          //       padding: "28px 32px",
          //       borderRadius: "16px",
          //       zIndex: 10000,
          //       backgroundColor: defaultColorTheme.White,
          //       maxWidth: "90%",
          //       maxHeight: "90%",
          //       minWidth: "404px",
          //       minHeight: "294px",
          //       boxShadow: ShadowTheme._16,
          //     }}
          //   >
          //     <div style={{ display: "flex", width: "70vw" }}>
          //       <div
          //         style={{
          //           marginBottom: "16px",
          //         }}
          //       >
          //         <Icon
          //           icon={"priority_high"}
          //           fontSize={"25px"}
          //           style={{ marginRight: "16px" }}
          //         />
          //         <TypographyComponent.label.semiBold20>
          //           Accident List
          //         </TypographyComponent.label.semiBold20>
          //         <div
          //           style={{
          //             overflowY: "scroll",
          //             height: "75vh",
          //             padding: "0 5px",
          //           }}
          //         >
          //           {originItems.map((item, index) => {
          //             return (
          //               <div>
          //                 <BorderButton
          //                   key={index}
          //                   onMouseEnter={() => setHoverButton(index)}
          //                   onMouseLeave={() => setHoverButton(null)}
          //                   onClick={() => {
          //                     setSelectedButton(index);
          //                     returnData.updateData(item.dataObject);
          //                   }}
          //                   style={{
          //                     borderRadius: "8px",
          //                     backgroundColor:
          //                       selectedButton === index
          //                         ? grayScaleTheme.grayScaleColor500
          //                         : hoverButton === index
          //                         ? grayScaleTheme.grayScaleColor50
          //                         : grayScaleTheme.grayScaleColor100,
          //                     padding: "12px",
          //                     marginBottom: "12px",
          //                     boxShadow:
          //                       hoverButton === index
          //                         ? ShadowTheme._12
          //                         : ShadowTheme._2,
          //                     width: "100%",
          //                   }}
          //                   borderRadius={"8px"}
          //                   textColor={""}
          //                   backgroundColor={defaultColorTheme.White}
          //                   paddingHorizontal="12px"
          //                   paddingVertical="12px"
          //                   marginBottom="20px"
          //                 >
          //                   <div
          //                     style={{
          //                       display: "flex",
          //                       paddingLeft: "12px",
          //                       width: "100%",
          //                       justifyContent: "space-between",
          //                     }}
          //                   >
          //                     <div>
          //                       <div
          //                         style={{
          //                           width: "fit-content",
          //                           display: "flex",
          //                           alignItems: "center",
          //                           color:
          //                             selectedButton === index
          //                               ? defaultColorTheme.White
          //                               : grayScaleTheme.grayScaleColor800,
          //                         }}
          //                       >
          //                         <Icon
          //                           icon={"mdi:walk"}
          //                           style={{
          //                             color:
          //                               selectedButton === index
          //                                 ? defaultColorTheme.White
          //                                 : grayScaleTheme.grayScaleColor800,
          //                           }}
          //                         />
          //                         <Spacer horizontal={false} space={"8px"} />
          //                         급발진
          //                       </div>
          //                       <div
          //                         style={{
          //                           color:
          //                             selectedButton === index
          //                               ? defaultColorTheme.White
          //                               : grayScaleTheme.grayScaleColor800,
          //                           textAlign: "initial",
          //                         }}
          //                       >
          //                         {item.dataObject.address}
          //                       </div>
          //                     </div>
          //                     <div
          //                       style={{
          //                         display: "flex",
          //                         justifyContent: "space-between",
          //                         alignItems: "center",
          //                         width: "28%",
          //                       }}
          //                     >
          //                       <div
          //                         style={{
          //                           color:
          //                             selectedButton === index
          //                               ? defaultColorTheme.White
          //                               : grayScaleTheme.grayScaleColor800,
          //                         }}
          //                       >
          //                         {item.dataObject.date.slice(0, 10)}
          //                       </div>
          //                       {/* <Spacer space={"20px"} /> */}
          //                       <div
          //                         style={{
          //                           color:
          //                             selectedButton === index
          //                               ? defaultColorTheme.White
          //                               : grayScaleTheme.grayScaleColor800,
          //                         }}
          //                       >
          //                         {
          //                           item.dataObject.date
          //                             .split(".")[0]
          //                             .split("T")[1]
          //                         }
          //                       </div>
          //                       {/* <Spacer space={"20px"} /> */}
          //                       <Icon
          //                         icon={"ri:arrow-right-s-line"}
          //                         style={{
          //                           color:
          //                             selectedButton === index
          //                               ? defaultColorTheme.White
          //                               : grayScaleTheme.grayScaleColor800,
          //                         }}
          //                       />
          //                     </div>
          //                   </div>
          //                 </BorderButton>
          //               </div>
          //             );
          //           })}
          //         </div>
          //       </div>
          //       <div>
          //         <BorderButton
          //           onClick={() => {
          //             navigate("/accidentreport");
          //           }}
          //           style={{ ...ButtonStyle.Enabled, padding: "8px 16px" }}
          //         >
          //           보고서 미리보기
          //         </BorderButton>
          //       </div>
          //     </div>
          //   </div>
          // </Modal>
          <Modal onClickToggleModal={onClickToggleModal}>
            <div
              style={{
                padding: "28px 32px",
                borderRadius: "16px",
                zIndex: 10000,
                backgroundColor: defaultColorTheme.White,
                maxWidth: "90%",
                maxHeight: "90%",
                minWidth: "404px",
                minHeight: "294px",
                boxShadow: ShadowTheme._16,
              }}
            >
              <div
                style={{
                  // display: "flex",
                  width: "50vw",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginBottom: "16px",
                    marginRight: "16px",
                  }}
                >
                  <Icon
                    icon={"priority_high"}
                    fontSize={"25px"}
                    style={{ marginRight: "16px" }}
                  />
                  <TypographyComponent.label.semiBold20>
                    Accident List
                  </TypographyComponent.label.semiBold20>
                  <div
                    style={{
                      overflowY: "scroll",
                      height: "75vh",
                      padding: "0 5px",
                    }}
                  >
                    {originItems.map((item, index) => {
                      return (
                        <div key={index}>
                          <BorderButton
                            key={index}
                            onMouseEnter={() => setHoverButton(index)}
                            onMouseLeave={() => setHoverButton(null)}
                            onClick={() => {
                              setSelectedButton(index);
                              returnData.updateData(item.dataObject);
                            }}
                            style={{
                              borderRadius: "8px",
                              backgroundColor:
                                selectedButton === index
                                  ? grayScaleTheme.grayScaleColor500
                                  : hoverButton === index
                                  ? grayScaleTheme.grayScaleColor50
                                  : grayScaleTheme.grayScaleColor100,
                              padding: "12px",
                              marginBottom: "12px",
                              boxShadow:
                                hoverButton === index
                                  ? ShadowTheme._12
                                  : ShadowTheme._2,
                              width: "100%",
                            }}
                            borderRadius={"8px"}
                            textColor={""}
                            backgroundColor={defaultColorTheme.White}
                            paddingHorizontal="12px"
                            paddingVertical="12px"
                            marginBottom="20px"
                          >
                            <div
                              style={{
                                display: "flex",
                                paddingLeft: "12px",
                                width: "100%",
                                justifyContent: "space-between",
                                textAlign: "left",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    width: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    color:
                                      selectedButton === index
                                        ? defaultColorTheme.White
                                        : grayScaleTheme.grayScaleColor800,
                                  }}
                                >
                                  <Icon
                                    icon={
                                      "healthicons:accident-and-emergency-outline"
                                    }
                                    style={{
                                      color:
                                        selectedButton === index
                                          ? defaultColorTheme.White
                                          : grayScaleTheme.grayScaleColor800,
                                    }}
                                  />
                                  <Spacer horizontal={false} space={"8px"} />
                                  {item.dataObject.speed === "-"
                                    ? "Emergency Report"
                                    : ""}
                                </div>
                                <div
                                  style={{
                                    color:
                                      selectedButton === index
                                        ? defaultColorTheme.White
                                        : grayScaleTheme.grayScaleColor800,
                                  }}
                                >
                                  {item.dataObject.address}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "28%",
                                }}
                              >
                                <div
                                  style={{
                                    color:
                                      selectedButton === index
                                        ? defaultColorTheme.White
                                        : grayScaleTheme.grayScaleColor800,
                                  }}
                                >
                                  {item.dataObject.date.slice(0, 10)}
                                </div>
                                {/* <Spacer space={"20px"} /> */}
                                <div
                                  style={{
                                    color:
                                      selectedButton === index
                                        ? defaultColorTheme.White
                                        : grayScaleTheme.grayScaleColor800,
                                  }}
                                >
                                  {
                                    item.dataObject.date
                                      .split(".")[0]
                                      .split("T")[1]
                                  }
                                </div>
                                <Icon
                                  icon={"ri:arrow-right-s-line"}
                                  style={{
                                    color:
                                      selectedButton === index
                                        ? defaultColorTheme.White
                                        : grayScaleTheme.grayScaleColor800,
                                  }}
                                />
                              </div>
                            </div>
                          </BorderButton>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BorderButton
                    onClick={() => {
                      navigate("/caraccidentreport");
                    }}
                    style={{
                      ...ButtonStyle.Enabled,
                      padding: "8px 16px",
                      width: "150px",
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  >
                    Report Preivew
                  </BorderButton>
                  <Spacer horizontal={true} space={"16px"} />
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor500,
                    }}
                  >
                    Direct reporting does not provide acceleration sensor
                    values,
                    <br />
                    speed, or gyro sensors (Roll/Yaw/Pitch).
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
        <MenuHorizontalWrapper>
          <LogoWrapper>
            <MenuLogoImg src="/img/MobileLogo.png" alt="" />
          </LogoWrapper>
          <DividerLine />
          <Accordion />
          <DividerLine />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <BorderButton backgroundColor="white" onClick={() => {}}>
              <ButtonContent
                iconSrc="mdi:exclamation"
                title={""}
                noticeFlag={false}
              />
            </BorderButton>
          </div>
          <DividerLine />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <BorderButton
              backgroundColor="white"
              onClick={() => {
                setOpenModal(!isOpenModal);
              }}
            >
              <ButtonContent
                iconSrc="gg:export"
                title={""}
                noticeFlag={false}
              />
            </BorderButton>
          </div>
          <DividerLine />
        </MenuHorizontalWrapper>
      </>
    );
  };

export default CarMenuIconHorizontal;
