import { Icon } from "@iconify/react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import axios from "axios";

import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import SockJS from "sockjs-client";
import { over } from "webstomp-client";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { userInfoType } from "./Dashboard";
import { AccidentIndexContext } from "../../context/accidentIndexContext";
import MenuIconHorizontal from "../Layout/Menu/MenuIconHorizontal";
import Modal from "../Layout/Modal";
import { defaultColorTheme } from "../Layout/Theme";
import MenuHorizontal from "../Layout/Menu/MenuHorizontal";
import {
  ShadowTheme,
  TypographyComponent,
  TypographyStyle,
} from "../StyleComponent/FontStyle";
import { DividerLine } from "../StyleComponent/DividerLine";
import { Graph, GraphTable } from "../Layout/GraphComponent";
import {
  FlexBackground,
  FlexCenter,
  GridColumn4to2,
} from "../Layout/Layout.Style";
import { grayScaleTheme } from "../Layout/RootColorStyle";
import { BorderButton } from "../StyleComponent/BorderButton";
import VerticalNameTagRadiusBox from "../StyleComponent/VerticalNameTagRadiusBox";
import { Spacer } from "../StyleComponent/Spacer";
import CarMenuIconHorizontal from "../Layout/Cartalog_Menu/CarMenuIconHorizontal";
import CarMenuHorizontal from "../Layout/Cartalog_Menu/CarMenuHorizontal";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
type sortType = {
  car: boolean;
  bicycle: boolean;
  wheelchair: boolean;
  pedestrian: boolean;
};
export type dataObjectType = {
  accel: string;
  address: string;
  createdAt: string;
  date: string;
  idx: number;
  insurance: string;
  location: string;
  number: string;
  ownName: string;
  ownType: string;
  parentPhoneNumber: string;
  pitch: string;
  pty: string;
  roll: string;
  sky: string;
  speed: string;
  t1h: string;
  type: string;
  updatedAt: string;
  userId: string;
  yaw: string;
};

// type dataObjectType = {
//   idx: number;
//   date: string;
//   address: string;
//   location: string;
//   speed: string;
//   rpm: string;
//   createdAt: string;
//   updatedAt: string;
// };
type Item = {
  dataKey: string;
  dataObject: dataObjectType;
};
const API_KEY = "JAXEBGHR";
const setAverage = function (getData: string[]) {
  const numberArray = getData.map((stringValue) => parseInt(stringValue, 10));
  const sum =
    numberArray.reduce((total, currentValue) => total + currentValue, 0) /
    numberArray.length;
  return sum;
};

const changeStringArrToNumberArr = function (getData: string[]) {
  const numberArray = getData.map((stringValue) => parseInt(stringValue, 10));
  return numberArray;
};

const setMax = function (getData: string[]) {
  const numberArray = getData.map((stringValue) => parseInt(stringValue, 10));
  return Math.max(...numberArray);
};
const Cartalog: FunctionComponent = function () {
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: process.env.REACT_APP_API_KEY,
    googleMapsApiKey: "AIzaSyAUMOlJCMKz9JhtLsahW_XVOCOFNqoG7Ys",
  });
  const containerStyle = {
    width: "100%",
    height: "95%",
    borderRadius: "8px",
  };
  const [info, setInfo] = useState<dataObjectType>({
    accel: "-1",
    address: "",
    createdAt: "",
    date: "",
    idx: -1,
    insurance: "",
    location: "36.8098309/ 127.1442811",
    number: "",
    ownName: "",
    ownType: "",
    parentPhoneNumber: "",
    pitch: "-1",
    pty: "0",
    roll: "-1",
    sky: "0",
    speed: "",
    t1h: "0",
    type: "0",
    updatedAt: "",
    userId: "",
    yaw: "0",
  });
  // const [info, setInfo] = useState<dataObjectType>({
  //   idx: -1,
  //   date: "",
  //   createdAt: "",
  //   updatedAt: "",
  //   address: "",
  //   location: "",
  //   speed: "",
  //   rpm: "",
  // });

  const [originItems, setOriginItems] = useState<Item[]>([]);
  const [numberRpm, setNumberRpm] = useState<number>();
  const [items, setItems] = useState<Item[]>([]);
  const [filteredItems, setFiltereditems] = useState<Item[]>([]);
  const [selectedButton, setSelectedButton] = useState<number>(0);
  const [hoverButton, setHoverButton] = useState<number | null>(null);
  const [isOpenModal, setOpenModal] = useState(false);
  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);

  const [what3wordsAddress, setWhat3wordsAddress] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userData, setUserData] = useState<userInfoType>({
    createdAt: "",
    updatedAt: "",
    idx: "",
    userId: "",
    name: "",
    parentNumber: "",
    profileImageUrl: "",
    loginType: "",
  });
  const AccidentRecord = [
    // "경미한 사고로 추정",
    // "상당한 사고로 추정",
    // "중대한 사고로 추정",
    "Probably a minor accident",
    "Probably a significant accident",
    "Probably a serious accident",
  ];
  const [record, setRecord] = useState<{
    label: string;
    value: number;
    color: string;
  }>({
    label: "",
    value: 0,
    color: "",
  });
  const userInfo = useContext(AuthContext);
  const navigation = useNavigate();

  useEffect(() => {
    if (userInfo?.uid !== "yzd7S2S8oDP71211KadrC5WVhT33") {
      navigation("/carserverlogin");
    }
  }, [navigation, userInfo]);
  const checkAccidentType = function (props: dataObjectType) {
    axios
      .post("/api/mobile/user/login", {
        user_id: props.userId,
      })
      .then(function (response) {
        setUserData(response.data.data.user);
        setInfo((prev) => ({
          ...prev,
          ownName: response.data.data.user.name,
          parentPhoneNumber: response.data.data.user.parentNumber,
        }));
      });
  };
  const indexData = useContext(AccidentIndexContext);
  useEffect(() => {
    const Data = items.find(
      (item) => item.dataObject.idx === indexData.accidentIndex
    );
    if (Data !== undefined) {
      checkAccidentType(Data.dataObject);
      setInfo(Data.dataObject);
      setSelectedButton(Data.dataObject.idx);
    }
  }, [indexData.accidentIndex]);
  /*onChildAdded 함수를 사용하면 DB에 새로운 정보가 저정되었을 때, dbRef에 있는 AccidentRecord 데이터 불러온다.*/
  useEffect(() => {
    axios
      .get("/api/accident-record/all")
      .then((Response) => {
        const dataKey = Object.keys(Response.data.data);
        const dataValue = Object.values(Response.data.data);
        const Items: Item[] = dataKey.map((key, index) => ({
          dataKey: key,
          dataObject: dataValue[index] as dataObjectType,
        }));
        Items.reverse();
        // oldIdx = Items[0].dataObject.idx;
        console.log(Items);
        setItems(Items);
        setOriginItems(Items);
        setFiltereditems(Items);
        setInfo(Items[0].dataObject);
        setLatitude(Number(info.location.split("/")[0]));
        setLongitude(Number(info.location.split("/")[1]));
        handleConvertToWhat3words();
        Number(info.accel.split(",")[info.accel.split(",").length - 1]) < 150
          ? setRecord({
              label: AccidentRecord[0],
              value: 33,
              color: "#0ECE49",
            })
          : Number(info.accel.split(",")[info.accel.split(",").length - 1]) >
            400
          ? setRecord({
              label: AccidentRecord[2],
              value: 100,
              color: "#F0273D",
            })
          : setRecord({
              label: AccidentRecord[1],
              value: 66,
              color: "#F36B1E",
            });
      })
      .catch((Error) => {
        console.log(Error);
      });
    return () => {};
    // .then((Response) => {
    //   const dataKey = Object.keys(Response.data.data);
    //   const dataValue = Object.values(Response.data.data);
    //   const Items: Item[] = dataKey.map((key, index) => ({
    //     dataKey: key,
    //     dataObject: dataValue[index] as dataObjectType,
    //   }));
    //   setItems(Items);
    //   setOriginItems(Items);
    //   setFiltereditems(Items);
    //   setInfo(Items[0].dataObject);
    //   console.log(Items);
    // })
    // .catch((Error) => {
    //   console.log(Error);
    // });
  }, []);

  let testtest = 0;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const socket = new SockJS("https://alwayssafeu.com/wss");
    const stompClient = over(socket);

    stompClient.connect({}, (frame) => {
      stompClient.subscribe("/topic/accidentRecords", (message) => {
        if (message.body) {
          const Item = {
            dataKey: items.length.toString(),
            dataObject: JSON.parse(message.body),
          };
          if (testtest !== Item.dataObject.idx) {
            setItems((items) => [Item, ...items]);
            testtest = Item.dataObject.idx;
          }

          setInfo(Item.dataObject);
          setSelectedButton(0);
        }
      });
    });
    return () => {
      if (stompClient.connected) {
        stompClient.disconnect(() => {}, {});
      }
    };
  }, []);
  const handleConvertToWhat3words = () => {
    // What3words API 키
    const what3wordsApiKey = API_KEY;

    // 좌표를 What3words 주소로 변환하는 API 엔드포인트
    const apiUrl = `https://api.what3words.com/v3/convert-to-3wa?coordinates=${latitude},${longitude}&key=${what3wordsApiKey}`;

    // Axios를 사용하여 API 호출
    axios
      .get(apiUrl)
      .then((response) => {
        // API 응답에서 What3words 주소 추출
        const address = response.data.words;
        setWhat3wordsAddress(address);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const sortingDB = (props: { Item: Item[]; Type: boolean }) => {
    // setItems(props.Item);
  };
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div style={{ display: "flex" }}>
      {/* 레이아웃 크기 지정은 %로 지정하지 않고 px로 지정해야할 듯 */}
      {windowWidth <= 1200 ? (
        <CarMenuIconHorizontal
          originItems={originItems}
          selectItemIdx={selectedButton}
        />
      ) : (
        // eslint-disable-next-line react/jsx-no-undef
        <CarMenuHorizontal
          originItems={originItems}
          selectItemIdx={selectedButton}
        />
      )}
      {isOpenModal ? (
        <Modal onClickToggleModal={onClickToggleModal}>
          <div
            style={{
              zIndex: 10000,
              backgroundColor: defaultColorTheme.White,
              minWidth: "90%",
              maxHeight: "90%",
              boxShadow:
                "0px 4px 12px 0px rgba(0, 0, 0, 0.22),0px 4px 24px 0px rgba(0, 0, 0, 0.16)",
              borderRadius: "8px",
              padding: "24px",
              // height: "84vh",
              overflowY: "scroll",
            }}
          >
            <TypographyComponent.heading.bold31>
              RPM
            </TypographyComponent.heading.bold31>

            <DividerLine />
            <TypographyComponent.heading.bold25>
              RPM
            </TypographyComponent.heading.bold25>
            {/* <GraphTable
              data={changeStringArrToNumberArr(info.rpm.split(","))}
              title="RPM"
              color={grayScaleTheme.grayScaleColor200}
            /> */}
            <DividerLine />
            <TypographyComponent.heading.bold25>
              속도
            </TypographyComponent.heading.bold25>

            <GraphTable
              data={changeStringArrToNumberArr(info.speed.split(","))}
              title="속도"
              color="hsl(249, 70%, 50%)"
            />
            <DividerLine />
            <TypographyComponent.heading.bold25>
              스코프
            </TypographyComponent.heading.bold25>
            <Graph
              data={changeStringArrToNumberArr(info.speed.split(","))}
              title="스코프"
              color="hsl(299, 70%, 50%)"
            />
            <DividerLine />
          </div>
        </Modal>
      ) : null}
      <FlexBackground style={{ width: "100%" }}>
        <div style={{ width: "50%" }}>
          <div
            style={{
              boxShadow: ShadowTheme._16,
              borderRadius: "40px",
              backgroundColor: grayScaleTheme.grayScaleColor100,
              height: "96.7vh",
              margin: "16px 16px",
              padding: "32px",
              position: "relative",
            }}
          >
            <FlexCenter style={{ marginBottom: "16px" }}>
              <Icon
                icon={"mdi:location"}
                fontSize={"25px"}
                style={{ marginRight: "16px" }}
              />
              <TypographyComponent.label.semiBold20>
                Location (Map)
              </TypographyComponent.label.semiBold20>
            </FlexCenter>
            <GoogleMap
              zoom={16}
              mapContainerStyle={containerStyle}
              center={{
                lat: Number(info.location.split("/")[0]),
                lng: Number(info.location.split("/")[1]),
              }}
              mapContainerClassName="map-container"
              options={{ disableDefaultUI: true }}
            >
              <div
                style={{
                  display: "flex",
                  width: "93%",
                  height: "54px",
                  marginTop: "16px",
                  marginLeft: "20px",
                  zIndex: 99,
                  position: "absolute",
                  borderRadius: "8px",
                  boxShadow: "0 2px 16px 0 gray",
                  backgroundColor: defaultColorTheme.White,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "16px",
                    margin: "8px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/img/w3w_Symbol_RGB_Red.png"
                    alt=""
                    style={{ width: "50px" }}
                  />
                  <Spacer space={"26px"} />
                  <TypographyComponent.label.semiBold20>
                    {what3wordsAddress}
                  </TypographyComponent.label.semiBold20>
                </div>
              </div>
              <MarkerF
                position={{
                  lat: Number(info.location.split("/")[0]),
                  lng: Number(info.location.split("/")[1]),
                }}
                icon={{ url: "/img/MapMarker.png", scale: 5 }}
              />
            </GoogleMap>
          </div>
        </div>
        <div style={{ width: "58%" }}>
          <div
            style={{
              boxShadow: ShadowTheme._16,
              boxSizing: "border-box",
              borderRadius: "40px",
              backgroundColor: grayScaleTheme.grayScaleColor100,
              height: "35vh",
              margin: "16px 16px",
              padding: "24px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Icon
                icon={"priority_high"}
                fontSize={"25px"}
                style={{ marginRight: "16px" }}
              />
              <TypographyComponent.label.semiBold20>
                Accident List
              </TypographyComponent.label.semiBold20>
            </div>
            <div
              style={{
                overflowY: "scroll",
                height: "85%",
                padding: "0 5px",
              }}
            >
              {items.map((item, index) => {
                return (
                  <div>
                    <BorderButton
                      key={index}
                      onMouseEnter={() => setHoverButton(index)}
                      onMouseLeave={() => setHoverButton(null)}
                      onClick={() => {
                        setInfo(item.dataObject);
                        setSelectedButton(index);
                        setSelectedButton(item.dataObject.idx);
                        setLatitude(
                          Number(item.dataObject.location.split("/")[0])
                        );
                        setLongitude(
                          Number(item.dataObject.location.split("/")[1])
                        );
                        handleConvertToWhat3words();
                        // setNumberRpm(
                        //   setAverage(item.dataObject.rpm.split(","))
                        // );
                      }}
                      style={{
                        borderRadius: "8px",
                        backgroundColor:
                          selectedButton === item.dataObject.idx
                            ? grayScaleTheme.grayScaleColor700
                            : hoverButton === item.dataObject.idx
                            ? grayScaleTheme.grayScaleColor50
                            : "white",
                        padding: "12px",
                        marginBottom: "12px",
                        boxShadow:
                          hoverButton === item.dataObject.idx
                            ? ShadowTheme._12
                            : ShadowTheme._2,
                        width: "100%",
                      }}
                      borderRadius={"8px"}
                      textColor={""}
                      backgroundColor={defaultColorTheme.White}
                      paddingHorizontal="12px"
                      paddingVertical="12px"
                      marginBottom="20px"
                    >
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "12px",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              color:
                                selectedButton === item.dataObject.idx
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                              display: "flex",
                              textAlign: "left",
                            }}
                          >
                            <div
                              style={{
                                width: "fit-content",
                                display: "flex",
                                alignItems: "center",
                                color:
                                  selectedButton === item.dataObject.idx
                                    ? defaultColorTheme.White
                                    : grayScaleTheme.grayScaleColor800,
                              }}
                            >
                              <Icon
                                icon={
                                  "healthicons:accident-and-emergency-outline"
                                }
                                style={{
                                  paddingRight: "4px",
                                  fontSize: "30px",
                                  color:
                                    selectedButton === item.dataObject.idx
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              />
                            </div>
                            Car Accident
                            <br />
                            {item.dataObject.address}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "28%",
                          }}
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color:
                                selectedButton === item.dataObject.idx
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          >
                            {item.dataObject.date.slice(0, 10)}
                          </div>
                          {/* <Spacer space={"20px"} /> */}
                          <div
                            style={{
                              color:
                                selectedButton === item.dataObject.idx
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          >
                            {item.dataObject.date.slice(
                              11,
                              item.dataObject.date.length
                            )}
                          </div>
                          {/* <Spacer space={"20px"} /> */}
                          <Icon
                            icon={"ri:arrow-right-s-line"}
                            style={{
                              color:
                                selectedButton === item.dataObject.idx
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          />
                        </div>
                      </div>
                    </BorderButton>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              boxShadow: ShadowTheme._16,
              borderRadius: "40px",
              backgroundColor: grayScaleTheme.grayScaleColor100,
              height: "59.7vh",
              margin: "16px 16px",
              padding: "32px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                marginBottom: "16px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Icon
                  icon={"material-symbols:grid-on-sharp"}
                  fontSize={"25px"}
                  style={{ marginRight: "16px" }}
                />
                <TypographyComponent.label.semiBold20>
                  Details
                </TypographyComponent.label.semiBold20>
              </div>
              <Spacer horizontal={true} space={"8px"} />
              <div
                style={{
                  overflowY: "scroll",
                }}
              >
                {/* row1 */}
                <GridColumn4to2>
                  <VerticalNameTagRadiusBox
                    children={
                      info.roll.split(",")[29] === undefined ? (
                        <div
                          style={{
                            display: "flex",
                            padding: "16px 16px",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div>Emergency Report</div>
                          <div
                            style={{
                              ...TypographyStyle.paragraphRegular
                                .paragraphRegular12,
                              color: grayScaleTheme.grayScaleColor500,
                              wordBreak: "keep-all",
                            }}
                          >
                            Direct reporting does not provide acceleration
                            sensor value, speed, or gyro sensor
                            (Roll/Yaw/Pitch).
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            padding: "16px 16px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginRight: "16px" }}>
                            <div
                              style={{
                                width: "60px",
                                height: "60px",
                                position: "relative",
                                marginRight: "20px",
                              }}
                            >
                              <CircularProgressbar
                                counterClockwise={true}
                                strokeWidth={15}
                                value={record.value}
                                styles={buildStyles({
                                  textColor: grayScaleTheme.grayScaleColor800,
                                  textSize: "30px",
                                  pathColor: record.color,
                                  trailColor: grayScaleTheme.grayScaleColor200,
                                })}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: "32%",
                                  left: "30%",
                                }}
                              >
                                {Number(
                                  info.accel
                                    .split(",")
                                    [info.accel.split(",").length - 1].split(
                                      "."
                                    )[0]
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                ...TypographyStyle.paragraphMedium
                                  .paragraphMedium16,
                                wordBreak: "keep-all",
                              }}
                            >
                              {record.label}
                            </div>
                            <div
                              style={{
                                ...TypographyStyle.paragraphRegular
                                  .paragraphRegular12,
                                color: grayScaleTheme.grayScaleColor500,
                                wordBreak: "keep-all",
                              }}
                            >
                              This data has no legal effect. It is for your
                              information.
                            </div>
                          </div>
                        </div>
                      )
                    }
                    LabelText={null}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.ownName}
                    LabelText={"User"}
                  />

                  {/* row2 */}
                  <VerticalNameTagRadiusBox
                    children={info.number}
                    LabelText={"Car Number"}
                  />
                  <VerticalNameTagRadiusBox
                    children={
                      info.insurance === null ? "Geico" : info.insurance
                    }
                    LabelText={"Insurance"}
                  />
                  <VerticalNameTagRadiusBox
                    children={
                      info.parentPhoneNumber === "01012341234"
                        ? "19496782552"
                        : info.parentPhoneNumber
                    }
                    LabelText={"Parent Contact"}
                  />

                  {/* row3 */}
                  <VerticalNameTagRadiusBox
                    children={"Car Type"}
                    LabelText={"Accident Type"}
                  />

                  <VerticalNameTagRadiusBox
                    children={info.date}
                    LabelText={"Accident Date"}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.address}
                    LabelText={"Accident Address"}
                  />
                  <VerticalNameTagRadiusBox
                    children={
                      info.roll.split(",")[29] !== undefined
                        ? info.roll.split(",")[29] +
                          "/ " +
                          info.yaw.split(",")[29]
                        : "-/-"
                    }
                    wordBreak={true}
                    LabelText={"Roll/Yaw"}
                  />
                </GridColumn4to2>
              </div>
            </div>
          </div>
        </div>
      </FlexBackground>
    </div>
  );
};

export default Cartalog;
