import { CSSProperties, FunctionComponent, ReactElement } from "react";

export const BorderButton: FunctionComponent<{
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  borderRadius?: string;
  textColor?: string;
  backgroundColor?: string;
  hoverColor?: string;
  paddingHorizontal?: string;
  paddingVertical?: string;
  marginBottom?: string;
  children: ReactElement | ReactElement[] | string;
  style?: CSSProperties | object | undefined;
}> = function (props) {
  if (props.style) {
    return (
      <button
        onClick={props.onClick}
        style={{ ...props.style, marginRight: "8px" }}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        {props.children}
      </button>
    );
  } else {
    return (
      <button
        onClick={props.onClick}
        style={{
          color: props.textColor,
          backgroundColor: props.backgroundColor,
          paddingTop: props.paddingHorizontal,
          paddingBottom: props.paddingHorizontal,
          paddingRight: props.paddingVertical,
          paddingLeft: props.paddingVertical,
          borderRadius: props.borderRadius,
          marginBottom: props.marginBottom,
        }}
      >
        {props.children}
      </button>
    );
  }
};
