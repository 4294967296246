import { FunctionComponent } from "react";
import ContentsCard from "./ContentsCard";
const TextDataKr = {
  headline: "마이렌 주요 기능",
  firstTitle: "실시간 사고감지",
  firstContent1: "사고났슈 앱에서 실시간 사고 감지 모드를 켜고 끌 수 있습니다.",
  firstContent2: "자동차, 오토바이, 자전거, 킥보드, 버스, 보행자, 휠체어",
  firstContent3: "총 7개 모드 중 하나를 선택할 수 있습니다.",
  secoendTitle: "자동 사고 신고",
  secoendContent1: "사고가 감지되면 30초 카운트다운이 시작됩니다.",
  secoendContent2: "30초 이내에 응답하지 않으면 자동으로 신고가 진행됩니다.",
  thirdTitle: "사고 신고 완료",
  thirdContent1:
    "사고에 대한 다양한 데이터를 유관기관 및 관계자에게 전송합니다.",
  thirdContent2:
    "이용되는 데이터에는 사용자 정보, 위치, 사고 일자 등이 포함됩니다.",
  fourthTitle: "순위",
  fourthContent1:
    "사고 감지 기능을 사용한 누적 사용 시간만큼 순위가 집계됩니다.",
  fourthContent2:
    "높은 순위의 사용자는 각종 이벤트 등에서 상품을 수령할 수 있습니다.",
  fifthTitle: "사고 대응요령",
  fifthContent1: "교통사고 발생 시 대응요령 5가지에 대해 안내해 드립니다.",
  fifthContent2:
    "2차 교통사고 예방법, 부상자 구호, 사고현장 촬영 등의 가이드라인을 제공합니다.",
  sixthTitle: "  신고 내역",
  sixthContent1:
    "사용자의 사고신고 내역을 표시합니다. 사고신고 유형 여부, 날짜, 시간,",
  sixthContent2:
    "위치 주소가 표시됩니다. 눌러서 상세 사고 정보를 확인할 수 있습니다.",
  seventhTitle: "센서 민감도 설정",
  seventhContent1:
    "사고감지에 사용되는 디바이스 내부 센서의 민감도를 조정합니다.",
  seventhContent2:
    "약한 접촉사고부터 충돌•추돌 사고 범위로 감지할 수 있습니다.",
  eighthTitle: "사고 유형 자동 감지",
  eighthContent1:
    "‘실시간 사고 감지 모드’를 별도로 선택하지 않아도 자동으로 감지합니다.",
};
const TextDataEn = {
  headline: "Main features of MYREN",
  firstTitle: "Real-time accident detection",
  firstContent1:
    "Real-time incident detection mode is activated while using the App.",
  firstContent2:
    "You can enable/disable by pressing the toggle button directly.",
  firstContent3: "총 7개 모드 중 하나를 선택할 수 있습니다.",
  secoendTitle: "automatic accident report",
  secoendContent1:
    "If an accident is detected, a 30-second countdown will begin.",
  secoendContent2:
    "If you do not respond within 30 seconds, the report will proceed automatically.",
  thirdTitle: "a direct report of an accident",
  thirdContent1:
    "Even if the accident is not detected automatically, it is possible to report the accident directly.",
  thirdContent2:
    "After selecting the accident type, you can easily report it by pressing the incident report button.",
  fourthTitle: "Accident report completed",
  fourthContent1:
    "Send information about the accident to the relevant authorities and associates.",
  fourthContent2:
    "Data used includes profiles, location information, and accident date.",
  fifthTitle: "Tips for responding to traffic accidents",
  fifthContent1:
    "Here are five ways to respond in the event of a traffic accident.",
  fifthContent2:
    "It provides guidelines on how to prevent secondary traffic accidents, relief for the injured, and shooting at the scene of the accident.",
  sixthTitle: "Report details",
  sixthContent1:
    "Displays the incident report history of the user. Type of accident report, date, time,",
  sixthContent2:
    "The location address is displayed. Touch to view detailed incident information.",
  seventhTitle: "Sensor Sensitivity Settings",
  seventhContent1:
    "Adjust the sensitivity of the sensor inside the device used to detect an accident.",
  seventhContent2:
    "It can be detected from weak contact accidents to collision/collision accident ranges of collisions.",
  eighthTitle: "사고 유형 자동 감지",
  eighthContent1:
    "‘실시간 사고 감지 모드’를 별도로 선택하지 않아도 자동으로 감지합니다.",
};

const ThirdContents: FunctionComponent = function () {
  const lang = window.localStorage.getItem("language");
  let TextData = TextDataKr;
  lang === "false" ? (TextData = TextDataKr) : (TextData = TextDataEn);
  return (
    <div>
      {/* right card */}
      <ContentsCard
        side={false}
        title={TextData.firstTitle}
        imgURL={"./img/RealtimeMockUP.png"}
      >
        {TextData.firstContent1}
        <br /> {TextData.firstContent2}
        <br /> {TextData.firstContent3}
      </ContentsCard>
      {/* left card */}
      <ContentsCard
        side={true}
        title={TextData.secoendTitle}
        imgURL={"./img/AutoSirenImg.png"}
      >
        {TextData.secoendContent1}
        <br /> {TextData.secoendContent2}
      </ContentsCard>
      {/* right card */}
      <ContentsCard
        side={false}
        title={TextData.thirdTitle}
        imgURL={"./img/DirectSirenFull.png"}
      >
        {TextData.thirdContent1}
        <br /> {TextData.thirdContent2}
      </ContentsCard>
      {/* left card */}
      <ContentsCard
        side={true}
        title={TextData.fourthTitle}
        imgURL={"./img/RankingMockUP.png"}
      >
        {TextData.fourthContent1}
        <br /> {TextData.fourthContent2}
      </ContentsCard>
      {/* right card */}
      <ContentsCard
        side={false}
        title={TextData.fifthTitle}
        imgURL={"./img/AccidentReact.png"}
      >
        {TextData.fifthContent1}
        <br /> {TextData.fifthContent2}
      </ContentsCard>
      {/* left card */}
      <ContentsCard
        side={true}
        title={TextData.sixthTitle}
        imgURL={"./img/AccidentListMockUP.png"}
      >
        {TextData.sixthContent1}
        <br /> {TextData.sixthContent2}
      </ContentsCard>
      <ContentsCard
        side={false}
        title={TextData.eighthTitle}
        imgURL={"./img/AutoModeMockUP.png"}
      >
        {TextData.eighthContent1}
      </ContentsCard>
    </div>
  );
};

export default ThirdContents;
