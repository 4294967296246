import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyADFLmXrkQcGbRzSQD35MghBT88SDH59zI",
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: "myrenmode",
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: "1:1049493852059:web:bf130a7b9611a887e4db3e",
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
const Firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(Firebase);
const firestore = getFirestore(Firebase);
const database = getDatabase(Firebase);
const auth = getAuth(Firebase);
export { analytics, Firebase, firestore, database, auth };
