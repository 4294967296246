import React from "react";
import { dataObjectType } from "../components/page/ControllServer";

type reportContextType = {
  accidentData: dataObjectType;
  updateData: (newData: dataObjectType) => void;
};

export const ReportItemContext = React.createContext<reportContextType>({
  accidentData: {
    accel: "-1",
    address: "",
    createdAt: "",
    date: "",
    idx: -1,
    insurance: "",
    location: "36.8098309/ 127.1442811",
    number: "",
    ownName: "",
    ownType: "",
    parentPhoneNumber: "",
    pitch: "-1",
    pty: "0",
    roll: "-1",
    sky: "0",
    speed: "",
    t1h: "0",
    type: "0",
    updatedAt: "",
    userId: "",
    yaw: "0",
  },
  updateData: function (newData: dataObjectType): void {},
});
