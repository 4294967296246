import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { defaultColorTheme } from "./Theme";
import {
  FontSizeValue,
  FontWeightTheme,
  LetterSpacingValue,
  LineHeightValue,
  ShadowTheme,
} from "../StyleComponent/FontStyle";
import { emeraldScaleTheme, grayScaleTheme } from "./RootColorStyle";

export const slideFadeInDropdownAnimation = keyframes`
0% {
  transform: translateY(-100%);
}

100% {
  transform: translateY(0);
}
`;

export const slideFadeOutDropdownAnimation = keyframes`
0% {
  transform: translateY(0);
}

100% {
  transform: translateY(-100%);
}
`;

export const MenuWrapper = styled.div`
  height: 6.25rem;
  width: 100%;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    height: 48px;
  }
`;

export const TopContents = styled.div`
  z-index: 10;
  height: 6.25rem;
  width: 100%;
  // padding-right:50%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 96px;
  display: flex;
  position: relative;
  box-shadow: 0 0 6px 0 #d7d7d7;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    justify-content: space-between;
    height: 3rem;
    padding: 0 1.25rem;
  }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    justify-content: space-between;
    padding: 0 3rem;
  }
`;

export const MenuItemDropDownContainer = styled.div`
  width: 100%;

  &.slide-fade-out-dropdown {
    overflow: hidden;
    position: relative;
    background: ${defaultColorTheme.White};
  }
  &.slide-fade-in-dropdown {
    overflow: hidden;
    position: relative;
    background: ${defaultColorTheme.White};
    height: 110px;
  }
  &.slide-fade-out-dropdown > div {
    display: flex;
    flex-direction: column;
    animation: ${slideFadeOutDropdownAnimation} 0.4s ease;
    animation-fill-mode: forwards;
  }
  &.slide-fade-in-dropdown > div {
    display: flex;
    flex-direction: column;
    animation: ${slideFadeInDropdownAnimation} 0.4s ease;
  }
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  &.max-width-page {
    align-items: stretch;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  @media (max-width: 1024px) {
  }
`;
export const MenuUnorderedList = styled.ul`
  list-style: none;
  &.max-width-page {
    display: flex;
    padding: 0;
    justify-content: space-between;
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  &:hover {
    color: ${defaultColorTheme.White};
  }
`;

export const MainIcon = styled.img`
  width: 112.48px;
  height: 28px;

  @media screen and (max-width: 768px) and (min-width: 320px) {
    width: 75px;
    height: 16px;
  }

  @media screen and (max-width: 768px) and (min-width: 769px) {
    width: 150px;
    height: 30px;
  }
  @media screen and (min-width: 1024px) {
    position: absolute;
    left: 96px;
    top: 35px;
  }
`;

export const SettingDiv = styled.div`
  // width: 11.75rem;
  // height: 2.5rem;

  @media screen and (max-width: 768px) and (min-width: 320px) {
    width: 75px;
    height: 16px;
  }

  @media screen and (max-width: 768px) and (min-width: 769px) {
    width: 150px;
    height: 30px;
  }
  @media screen and (min-width: 1024px) {
    position: absolute;
    right: 5rem;
    top: 2.3rem;
  }
`;

export const LeftIconTypo = styled.p`
  left: 19.25rem;
  position: absolute;

  font-weight: ${FontWeightTheme.Label};
  font-size: ${FontSizeValue._39};
  letter-spacing: ${LetterSpacingValue._minus05};

  @media screen and (max-width: 768px) and (min-width: 320px) {
    left: 6.938rem;
    // padding: 0 1.25rem;
    font-weight: ${FontWeightTheme.Label};
    line-height: ${LineHeightValue.Paragraph};
    font-size: ${FontSizeValue._20};
    letter-spacing: ${LetterSpacingValue._minus05};
  }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    left: 16.25rem;
  }
`;

export const MainIconNoPosition = styled.img`
  width: 11.75rem;
  height: 2.5rem;

  @media screen and (max-width: 768px) and (min-width: 320px) {
    width: 75px;
    height: 16px;
  }

  @media screen and (max-width: 768px) and (min-width: 769px) {
    width: 150px;
    height: 30px;
  }
`;

export const ContactBtn = styled.button`
  text-align: center;
  //width: 123px;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  border-radius: 0.5rem;
  font-size: ;16px;
  background-color: ${defaultColorTheme.White};
  color: ${defaultColorTheme.Black};
  @media screen and (max-width: 768px) and (min-width: 320px) {
    padding: 4px 12px;
    width: 40px;
    height: 32px;
  }
  @media screen and (max-width: 1025px) and (min-width: 768px) {
    padding: 4px 12px;
    width: 123px;
    height: 32px;
  }
`;

export const Hambuger = styled.div`
  @media screen and (max-width: 768px) and (min-width: 320px) {
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

export const ideBarWrap = styled.div`
  z-index: 5;
  padding: 12px;
  border-radius: 15px 0 0 15px;
  background-color: #e7e4e1;
  height: 100%;
  width: 55%;
  right: -55%;
  top: 0;
  position: fixed;
  transition: 0.5s ease;
  &.open {
    right: 0;
    transition: 0.5s ease;
  }
`;

export const MenuItemWrapper = styled.div`
  height: 100%;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  border-width: 3px 0 3px 0;
  border-color: #fff;
  @media screen and (min-width: 1025px) {
    &:hover {
      // color: ${emeraldScaleTheme.emeraldScaleColor500};
      border-width: 4px 0 4spx 0;
      border-bottom-color: ${emeraldScaleTheme.emeraldScaleColor500};
    }
  }
  @media (max-width: 1024px) {
    border-width: 0 0 1px 0;
    border-color: ${grayScaleTheme.grayScaleColor300};
    width: 100vw;
  }
`;

export const MenuHorizontalWrapper = styled.div`
  box-shadow: ${ShadowTheme._4};
  //padding: 44px 32px;
  width: 15%;
  height: 100vh;
  @media screen and (max-width: 1199px) {
    width: 80px;
    padding: 16px;
    // padding: 22px 16px;
  }
`;

export const MenuLogoImg = styled.img`
  width: 50%;
  @media screen and (max-width: 1199px) {
    width: 100%;
  }
`;
export const LogoWrapper = styled.div`
  @media screen and (max-width: 1199px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
