import { FunctionComponent, useState } from "react";
import { ReportItemContext } from "../context/reportItemContext";
import { dataObjectType } from "../components/page/ControllServer";
const ReportProvider: FunctionComponent<{ children: React.ReactNode }> = (
  props
) => {
  const [accidentData, setData] = useState<dataObjectType>({
    accel: "-1",
    address: "",
    createdAt: "",
    date: "",
    idx: -1,
    insurance: "",
    location: "36.8098309/ 127.1442811",
    number: "",
    ownName: "",
    ownType: "",
    parentPhoneNumber: "",
    pitch: "-1",
    pty: "0",
    roll: "-1",
    sky: "0",
    speed: "",
    t1h: "0",
    type: "0",
    updatedAt: "",
    userId: "",
    yaw: "0",
  });

  // useEffect(() => {}, []);

  const updateData = (newData: dataObjectType) => {
    setData(newData);
  };

  return (
    <ReportItemContext.Provider value={{ accidentData, updateData }}>
      {props.children}
    </ReportItemContext.Provider>
  );
};

export default ReportProvider;
