import React, { FunctionComponent } from "react";
import { grayScaleTheme } from "./RootColorStyle";
import { defaultColorTheme } from "./Theme";
import { TypographyStyle } from "../StyleComponent/FontStyle";
type DataLabelProps = {
  label: string;
  data: string | number;
  img?: string;
  style?: object;
};
export const DataLabel: FunctionComponent<DataLabelProps> = function (props) {
  return (
    <div
      style={{
        border: "1px solid",
        borderRadius: "6px",
        borderColor: grayScaleTheme.grayScaleColor200,
        backgroundColor: defaultColorTheme.White,
        padding: "4px 6px",
        width: "100%",
        height: "inherit",
        // display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...TypographyStyle.paragraphMedium.paragraphMedium16,
        color: grayScaleTheme.grayScaleColor700,
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "fit-content",
          padding: "2.5px 12px",
          margin: "4px auto",
          borderRadius: "6px",
          backgroundColor: grayScaleTheme.grayScaleColor200,
        }}
      >
        {props.label}
      </div>
      <div
        style={{
          textAlign: "center",
          color: grayScaleTheme.grayScaleColor800,
        }}
      >
        {props.data}
      </div>
    </div>
  );
};
