import { Icon } from "@iconify/react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import axios from "axios";
import { dataObjectType } from "./ControllServer";
import {} from "@what3words/react-components";
import SockJS from "sockjs-client";
import Stomp from "stompjs"; // <------변경
import { database } from "../../FirebaseSetting";
import { off, ref } from "firebase/database";
import { AuthContext } from "../../context/authContext";
import { AccidentIndexContext } from "../../context/accidentIndexContext";
import MenuIconHorizontal from "../Layout/Menu/MenuIconHorizontal";
import MenuHorizontal from "../Layout/Menu/MenuHorizontal";
import {
  ShadowTheme,
  TypographyComponent,
  TypographyStyle,
} from "../StyleComponent/FontStyle";
import { grayScaleTheme } from "../Layout/RootColorStyle";
import { defaultColorTheme } from "../Layout/Theme";
import { Spacer } from "../StyleComponent/Spacer";
import { BorderButton } from "../StyleComponent/BorderButton";
import VerticalNameTagRadiusBox from "../StyleComponent/VerticalNameTagRadiusBox";
import {
  FlexBackground,
  FlexCenter,
  GridColumn4to2,
} from "../Layout/Layout.Style";
import { Link } from "react-router-dom";
import { getDaeJeonInRangeCoordinate } from "../../util/cctv";
import CsvDownload from "react-json-to-csv";
export type userInfoType = {
  createdAt: string;
  updatedAt: string;
  idx: string;
  userId: string;
  name: string;
  parentNumber: string;
  profileImageUrl: string;
  loginType: string;
};
type Item = {
  dataKey: string;
  dataObject: dataObjectType;
};
type sortType = {
  car: boolean;
  bicycle: boolean;
  wheelchair: boolean;
  pedestrian: boolean;
};
const dateObject = new Date();
const Year = dateObject.getFullYear().toString();
const Month = (dateObject.getMonth() + 1).toString();
const Day = dateObject.getDate().toString();
const CSVFileName =
  "MyRen accident data report : " + Year + "_" + Month + "_" + Day;
const DashBoard: FunctionComponent = function () {
  const userDataTitle = [
    "신고자",
    "식별 토큰",
    "차급",
    "유종",
    "차량 번호",
    "차량명",
    "보험사",
    "보호자",
  ];
  const accidentDataTitle = [
    "유형",
    "위치",
    "일시",
    "날씨",
    "온도",
    "가속도",
    "Roll",
    "YaW",
  ];
  const userTagIcon = [
    "ic:baseline-person",
    "game-icons:token",
    "mdi:car-info",
    "mdi:fuel",
    "tabler:number",
    "mdi:car",
    "mdi:car-insurance",
    "ic:baseline-phone",
  ];
  const accidentTagIcon = [
    "material-symbols:info",
    "material-symbols:location-on",
    "material-symbols:date-range",
    "material-symbols:weather-mix",
    "mdi:temperature",
    "material-symbols:speed",
    "mdi:3d-rotation",
    "mdi:3d-rotation",
  ];
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAUMOlJCMKz9JhtLsahW_XVOCOFNqoG7Ys",
    language: "EN",
  });
  const API_KEY = "JAXEBGHR";
  const MAP_API_KEY = "AIzaSyAUMOlJCMKz9JhtLsahW_XVOCOFNqoG7Ys";
  const containerStyle = {
    width: "100%",
    height: "95%",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
  };
  const AccidentRecord = [
    "경미한 사고로 추정",
    "상당한 사고로 추정",
    "중대한 사고로 추정",
    // "Probably a minor accident",
    // "Probably a significant accident",
    // "Probably a serious accident",
  ];
  const skyState = ["", "맑음", "", "흐림", "비구름"];
  const rainState = [
    "None",
    "비",
    "비/눈",
    "눈",
    "",
    "빗방울",
    "빗방울/눈",
    "눈",
  ];
  const navigation = useNavigate();
  const [selectedButton, setSelectedButton] = useState<number | null>(0);
  const [hoverButton, setHoverButton] = useState<number | null>(null);
  const [items, setItems] = useState<Item[]>([]);
  const [filteredItems, setFiltereditems] = useState<Item[]>([]);
  const [originItems, setOriginItems] = useState<Item[]>([]);
  const [CSV, setCSV] = useState<dataObjectType[]>([]);
  const csvHeader = ["가속도,주소,날짜,회원 토큰,위치정보,Roll,사고유형,Yaw"];
  const dbRef = ref(database, "AccidentRecord/");
  const userInfo = useContext(AuthContext);
  const [userData, setUserData] = useState<userInfoType>({
    createdAt: "",
    updatedAt: "",
    idx: "",
    userId: "",
    name: "",
    parentNumber: "",
    profileImageUrl: "",
    loginType: "",
  });
  const [info, setInfo] = useState<dataObjectType>({
    accel: "-1",
    address: "",
    createdAt: "",
    date: "",
    idx: -1,
    insurance: "",
    location: "36.8098309/ 127.1442811",
    number: "",
    ownName: "",
    ownType: "",
    parentPhoneNumber: "",
    pitch: "-1",
    pty: "0",
    roll: "-1",
    sky: "0",
    speed: "",
    t1h: "0",
    type: "0",
    updatedAt: "",
    userId: "",
    yaw: "0",
  });

  const [record, setRecord] = useState<{
    label: string;
    value: number;
    color: string;
  }>({
    label: "",
    value: 0,
    color: "",
  });

  //사고 유형이 자동차 인지 확인하고 아니면 사용자 정보 불러오는 함수
  const checkAccidentType = function (props: dataObjectType) {
    if (props.type !== "자동차") {
      axios
        .post("/api/mobile/user/login", {
          user_id: props.userId,
        })
        .then(function (response) {
          setUserData(response.data.data.user);
          setInfo((prev) => ({
            ...prev,
            ownName: response.data.data.user.name,
            parentPhoneNumber: response.data.data.user.parentNumber,
          }));
        });
    }
  };
  const indexData = useContext(AccidentIndexContext);

  /* 로그인 여부 확인하는 로직 */
  useEffect(() => {
    if (userInfo?.uid !== "yzd7S2S8oDP71211KadrC5WVhT33") {
      navigation("/serverlogin");
    }
  }, [navigation, userInfo]);

  // sorting 요소 전체 선택인지 확인하는 함수
  const checkTotalSorting = (props: sortType) => {
    const isAllTrue = Object.values(props).every((value) => value === true);
    return isAllTrue;
  };

  /*onChildAdded 함수를 사용하면 DB에 새로운 정보가 저정되었을 때, dbRef에 있는 AccidentRecord 데이터 불러온다.*/
  useEffect(() => {
    axios
      .get("/api/accident-record/all")
      .then((Response) => {
        const dataKey = Object.keys(Response.data.data);
        const dataValue = Object.values(Response.data.data);
        const Items: Item[] = dataKey.map((key, index) => ({
          dataKey: key,
          dataObject: dataValue[index] as dataObjectType,
        }));
        Items.reverse();
        oldIdx = Items[0].dataObject.idx;
        console.log(Items);
        setItems(Items);
        setOriginItems(Items);
        setFiltereditems(Items);
        setInfo(Items[0].dataObject);
        setLatitude(Number(info.location.split("/")[0]));
        setLongitude(Number(info.location.split("/")[1]));
        handleConvertToWhat3words();
        Number(info.accel.split(",")[info.accel.split(",").length - 1]) < 150
          ? setRecord({
              label: AccidentRecord[0],
              value: 33,
              color: "#0ECE49",
            })
          : Number(info.accel.split(",")[info.accel.split(",").length - 1]) >
            400
          ? setRecord({
              label: AccidentRecord[2],
              value: 100,
              color: "#F0273D",
            })
          : setRecord({
              label: AccidentRecord[1],
              value: 66,
              color: "#F36B1E",
            });
      })
      .catch((Error) => {
        console.log(Error);
      });
    return () => {
      off(dbRef, "value");
    };
  }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let testtest = 0;
  let oldIdx = 0;

  //websoket 연결 부위ㄴ
  useEffect(() => {
    const socket = new SockJS("https://alwayssafeu.com/wss");
    // const stompClient = over(socket);// <------기존
    const stompClient = Stomp.over(socket); // <------변경

    stompClient.connect({}, (frame) => {
      stompClient.subscribe("/topic/accidentRecords", (message) => {
        if (message.body) {
          const Item = {
            dataKey: items.length.toString(),
            dataObject: JSON.parse(message.body),
          };
          if (oldIdx < Item.dataObject.idx) {
            oldIdx = Item.dataObject.idx;
            setItems((items) => [Item, ...items]);
          }
          setInfo(Item.dataObject);
          setSelectedButton(0);
        }
      });
    });

    // cleanup on unmount
    return () => {
      if (stompClient.connected) {
        stompClient.disconnect(() => {}, {});
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const Data = items.find(
      (item) => item.dataObject.idx === indexData.accidentIndex
    );
    if (Data !== undefined) {
      checkAccidentType(Data.dataObject);
      setInfo(Data.dataObject);
      setSelectedButton(Data.dataObject.idx);
    }
  }, [indexData.accidentIndex]);

  const [what3wordsAddress, setWhat3wordsAddress] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const handleConvertToWhat3words = () => {
    // What3words API 키
    const what3wordsApiKey = API_KEY;

    // 좌표를 What3words 주소로 변환하는 API 엔드포인트
    const apiUrl = `https://api.what3words.com/v3/convert-to-3wa?coordinates=${latitude},${longitude}&key=${what3wordsApiKey}`;

    // Axios를 사용하여 API 호출
    axios
      .get(apiUrl)
      .then((response) => {
        // API 응답에서 What3words 주소 추출
        const address = response.data.words;
        setWhat3wordsAddress(address);
      })
      .catch((error) => {});
  };
  const sortingDB = (props: { Item: Item[]; Type: boolean }) => {
    // setItems(props.Item);
  };
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div style={{ display: "flex", wordBreak: "keep-all" }}>
      {/* <div
        style={{
          display: "flex",
          flexFlow: "row-reverse",
        }}
      >
        <BorderButton
          onClick={() => {
            sortingDB({
              Item: originItems,
              Type: true,
            });
          }}
        >
          <CsvDownload
            data={originItems}
            filename={CSVFileName}
            headers={csvHeader}
            delimiter=','
            // style={{ color: grayScaleTheme.grayScaleColor500 }}
          >
            CSV 파일 내려받기
          </CsvDownload>
        </BorderButton>
      </div> */}
      {/* 레이아웃 크기 지정은 %로 지정하지 않고 px로 지정해야할 듯 */}
      {windowWidth <= 1200 ? (
        <MenuIconHorizontal originItems={originItems} />
      ) : (
        <MenuHorizontal originItems={originItems} />
      )}
      {/* <MenuHorizontal /> */}
      <FlexBackground style={{ width: "100%" }}>
        <div style={{ width: "50%" }}>
          <div
            style={{
              boxShadow: ShadowTheme._16,
              borderRadius: "40px",
              backgroundColor: grayScaleTheme.grayScaleColor100,
              height: "96.7vh",
              margin: "16px 16px",
              padding: "32px",
              position: "relative",
            }}
          >
            <FlexCenter style={{ marginBottom: "16px" }}>
              <Icon
                icon={"mdi:location"}
                fontSize={"25px"}
                style={{ marginRight: "16px" }}
              />
              <TypographyComponent.label.semiBold20>
                사고 발생 위치 (지도)
              </TypographyComponent.label.semiBold20>
            </FlexCenter>
            <GoogleMap
              zoom={16}
              mapContainerStyle={containerStyle}
              center={{
                lat: Number(info.location.split("/")[0]),
                lng: Number(info.location.split("/")[1]),
              }}
              mapContainerClassName="map-container"
              options={{ disableDefaultUI: true }}
            >
              <div
                style={{
                  display: "flex",
                  width: "95%",
                  height: "54px",
                  marginTop: "16px",
                  zIndex: 99,
                  borderRadius: "8px",
                  boxShadow: "0 2px 16px 0 gray",
                  backgroundColor: defaultColorTheme.White,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "16px",
                    margin: "8px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/img/w3w_Symbol_RGB_Red.png"
                    alt=""
                    style={{ width: "50px" }}
                  />
                  <Spacer space={"26px"} />
                  <TypographyComponent.label.semiBold20>
                    {what3wordsAddress}
                  </TypographyComponent.label.semiBold20>
                </div>
              </div>
              <MarkerF
                position={{
                  lat: Number(info.location.split("/")[0]),
                  lng: Number(info.location.split("/")[1]),
                }}
                icon={{ url: "/img/MapMarker.png", scale: 5 }}
              />
            </GoogleMap>
          </div>
        </div>
        <div style={{ width: "58%" }}>
          {/* AccidentList */}
          <div
            style={{
              boxShadow: ShadowTheme._16,
              boxSizing: "border-box",
              borderRadius: "40px",
              backgroundColor: grayScaleTheme.grayScaleColor100,
              height: "35vh",
              margin: "16px 16px",
              padding: "24px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Icon
                icon={"priority_high"}
                fontSize={"25px"}
                style={{ marginRight: "16px" }}
              />
              <TypographyComponent.label.semiBold20>
                사고기록
              </TypographyComponent.label.semiBold20>
            </div>
            <div
              style={{
                overflowY: "scroll",
                height: "85%",
                padding: "0 5px",
              }}
            >
              {items.map((item, index) => {
                // const replacements: { [key: string]: string } = {
                //   라스베가스: "Las Vegas",
                //   "미 합중국": "USA",
                // };
                // let modifiedText = item.dataObject.address;
                // for (const wordToReplace in replacements) {
                //   if (replacements.hasOwnProperty(wordToReplace)) {
                //     const replacement = replacements[wordToReplace];
                //     modifiedText = modifiedText.replace(
                //       new RegExp(wordToReplace, "g"),
                //       replacement
                //     );
                //   }
                // }
                // item.dataObject.address = modifiedText;
                return (
                  <div>
                    <BorderButton
                      key={index}
                      onMouseEnter={() => setHoverButton(index)}
                      onMouseLeave={() => setHoverButton(null)}
                      onClick={() => {
                        console.log(item);
                        setInfo(item.dataObject);
                        checkAccidentType(item.dataObject);
                        setSelectedButton(item.dataObject.idx);
                        indexData.updateIndexData(item.dataObject.idx);
                        setLatitude(
                          Number(item.dataObject.location.split("/")[0])
                        );
                        setLongitude(
                          Number(item.dataObject.location.split("/")[1])
                        );
                        handleConvertToWhat3words();
                        Number(
                          item.dataObject.accel.split(",")[
                            item.dataObject.accel.split(",").length - 1
                          ]
                        ) < 250.0
                          ? setRecord({
                              label: AccidentRecord[0],
                              value: 33,
                              color: "#0ECE49",
                            })
                          : Number(
                              item.dataObject.accel.split(",")[
                                item.dataObject.accel.split(",").length - 1
                              ]
                            ) > 400.0
                          ? setRecord({
                              label: AccidentRecord[2],
                              value: 100,
                              color: "#F0273D",
                            })
                          : setRecord({
                              label: AccidentRecord[1],
                              value: 66,
                              color: "#F36B1E",
                            });
                      }}
                      style={{
                        borderRadius: "8px",
                        backgroundColor:
                          selectedButton === item.dataObject.idx
                            ? grayScaleTheme.grayScaleColor700
                            : hoverButton === item.dataObject.idx
                            ? grayScaleTheme.grayScaleColor50
                            : "white",
                        padding: "12px",
                        marginBottom: "12px",
                        boxShadow:
                          hoverButton === item.dataObject.idx
                            ? ShadowTheme._12
                            : ShadowTheme._2,
                        width: "100%",
                      }}
                      borderRadius={"8px"}
                      textColor={""}
                      backgroundColor={defaultColorTheme.White}
                      paddingHorizontal="12px"
                      paddingVertical="12px"
                      marginBottom="20px"
                    >
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "12px",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              color:
                                selectedButton === item.dataObject.idx
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                              display: "flex",
                              textAlign: "left",
                            }}
                          >
                            <div
                              style={{
                                width: "fit-content",
                                display: "flex",
                                alignItems: "center",
                                color:
                                  selectedButton === item.dataObject.idx
                                    ? defaultColorTheme.White
                                    : grayScaleTheme.grayScaleColor800,
                              }}
                            >
                              <Icon
                                icon={
                                  "healthicons:accident-and-emergency-outline"
                                }
                                style={{
                                  paddingRight: "4px",
                                  fontSize: "30px",
                                  color:
                                    selectedButton === item.dataObject.idx
                                      ? defaultColorTheme.White
                                      : grayScaleTheme.grayScaleColor800,
                                }}
                              />
                            </div>
                            {item.dataObject.type} 사고
                            <br />
                            {item.dataObject.address}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "28%",
                          }}
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color:
                                selectedButton === item.dataObject.idx
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          >
                            {item.dataObject.date.slice(0, 10)}
                          </div>
                          {/* <Spacer space={"20px"} /> */}
                          <div
                            style={{
                              color:
                                selectedButton === item.dataObject.idx
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          >
                            {item.dataObject.date.slice(
                              11,
                              item.dataObject.date.length
                            )}
                          </div>
                          {/* <Spacer space={"20px"} /> */}
                          <Icon
                            icon={"ri:arrow-right-s-line"}
                            style={{
                              color:
                                selectedButton === item.dataObject.idx
                                  ? defaultColorTheme.White
                                  : grayScaleTheme.grayScaleColor800,
                            }}
                          />
                        </div>
                      </div>
                    </BorderButton>
                  </div>
                );
              })}
            </div>
          </div>
          {/* detail */}
          <div
            style={{
              boxShadow: ShadowTheme._16,
              borderRadius: "40px",
              backgroundColor: grayScaleTheme.grayScaleColor100,
              height: "59.7vh",
              margin: "16px 16px",
              padding: "32px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                marginBottom: "16px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Icon
                  icon={"material-symbols:grid-on-sharp"}
                  fontSize={"25px"}
                  style={{ marginRight: "16px" }}
                />
                <TypographyComponent.label.semiBold20>
                  상세정보
                </TypographyComponent.label.semiBold20>
              </div>
              <Spacer horizontal={true} space={"8px"} />
              <div
                style={{
                  overflowY: "scroll",
                }}
              >
                {/* row1 */}
                <GridColumn4to2>
                  <VerticalNameTagRadiusBox
                    children={
                      info.roll.split(",")[29] === undefined ? (
                        <div
                          style={{
                            display: "flex",
                            padding: "16px 16px",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div>긴급신고</div>
                          <div
                            style={{
                              ...TypographyStyle.paragraphRegular
                                .paragraphRegular12,
                              color: grayScaleTheme.grayScaleColor500,
                            }}
                          >
                            직접 신고는 가속도 센서값, 속도,
                            <br />
                            자이로센서(Roll/Yaw/Pitch)를 제공하지 않습니다.
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            padding: "16px 16px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginRight: "16px" }}>
                            <div
                              style={{
                                width: "60px",
                                height: "60px",
                                position: "relative",
                                marginRight: "20px",
                              }}
                            >
                              <CircularProgressbar
                                counterClockwise={true}
                                strokeWidth={15}
                                value={record.value}
                                styles={buildStyles({
                                  textColor: grayScaleTheme.grayScaleColor800,
                                  textSize: "30px",
                                  pathColor: record.color,
                                  trailColor: grayScaleTheme.grayScaleColor200,
                                })}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: "32%",
                                  left: "30%",
                                }}
                              >
                                {Number(
                                  info.accel
                                    .split(",")
                                    [info.accel.split(",").length - 1].split(
                                      "."
                                    )[0]
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                ...TypographyStyle.paragraphMedium
                                  .paragraphMedium16,
                              }}
                            >
                              {record.label}
                            </div>
                            <div
                              style={{
                                ...TypographyStyle.paragraphRegular
                                  .paragraphRegular12,
                                color: grayScaleTheme.grayScaleColor500,
                              }}
                            >
                              본 데이터는 법적효력이 <br />
                              없습니다. 참고용 입니다.
                            </div>
                          </div>
                        </div>
                      )
                    }
                    LabelText={null}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.ownName}
                    LabelText={"사용자명"}
                  />

                  {/* row2 */}
                  <VerticalNameTagRadiusBox
                    children={info.number}
                    LabelText={"차량 번호"}
                  />
                  <VerticalNameTagRadiusBox
                    children={
                      info.insurance === null ? "Geico" : info.insurance
                    }
                    LabelText={"보험사"}
                  />
                  <VerticalNameTagRadiusBox
                    children={
                      info.parentPhoneNumber === "01012341234"
                        ? "19496782552"
                        : info.parentPhoneNumber
                    }
                    LabelText={"보호자 연락처"}
                  />

                  {/* row3 */}
                  <VerticalNameTagRadiusBox
                    children={info.type + " 사고"}
                    LabelText={"사고유형"}
                  />

                  <VerticalNameTagRadiusBox
                    children={info.date}
                    LabelText={"사고 일시"}
                  />
                  <VerticalNameTagRadiusBox
                    children={info.address}
                    LabelText={"사고 발생 위치"}
                  />
                  {/* <VerticalNameTagRadiusBox
                    children={
                      Number(info.pty) == 0
                        ? skyState[Number(info.sky)]
                        : rainState[Number(info.pty)]
                    }
                    LabelText={"날씨"}
                  />
                  <VerticalNameTagRadiusBox
                    // children={"32°C"}
                    children={info.t1h.toString() + "°C"}
                    LabelText={"온도"}
                  /> */}
                  <VerticalNameTagRadiusBox
                    children={
                      info.roll.split(",")[29] !== undefined
                        ? info.roll.split(",")[29] +
                          "/" +
                          info.yaw.split(",")[29]
                        : "-/-"
                    }
                    LabelText={"Roll/Yaw"}
                  />
                  {getDaeJeonInRangeCoordinate(
                    Number(info.location.split("/")[0]),
                    Number(info.location.split("/")[1])
                  ) ? (
                    <VerticalNameTagRadiusBox
                      children={
                        <a
                          href={`/test?lat=${info.location.split("/")[0]}&lng=${
                            info.location.split("/")[1]
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          실시간 CCTV / 교통정보 보기
                        </a>
                      }
                      LabelText={null}
                    />
                  ) : (
                    <VerticalNameTagRadiusBox
                      children={
                        "대전시 정보만 실시간 CCTV / 교통정보 보실 수 있습니다"
                      }
                      LabelText={null}
                    />
                  )}
                </GridColumn4to2>
              </div>
            </div>
          </div>
        </div>
      </FlexBackground>
    </div>
  );
};

export default DashBoard;

// "0,0,0,0,0
// ,0,0,0,0,0,
// 0,0,0,0,0,
// 0,0,0,0,0,
// 0,0,0,0,0,
// 0,0,0,60.259448945522308,
// 36.029464185237885"
