import { FunctionComponent, useState } from "react";
import { AccidentIndexContext } from "../context/accidentIndexContext";
const AccidentIndexProvider: FunctionComponent<{
  children: React.ReactNode;
}> = (props) => {
  const [accidentIndex, setDataIndex] = useState<number>(0);
  const [notiItemLength, setNotiDataIndex] = useState<number>(0);


  const updateIndexData = (newData: number) => {
    setDataIndex(newData);
  };
  const updateNotiIndexData = (newData: number) => {
    setNotiDataIndex(newData);
  };

  return (
    <AccidentIndexContext.Provider
      value={{
        accidentIndex,
        notiItemLength,
        updateIndexData,
        updateNotiIndexData,
      }}
    >
      {props.children}
    </AccidentIndexContext.Provider>
  );
};

export default AccidentIndexProvider;
