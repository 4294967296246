import React, { FunctionComponent } from "react";
import FixCopyContain from "./FixCopyContain";
import AnimationContain from "./AnimationContain";
import { SecondContentsContainer } from "./SecondContents.style";

const SecondContents: FunctionComponent = function () {
  return (
    <SecondContentsContainer>
      <FixCopyContain />
      <AnimationContain />
    </SecondContentsContainer>
  );
};

export default SecondContents;
