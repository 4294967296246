import { Icon } from "@iconify/react";

import { FunctionComponent, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { ShadowTheme, TypographyStyle } from "../StyleComponent/FontStyle";
import { defaultColorTheme } from "./Theme";
import { Spacer } from "../StyleComponent/Spacer";
import { grayScaleTheme } from "./RootColorStyle";
type SensorLabelProps = { accel: number; lang: boolean };
export const SensorLabel: FunctionComponent<SensorLabelProps> = function (
  props
) {
  const AccidentRecord = [
    "경미한 사고로 추정",
    "상당한 사고로 추정",
    "중대한 사고로 추정",
  ];
  const AccidentRecordEn = [
    "Probably a minor accident",
    "Probably a significant accident",
    "Probably a serious accident",
  ];
  const [record, setRecord] = useState({
    label: "",
    value: 0,
    color: "",
  });
  useEffect(() => {
    props.accel < 250
      ? setRecord(
          props.lang
            ? {
                label: AccidentRecordEn[0],
                value: 33,
                color: "#0ECE49",
              }
            : {
                label: AccidentRecord[0],
                value: 33,
                color: "#0ECE49",
              }
        )
      : props.accel > 400
      ? setRecord(
          props.lang
            ? { label: AccidentRecordEn[2], value: 100, color: "#F0273D" }
            : {
                label: AccidentRecord[2],
                value: 100,
                color: "#F0273D",
              }
        )
      : setRecord(
          props.lang
            ? { label: AccidentRecordEn[1], value: 66, color: "#F36B1E" }
            : {
                label: AccidentRecord[1],
                value: 66,
                color: "#F36B1E",
              }
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        boxShadow: ShadowTheme._16,
        backgroundColor: defaultColorTheme.White,
        width: "100%",
        padding: "8px",
        borderRadius: "8px",
      }}
    >
      <div style={{ display: "flex", padding: "0 8px", marginBottom: "4px" }}>
        <Icon icon={"material-symbols:data-usage"} fontSize={24} />
        <Spacer space={"16px"} />

        {Number.isNaN(props.accel) ? (
          <strong> {props.lang ? "Direct Siren" : "직접신고"}</strong>
        ) : props.lang ? (
          "Acceleration sensor value"
        ) : (
          "가속도 센서 값"
        )}
      </div>
      <div
        style={{ display: "flex", padding: "16px 16px", alignItems: "center" }}
      >
        {Number.isNaN(props.accel) ? (
          <>
            <div
              style={{
                ...TypographyStyle.paragraphRegular.paragraphRegular12,
                color: grayScaleTheme.grayScaleColor500,
              }}
            >
              {!props.lang
                ? "직접 신고는 가속도 센서값, 속도, 자이로센서(Roll/Yaw/Pitch)를 제공하지 않습니다."
                : "Direct reporting indicates acceleration sensor value, speed, gyro sensor (Roll/Yaw/Pitch) We don't provide it."}
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{
                marginRight: "16px",
              }}
            >
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  position: "relative",
                  marginRight: "20px",
                }}
              >
                <CircularProgressbar
                  counterClockwise={true}
                  strokeWidth={15}
                  value={record.value}
                  styles={buildStyles({
                    textColor: grayScaleTheme.grayScaleColor800,
                    textSize: "30px",
                    pathColor: record.color,
                    trailColor: grayScaleTheme.grayScaleColor200,
                  })}
                />
                <div style={{ position: "absolute", top: "32%", left: "28%" }}>
                  {props.accel}
                </div>
              </div>
            </div>
            <div style={{ maxWidth: "50%" }}>
              <div
                style={{ ...TypographyStyle.paragraphMedium.paragraphMedium16 }}
              >
                {record.label}
              </div>
              <div
                style={{
                  ...TypographyStyle.paragraphRegular.paragraphRegular12,
                  color: grayScaleTheme.grayScaleColor500,
                }}
              >
                {!props.lang
                  ? "본 센서 값은 핸드폰에 있는 자이로 센서 값을 통해 측정됩니다. 본 데이터는 참고용임으로 법적인 효력이 없습니다."
                  : "This sensor value is measured by a gyro sensor within the mobile phone where the app is installed. This data is for reference purposes."}
              </div>
            </div>
            <div
              style={{
                // display: "flex",
                alignItems: "center",
                width: "fit-content",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#0ECE49",
                    marginRight: "8px",
                  }}
                />
                {props.lang
                  ? "Probably a minor accident"
                  : "경미한 사고로 추정"}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#F36B1E",
                    marginRight: "8px",
                  }}
                />
                {props.lang
                  ? "Probably a significant accident"
                  : "상당한 사고로 추정"}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#F0273D",
                    marginRight: "8px",
                  }}
                />
                {props.lang
                  ? "Probably a serious accident"
                  : "중대한 사고로 추정"}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
