export function getAverage(data: number[]) {
  let sum = 0;
  data.forEach((item) => {
    sum += item;
  });
  if (isNaN(Math.floor(sum / data.length))) {
    return 0;
  }
  return Math.floor(sum / data.length);
}

export function getMax(data: number[]) {
  if (isNaN(Math.max(...data))) {
    return 0;
  }
  return Math.max(...data);
}

export function getMin(data: number[]) {
  if (isNaN(Math.min(...data))) {
    return 0;
  }
  return Math.min(...data);
}
