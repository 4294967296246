import { FunctionComponent, useState } from "react";
import { CctvIndexContext } from "../context/clickCctvContext";
const CctvIndexProvider: FunctionComponent<{
  children: React.ReactNode;
}> = (props) => {
  const [cctvIndex, setDataIndex] = useState<number>(-1);

  const updateCctvIndexData = (newData: number) => {
    setDataIndex(newData);
  };

  return (
    <CctvIndexContext.Provider
      value={{
        cctvIndex,
        updateCctvIndexData,
      }}
    >
      {props.children}
    </CctvIndexContext.Provider>
  );
};

export default CctvIndexProvider;
