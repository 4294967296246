import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ReportItemContext } from "../../context/reportItemContext";
import {
  ShadowTheme,
  TypographyComponent,
  TypographyStyle,
} from "../StyleComponent/FontStyle";
import { ButtonStyle, defaultColorTheme } from "../Layout/Theme";
import { grayScaleTheme } from "../Layout/RootColorStyle";
import { DataLabel } from "../Layout/DataLable";
import { SensorLabel } from "../Layout/SensorLabel";
import { Graph } from "../Layout/GraphComponent";
import { dataObjectType } from "./ControllServer";
import axios from "axios";
import { userInfoType } from "./Dashboard";
import { getAverage, getMax, getMin } from "../../util/data";
import { GoogleMap, MarkerF, OverlayView } from "@react-google-maps/api";
const AccidentItemReport: FunctionComponent = function () {
  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
  };
  const [userData, setUserData] = useState<userInfoType>({
    createdAt: "",
    updatedAt: "",
    idx: "",
    userId: "",
    name: "",
    parentNumber: "",
    profileImageUrl: "",
    loginType: "",
  });
  const skyState = ["", "맑음", "", "흐림", "비구름"];
  const rainState = [
    "None",
    "비",
    "비/눈",
    "눈",
    "",
    "빗방울",
    "빗방울/눈",
    "눈",
  ];
  const contentRef = useRef<HTMLDivElement | null>(null);
  const reportData = useContext(ReportItemContext);
  const generatePDF = async () => {
    if (contentRef.current) {
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const pages = contentRef.current.children as HTMLCollection;

      for (let i = 0; i < pages.length; i++) {
        const page = pages[i] as HTMLElement;
        // const canvas = await html2canvas(page);
        const canvas = await html2canvas(page, {
          allowTaint: true,
          useCORS: true,
        }); // HTML을 캔버스로 렌더링
        const imgData = canvas.toDataURL("image/png");

        if (i > 0) {
          pdf.addPage();
        }

        pdf.addImage(imgData, "PNG", 0, 0, 210, pdfHeight);
      }

      pdf.save(
        "MyRen Accident Report(" +
          reportData.accidentData.date.split("T")[0] +
          ").pdf"
      );
    }
  };
  const checkAccidentType = function (props: dataObjectType) {
    if (props.type !== "자동차") {
      axios
        .post("/api/mobile/user/login", {
          user_id: props.userId,
        })
        .then(function (response) {
          setUserData(response.data.data.user);
          const Item: dataObjectType = {
            ...reportData.accidentData,
            ownName: response.data.data.user.name,
          };
          reportData.updateData(Item);
          console.log(response.data.data.user);
        });
    }
  };

  useEffect(() => {
    checkAccidentType(reportData.accidentData);
    setLatitude(Number(reportData.accidentData.location.split("/")[0]));
    setLongitude(Number(reportData.accidentData.location.split("/")[1]));
    handleConvertToWhat3words();
  }, []);
  const dateObject = new Date();
  const Year = dateObject.getFullYear().toString();
  const Month = (dateObject.getMonth() + 1).toString();
  const Day = dateObject.getDate().toString();

  const speedArray: number[] = [];
  reportData.accidentData.speed
    .split(",")
    .forEach((item) => speedArray.push(Number(item)));

  const averageSpeed = getAverage(speedArray);
  const maxSpeed = getMax(speedArray);
  const minSpeed = getMin(speedArray);

  const API_KEY = "JAXEBGHR";
  const MAP_API_KEY = "AIzaSyAUMOlJCMKz9JhtLsahW_XVOCOFNqoG7Ys";
  const [what3wordsAddress, setWhat3wordsAddress] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const handleConvertToWhat3words = () => {
    // What3words API 키
    const what3wordsApiKey = API_KEY;

    // 좌표를 What3words 주소로 변환하는 API 엔드포인트
    const apiUrl = `https://api.what3words.com/v3/convert-to-3wa?coordinates=${latitude},${longitude}&key=${what3wordsApiKey}`;

    // Axios를 사용하여 API 호출
    axios
      .get(apiUrl)
      .then((response) => {
        // API 응답에서 What3words 주소 추출
        const address = response.data.words;
        setWhat3wordsAddress(address);
      })
      .catch((error) => {});
  };
  return (
    <>
      <div
        style={{
          width: "21cm",
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ ...TypographyStyle.heading.bold31, paddingLeft: "24px" }}>
          보고서 미리보기
        </div>
        <div>
          <button
            onClick={generatePDF}
            style={{ ...ButtonStyle.EnabledChecked }}
          >
            PDF 다운로드
          </button>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div ref={contentRef} style={{ width: "21cm", height: "29.7cm" }}>
          <div>
            {/* PDF로 변환하려는 내용을 이 div 안에 넣으세요 */}
            <div
              style={{
                width: "21cm",
                height: "29.7cm",
                padding: "24px",
                borderWidth: "1px",
                borderColor: defaultColorTheme.Black,
              }}
            >
              {/* WATERMARK */}
              <div
                style={{
                  position: "absolute",
                  top: "13cm",
                  left: "6.25cm",
                  opacity: "25%",
                  zIndex: "9999",
                }}
              >
                <img src="./img/Logo.png" alt="" style={{ height: "80px" }} />
              </div>
              {/* HEADERS */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottomWidth: "1px",
                  borderColor: grayScaleTheme.grayScaleColor300,
                  paddingBottom: "8px",
                  marginBottom: "16px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <img
                      src="/img/Logo.png"
                      alt="Logo"
                      style={{ height: "30px", paddingRight: "16px" }}
                    />
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphMedium.paragraphMedium25,
                    }}
                  >
                    사고 보고서 - 개요
                  </div>
                </div>
                <div
                  style={{
                    color: grayScaleTheme.grayScaleColor400,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {Year}.{Month}.{Day}
                </div>
              </div>
              {/* CONTENTS */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "90%",
                }}
              >
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor50,
                    padding: "16px",
                  }}
                >
                  <div
                    style={{
                      ...TypographyStyle.paragraphMedium.paragraphMedium20,
                      paddingBottom: "10px",
                    }}
                  >
                    사고정보
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                    }}
                  >
                    <DataLabel
                      label={"사용자"}
                      data={reportData.accidentData.ownName}
                    />
                    <DataLabel
                      label={"사고유형"}
                      data={reportData.accidentData.type}
                    />
                    <DataLabel
                      label={"날짜"}
                      data={reportData.accidentData.date}
                    />

                    <DataLabel
                      label={"보험사"}
                      data={reportData.accidentData.insurance}
                    />
                    <DataLabel
                      label={"Roll"}
                      data={
                        reportData.accidentData.roll === "-"
                          ? "-"
                          : reportData.accidentData.roll.split(",")[29]
                      }
                    />
                    <DataLabel
                      label={"Yaw"}
                      data={
                        reportData.accidentData.yaw === "-"
                          ? "-"
                          : reportData.accidentData.yaw.split(",")[29]
                      }
                    />
                    <div
                      style={{
                        border: "1px solid",
                        borderRadius: "6px",
                        borderColor: grayScaleTheme.grayScaleColor200,
                        backgroundColor: defaultColorTheme.White,
                        padding: "4px 6px",
                        width: "100%",
                        height: "fit-content",
                        alignItems: "center",
                        justifyContent: "center",
                        ...TypographyStyle.paragraphMedium.paragraphMedium16,
                        color: grayScaleTheme.grayScaleColor700,
                        marginBottom: "10px",
                        gridColumnEnd: "span 3",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            height: "fit-content",
                            padding: "2.5px 12px",
                            margin: "4px auto",
                            borderRadius: "6px",
                            backgroundColor: grayScaleTheme.grayScaleColor200,
                            marginRight: "2px",
                          }}
                        >
                          평균시속
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            height: "fit-content",
                            padding: "2.5px 12px",
                            margin: "4px auto",
                            borderRadius: "6px",
                            backgroundColor: grayScaleTheme.grayScaleColor200,
                            marginRight: "2px",
                          }}
                        >
                          최대시속
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            height: "fit-content",
                            padding: "2.5px 12px",
                            margin: "4px auto",
                            borderRadius: "6px",
                            backgroundColor: grayScaleTheme.grayScaleColor200,
                          }}
                        >
                          최저시속
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            textAlign: "center",
                            color: grayScaleTheme.grayScaleColor800,
                            width: "100%",
                          }}
                        >
                          {averageSpeed}km/h
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            color: grayScaleTheme.grayScaleColor800,
                            width: "100%",
                          }}
                        >
                          {maxSpeed}km/h
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            color: grayScaleTheme.grayScaleColor800,
                            width: "100%",
                          }}
                        >
                          {minSpeed}km/h
                        </div>
                      </div>
                    </div>
                    {/* 사고위치 */}
                    <div
                      style={{
                        border: "1px solid",
                        borderRadius: "6px",
                        borderColor: grayScaleTheme.grayScaleColor200,
                        backgroundColor: defaultColorTheme.White,
                        padding: "4px 6px",
                        width: "100%",
                        height: "fit-content",
                        // display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        ...TypographyStyle.paragraphMedium.paragraphMedium16,
                        color: grayScaleTheme.grayScaleColor700,
                        marginBottom: "10px",
                        gridColumnEnd: "span 3",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          height: "fit-content",
                          padding: "2.5px 12px",
                          margin: "4px auto",
                          borderRadius: "6px",
                          backgroundColor: grayScaleTheme.grayScaleColor200,
                          marginRight: "2px",
                        }}
                      >
                        사고위치
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          color: grayScaleTheme.grayScaleColor800,
                          width: "100%",
                        }}
                      >
                        {reportData.accidentData.address}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphRegular.paragraphRegular12,
                      color: grayScaleTheme.grayScaleColor500,
                    }}
                  >
                    * 본 보고서에 표기된 km/h는 충돌 시점에 해당하는 속도입니다.
                    충돌 이전 15초 동안의 기록을 보기 위해서는 3번째 페이지의
                    속도 그래프를 확인하세요.
                  </div>
                </div>
                {/* 세로구분선  */}
                <div
                  style={{
                    backgroundColor: grayScaleTheme.grayScaleColor300,
                    width: "100%",
                    height: "1px",
                  }}
                />
                {/* 사고 정보 */}
                <div
                  style={{
                    width: "100%",
                    backgroundColor: grayScaleTheme.grayScaleColor50,
                    padding: "16px",
                    height: "100%",
                  }}
                >
                  <SensorLabel
                    accel={Number(
                      reportData.accidentData.accel
                        .split(",")
                        [
                          reportData.accidentData.accel.split(",").length - 1
                        ].split(".")[0]
                    )}
                    lang={false}
                  />
                  <div
                    style={{
                      backgroundColor: grayScaleTheme.grayScaleColor300,
                      width: "100%",
                      height: "1px",
                      marginBottom: "10px",
                    }}
                  />
                  {/* 사고 분석 */}
                  <div
                    style={{
                      ...TypographyStyle.paragraphMedium.paragraphMedium20,
                      paddingBottom: "10px",
                    }}
                  >
                    사고 위치 (지도)
                  </div>
                  <div
                    style={{
                      ...TypographyStyle.paragraphMedium.paragraphMedium16,
                      color: grayScaleTheme.grayScaleColor400,
                      height: "60%",
                      padding: "16px",
                      backgroundColor: defaultColorTheme.White,
                      borderRadius: "8px",
                      boxShadow: ShadowTheme._16,
                    }}
                  >
                    <div style={{ position: "absolute", width: "660px" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "54px",
                          marginTop: "16px",
                          zIndex: 9999,
                          borderRadius: "8px",
                          boxShadow: "0 2px 16px 0 gray",
                          backgroundColor: defaultColorTheme.White,
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                          left: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            // padding: "16px",
                            margin: "8px",
                            alignItems: "center",
                            color: defaultColorTheme.Black,
                          }}
                        >
                          <img
                            src="/img/w3w_Symbol_RGB_Red.png"
                            alt=""
                            style={{ width: "50px" }}
                          />
                          {/* <Spacer space={"26px"} /> */}
                          <TypographyComponent.label.semiBold16>
                            {what3wordsAddress}
                          </TypographyComponent.label.semiBold16>
                        </div>
                      </div>
                    </div>
                    <GoogleMap
                      zoom={16}
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: Number(
                          reportData.accidentData.location.split("/")[0]
                        ),
                        lng: Number(
                          reportData.accidentData.location.split("/")[1]
                        ),
                      }}
                      mapContainerClassName="map-container"
                      options={{ disableDefaultUI: true }}
                    >
                      <MarkerF
                        position={{
                          lat: Number(
                            reportData.accidentData.location.split("/")[0]
                          ),
                          lng: Number(
                            reportData.accidentData.location.split("/")[1]
                          ),
                        }}
                        icon={{ url: "/img/MapMarker.png", scale: 5 }}
                      />
                      <OverlayView
                        position={{
                          lat: Number(
                            reportData.accidentData.location.split("/")[0]
                          ),
                          lng: Number(
                            reportData.accidentData.location.split("/")[1]
                          ),
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            background: "rgba(255,255,255,0.7)",
                            border: "1px solid #ccc",
                          }}
                        >
                          {/* 여기에 what3words grid의 내용을 렌더링하거나 스타일을 추가할 수 있습니다. */}
                        </div>
                      </OverlayView>
                    </GoogleMap>
                    {/* <MapContainer
                    location={reportData.accidentData.location}
                    address={""}
                  /> */}
                    {/* <MapW3W
                    location={reportData.accidentData.location}
                    address={""}
                  /> */}
                  </div>
                </div>
              </div>
              {/* BOTTOM */}
              <div
                style={{
                  marginTop: "16px",
                  borderTopWidth: "1px",
                  borderTopColor: grayScaleTheme.grayScaleColor300,
                  paddingTop: "8px",
                  ...TypographyStyle.paragraphMedium.paragraphMedium12,
                  color: grayScaleTheme.grayScaleColor400,
                }}
              >
                *This accident report has no legal effect and is for reference
                only.
              </div>
            </div>
          </div>
          {reportData.accidentData.accel === "-" ? null : (
            <>
              <div>
                <div
                  style={{
                    width: "21cm",
                    height: "29.7cm",
                    padding: "24px",
                    borderWidth: "1px",
                    borderColor: defaultColorTheme.Black,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "13cm",
                      left: "6.25cm",
                      opacity: "25%",
                      zIndex: "9999",
                    }}
                  >
                    <img
                      src="./img/Logo.png"
                      alt=""
                      style={{ height: "80px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottomWidth: "1px",
                      borderColor: grayScaleTheme.grayScaleColor300,
                      paddingBottom: "8px",
                      marginBottom: "16px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <img
                          src="/img/Logo.png"
                          alt="Logo"
                          style={{ height: "30px", paddingRight: "16px" }}
                        />
                      </div>
                      <div
                        style={{
                          ...TypographyStyle.paragraphMedium.paragraphMedium25,
                        }}
                      >
                        사고 보고서 - 센서 값 (Roll, Yaw, Pitch)
                      </div>
                    </div>
                    <div
                      style={{
                        color: grayScaleTheme.grayScaleColor400,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {Year}.{Month}.{Day}
                    </div>
                  </div>
                  <div
                    style={{
                      // display: "flex",
                      // justifyContent: "space-between",
                      height: "90%",
                    }}
                  >
                    <AccidentItemReportPage2 />
                  </div>
                  <div
                    style={{
                      marginTop: "16px",
                      borderTopWidth: "1px",
                      borderTopColor: grayScaleTheme.grayScaleColor300,
                      paddingTop: "8px",
                      ...TypographyStyle.paragraphMedium.paragraphMedium12,
                      color: grayScaleTheme.grayScaleColor400,
                    }}
                  >
                    *This accident report has no legal effect and is for
                    reference only.
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    width: "21cm",
                    height: "29.7cm",
                    padding: "24px",
                    borderWidth: "1px",
                    borderColor: defaultColorTheme.Black,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "13cm",
                      left: "6.25cm",
                      opacity: "25%",
                      zIndex: "9999",
                    }}
                  >
                    <img
                      src="./img/Logo.png"
                      alt=""
                      style={{ height: "80px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottomWidth: "1px",
                      borderColor: grayScaleTheme.grayScaleColor300,
                      paddingBottom: "8px",
                      marginBottom: "16px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <img
                          src="/img/Logo.png"
                          alt="Logo"
                          style={{ height: "30px", paddingRight: "16px" }}
                        />
                      </div>
                      <div
                        style={{
                          ...TypographyStyle.paragraphMedium.paragraphMedium25,
                        }}
                      >
                        사고 보고서 - 센서 값 (가속도, 속도)
                      </div>
                    </div>
                    <div
                      style={{
                        color: grayScaleTheme.grayScaleColor400,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {Year}.{Month}.{Day}
                    </div>
                  </div>
                  <div
                    style={{
                      // display: "flex",
                      // justifyContent: "space-between",
                      height: "90%",
                    }}
                  >
                    <AccidentItemReportPage3 />
                  </div>
                  <div
                    style={{
                      marginTop: "16px",
                      borderTopWidth: "1px",
                      borderTopColor: grayScaleTheme.grayScaleColor300,
                      paddingTop: "8px",
                      ...TypographyStyle.paragraphMedium.paragraphMedium12,
                      color: grayScaleTheme.grayScaleColor400,
                    }}
                  >
                    *This accident report has no legal effect and is for
                    reference only.
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {/* {reportData.accidentData.accel === "-" ? null : <GSAReport />} */}
      </div>
    </>
  );
};

const AccidentItemReportPage2: FunctionComponent = function () {
  const changeStringArrToNumberArr = function (getData: string[]) {
    const numberArray = getData.map((stringValue) => parseInt(stringValue, 10));
    return numberArray;
  };
  const reportData = useContext(ReportItemContext);
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "200px",
          marginBottom: "40px",
        }}
      >
        <TypographyComponent.label.semiBold20>
          Roll
        </TypographyComponent.label.semiBold20>
        <Graph
          data={changeStringArrToNumberArr(
            reportData.accidentData.roll.split(",")
          )}
          title="속도"
          color="hsl(249, 70%, 50%)"
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "200px",
          marginBottom: "40px",
        }}
      >
        <TypographyComponent.label.semiBold20>
          Yaw
        </TypographyComponent.label.semiBold20>
        <Graph
          data={changeStringArrToNumberArr(
            reportData.accidentData.yaw.split(",")
          )}
          title="속도"
          color="hsl(249, 70%, 50%)"
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "200px",
          marginBottom: "10px",
        }}
      >
        <TypographyComponent.label.semiBold20>
          Pitch
        </TypographyComponent.label.semiBold20>
        <Graph
          data={changeStringArrToNumberArr(
            reportData.accidentData.pitch.split(",")
          )}
          title="속도"
          color="hsl(249, 70%, 50%)"
        />
      </div>
    </>
  );
};
const AccidentItemReportPage3: FunctionComponent = function () {
  const changeStringArrToNumberArr = function (getData: string[]) {
    const numberArray = getData.map((stringValue) => parseInt(stringValue, 10));
    return numberArray;
  };
  const reportData = useContext(ReportItemContext);
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "200px",
          marginBottom: "40px",
        }}
      >
        <TypographyComponent.label.semiBold20>
          가속도
        </TypographyComponent.label.semiBold20>
        <Graph
          data={changeStringArrToNumberArr(
            reportData.accidentData.accel.split(",")
          )}
          title="속도"
          color="hsl(249, 70%, 50%)"
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "200px",
          marginBottom: "10px",
        }}
      >
        <TypographyComponent.label.semiBold20>
          속도
        </TypographyComponent.label.semiBold20>
        <Graph
          data={changeStringArrToNumberArr(
            reportData.accidentData.speed.split(",")
          )}
          title="속도"
          color="hsl(249, 70%, 50%)"
        />
      </div>
    </>
  );
};

export default AccidentItemReport;
